import React from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Grid, Dropdown } from 'semantic-ui-react';
import FormLabel from '../../../../../../shared-components/FormLabel';
import {
	fetchInbox,
	fetchOutbox,
	fetchQuality,
	fetchViInbox,
	fetchViOutbox,
	hideModal
} from '../../../../../../../store/actions/InboxActions';
import { addToast } from '../../../../../../../store/actions/ToastActions';

function OVI() {
	const users = useSelector(state => state.inbox.users);
	const positive = useSelector(state => state.inbox.positive);
	const record = useSelector(state => state.inbox.record);
	const name = useSelector(state => state.auth.name);
	const email = useSelector(state => state.auth.email);

	const dispatch = useDispatch();

	const fetchDataset = () => {
		switch (positive) {
			case 'Inbox':
				return dispatch(fetchInbox());
			case 'Outbox':
				return dispatch(fetchOutbox());
			case 'Quality':
				return dispatch(fetchQuality());
			case 'USVI Inbox':
				return dispatch(fetchViInbox());
			case 'USVI Outbox':
				return dispatch(fetchViOutbox());
			default:
				return dispatch(fetchInbox());
		}
	};

	return (
		<Formik
			initialValues={{
				id: record.id,
				egg: record.egg || '',
				confirmedbylab: record.confirmedbylab || '',
				laboratorytech: record.laboratorytech || name,
				labidcomments: record.labidcomments || '',
				healthy_eggs: record.healthy_eggs || '',
				collapsed_eggs: record.collapsed_eggs || '',
				hatched_eggs: record.hatched_eggs || '',
				suitable_hatching: record.suitable_hatching || ''
			}}
			validationSchema={Yup.object().shape({
				confirmedbylab: Yup.string().required('Please, confirm maintenance!!!'),
				laboratorytech: Yup.string().required('Please, choose a technician!!!'),
				healthy_eggs: Yup.number().integer('Provide integer number'),
				collapsed_eggs: Yup.number().integer('Provide integer number'),
				hatched_eggs: Yup.number().integer('Provide integer number')
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				const json = values;
				json.editdate = new Date();
				json.editor = email;
				fetch('/api/update-ovi', {
					method: 'post',
					body: JSON.stringify(json),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(response => response.json())
					.then(data => {
						if (data.success) {
							setSubmitting(false);
							resetForm();
							dispatch(
								addToast({
									type: 'success',
									message: data.success
								})
							);
							fetchDataset();
							dispatch(hideModal());
						} else {
							setSubmitting(false);
							dispatch(
								addToast({
									type: 'error',
									message: data.error
								})
							);
						}
					})
					.catch(error => {
						dispatch(
							addToast({
								type: 'error',
								message: error.message
							})
						);
						setSubmitting(false);
					});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				handleReset
			}) => (
				<Grid padded stackable doubling style={{ fontSize: 11 }}>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Form
								noValidate
								onSubmit={handleSubmit}
								onReset={handleReset}
								size="tiny"
							>
								<Form.Field>
									<FormLabel text="Eggs Found" />
									<Form.Dropdown
										name="egg"
										selection
										clearable
										search
										control={Dropdown}
										fluid
										placeholder="Select one..."
										value={values.egg}
										options={[
											{
												key: 'Yes',
												value: 'Yes',
												text: 'Yes'
											},
											{
												key: 'No',
												value: 'No',
												text: 'No'
											},
											{
												key: 'Not Received',
												value: 'Not Received',
												text: 'Not Received'
											}
										]}
										onChange={(e, { value }) => {
											setFieldValue('egg', value);
										}}
									/>
								</Form.Field>
								{values.egg === 'Yes' && (
									<Form.Group widths="equal">
										<Form.Field>
											<FormLabel
												text="Healthy Eggs"
												error={
													errors.healthy_eggs &&
													touched.healthy_eggs &&
													errors.healthy_eggs
												}
											/>
											<Form.Input
												type="number"
												name="healthy_eggs"
												placeholder="Add amount"
												value={values.healthy_eggs}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Field>
										<Form.Field>
											<FormLabel
												text="Collapsed Eggs"
												error={
													errors.collapsed_eggs &&
													touched.collapsed_eggs &&
													errors.collapsed_eggs
												}
											/>
											<Form.Input
												type="number"
												name="collapsed_eggs"
												placeholder="Add amount"
												value={values.collapsed_eggs}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Field>
										<Form.Field>
											<FormLabel
												text="Hatched Eggs"
												error={
													errors.hatched_eggs &&
													touched.hatched_eggs &&
													errors.hatched_eggs
												}
											/>
											<Form.Input
												type="number"
												name="hatched_eggs"
												placeholder="Add amount"
												value={values.hatched_eggs}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Field>
										<Form.Field>
											<FormLabel text="Suitable for hatching?" />
											<Form.Dropdown
												name="suitable_hatching"
												selection
												clearable
												search
												control={Dropdown}
												fluid
												placeholder="Select one..."
												value={values.suitable_hatching}
												options={[
													{
														key: 'Yes',
														value: 'Yes',
														text: 'Yes'
													},
													{
														key: 'No',
														value: 'No',
														text: 'No'
													}
												]}
												onChange={(e, { value }) => {
													setFieldValue('suitable_hatching', value);
												}}
											/>
										</Form.Field>
									</Form.Group>
								)}
								<Form.Group widths="equal">
									<Form.Field required>
										<FormLabel
											required
											text="Confirmed by lab"
											error={
												errors.confirmedbylab &&
												touched.confirmedbylab &&
												errors.confirmedbylab
											}
										/>
										<Form.Dropdown
											name="confirmedbylab"
											selection
											control={Dropdown}
											fluid
											search
											value={values.confirmedbylab}
											placeholder="Select one..."
											options={[
												{
													key: 'option-1',
													value: 'Yes',
													text: 'Yes (Received: accounted)'
												},
												{
													key: 'option-2',
													value: 'No (decomposed lizard)',
													text: 'No (decomposed lizard)'
												},
												{
													key: 'option-3',
													value: 'No (missing)',
													text: 'No (missing)'
												},
												{
													key: 'option-4',
													value: 'No (no access)',
													text: 'No (no access)'
												},
												{
													key: 'option-5',
													value: 'No (no label)',
													text: 'No (no label)'
												},
												{
													key: 'option-6',
													value: 'No (no glue)',
													text: 'No (no glue)'
												},
												{
													key: 'option-7',
													value: 'No (no screen)',
													text: 'No (no screen)'
												},
												{
													key: 'option-8',
													value: 'No (body fluids)',
													text: 'No (body fluids)'
												},
												{
													key: 'option-9',
													value: 'No (other)',
													text: 'No (other)'
												},
												{
													key: 'option-10',
													value: 'No (out of maintenance)',
													text: 'No (out of maintenance)'
												},
												{
													key: 'option-11',
													value: 'No (out of date)',
													text: 'No (out of date)'
												}
											]}
											onChange={(e, { value }) => {
												setFieldValue('confirmedbylab', value);
											}}
										/>
									</Form.Field>
									<Form.Field required>
										<FormLabel
											required
											search
											text="Id. Technician"
											error={
												errors.laboratorytech &&
												touched.laboratorytech &&
												errors.laboratorytech
											}
										/>
										<Form.Dropdown
											fluid
											selection
											search
											value={values.laboratorytech}
											options={users}
											placeholder="Select name..."
											onChange={(e, { value }) => {
												setFieldValue('laboratorytech', value);
											}}
										/>
									</Form.Field>
								</Form.Group>
								<Form.Field>
									<FormLabel text="Comments 💬" />
									<Form.TextArea
										name="labidcomments"
										placeholder="Feels like commenting?"
										value={values.labidcomments}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</Form.Field>
								<Form.Button
									positive
									type="submit"
									size="tiny"
									loading={isSubmitting}
									content="Submit Maintenance"
								/>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Formik>
	);
}

export default OVI;
