import {
	CUSTOMER_ORDERS_SUCCESS,
	CUSTOMER_ORDERS_ERROR,
	CUSTOMER_ORDERS_LOADING,
	CUSTOMER_ORDERS_ORDER,
	CUSTOMER_ORDERS_POOLS,
	CUSTOMER_ORDER_POOL,
	CUSTOMER_POOLS_LOADING,
	CUSTOMER_POOLS_ERROR,
	CUSTOMER_POOLS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	orders: null,
	loading: false,
	error: null,
	order: null,
	uuid: null,
	record: null,
	pools: [],
	pools_loading: false,
	pools_error: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CUSTOMER_ORDERS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case CUSTOMER_ORDERS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case CUSTOMER_ORDERS_SUCCESS:
			return {
				...state,
				loading: false,
				orders: action.payload
			};
		case CUSTOMER_ORDERS_ORDER:
			return {
				...state,
				order: action.payload
			};
		case CUSTOMER_ORDERS_POOLS:
			return {
				...state,
				uuid: action.payload
			};
		case CUSTOMER_ORDER_POOL:
			return {
				...state,
				record: action.payload
			};
		case CUSTOMER_POOLS_LOADING:
			return {
				...state,
				pools_loading: true,
				pools_error: null
			};
		case CUSTOMER_POOLS_ERROR:
			return {
				...state,
				pools_loading: false,
				pools_error: action.payload
			};
		case CUSTOMER_POOLS_SUCCESS:
			return {
				...state,
				pools_loading: false,
				pools: action.payload
			};
		default:
			return state;
	}
};
