import React, { Component } from 'react';
import { Container, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getMessages, hide } from '../../store/actions/MessengerActions';

class Message extends Component {
	componentDidMount() {
		this.props.getMessages();

		setTimeout(() => {
			this.props.hide();
		}, 300000);
	}

	renderMessage = () => {
		if (this.props.message) {
			return (
				<div className="note-container">
					<Container text>
						<div className="note">
							<div style={{ marginRight: 20 }}>
								<p>
									<span className="strong">{this.props.message.subject}</span>{' '}
									{this.props.message.message}
								</p>
							</div>
							<Icon link name="close" size="small" onClick={this.props.hide} />
						</div>
					</Container>
				</div>
			);
		}
		return null;
	};

	render() {
		return this.renderMessage();
	}
}

const mapStateToProps = state => {
	return {
		message: state.messenger.message
	};
};

export default connect(
	mapStateToProps,
	{ getMessages, hide }
)(Message);
