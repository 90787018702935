import {
	LARVAE_INBOX_ERROR,
	LARVAE_INBOX_LOADING,
	LARVAE_INBOX_SUCCESS,
	LARVAE_INBOX_ACTIVE
} from '../actions/types';

const INITIAL_STATE = {
	loading: false,
	data: null,
	error: null,
	active: 'inbox'
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LARVAE_INBOX_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case LARVAE_INBOX_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false
			};
		case LARVAE_INBOX_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case LARVAE_INBOX_ACTIVE:
			return {
				...state,
				active: action.payload
			};
		default:
			return state;
	}
};
