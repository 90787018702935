import React, { useEffect } from 'react';
import { Grid, Button, Container, Step, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTitle } from 'react-use';
import Navbar from '../../shared-components/Navbar';
import Footer from '../../shared-components/Footer';
import Inbox from './shared-components/Inbox';
import Banner from './shared-components/Banner';
import {
	fetchInbox,
	fetchOutbox,
	setActive
} from '../../../store/actions/LarvaeActions';
import Breadcrumbs from './shared-components/Breadcrumbs';
import LarvaeHeader from './shared-components/LarvaeHeader';

export default function Larvae() {
	const dispatch = useDispatch();
	const loading = useSelector(state => state.larvae.loading);
	const active = useSelector(state => state.larvae.active);

	const breadcrumbs = [
		{ key: '0', content: 'Home', to: '/', as: Link },
		{
			key: '1',
			content: 'Larvae Identification',
			active: true
		}
	];

	useTitle('PRVCU | Larvae Identification');

	useEffect(() => {
		if (active === 'inbox') {
			dispatch(fetchInbox());
		} else {
			dispatch(fetchOutbox());
		}
	}, [active, dispatch]);

	return (
		<>
			<Navbar />
			<Banner
				title="Larvae Identification"
				subtitle="This survey is intended to collect data related to mosquito larvae identification. Count and identify samples until specie level. If necessary larvae pools will be created."
			/>
			<Container text className="mt-30 mb-30">
				<Grid stackable>
					<Breadcrumbs sections={breadcrumbs} />
					<LarvaeHeader
						title={active === 'inbox' ? 'Inbox' : 'Outbox'}
						subtitle="This table shows all the traps that were suitable for larvae hatching, after laboratory inspection. Make sure to select the correct trap ID and pick up date to enter the data. Once in the survey please indicate the amount of larvaes per specie. In case of a No ID sample, please notify your supervisor."
					/>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Button
								fluid
								size="mini"
								content="Inbox"
								color={active === 'inbox' ? 'purple' : null}
								toggle
								loading={active === 'inbox' && loading}
								onClick={() => {
									dispatch(setActive('inbox'));
								}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Button
								fluid
								size="mini"
								content="Outbox"
								color={active === 'outbox' ? 'purple' : null}
								toggle
								loading={active === 'outbox' && loading}
								onClick={() => {
									dispatch(setActive('outbox'));
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1} stretched>
						<Grid.Column>
							<Step.Group size="mini">
								<Step>
									<Icon name="qrcode" />
									<Step.Content>
										<Step.Title>Scan</Step.Title>
										<Step.Description>Scan the trap barcode</Step.Description>
									</Step.Content>
								</Step>
								<Step>
									<Icon name="edit outline" />
									<Step.Content>
										<Step.Title>Edit</Step.Title>
										<Step.Description>
											Edit the maintenance form
										</Step.Description>
									</Step.Content>
								</Step>
								<Step completed>
									<Icon name="send" />
									<Step.Content>
										<Step.Title>Submit</Step.Title>
										<Step.Description>
											Submit the maintenance form
										</Step.Description>
									</Step.Content>
								</Step>
							</Step.Group>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Inbox />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
			<Footer />
		</>
	);
}
