import React, { useState, useEffect } from 'react';
import { Grid, Container, Header, Label, Icon, Button, Segment, Form, Input, Popup, Modal } from 'semantic-ui-react';
import matchSorter from 'match-sorter';
import { useMount } from 'react-use';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react';
import Navbar from '../../shared-components/Navbar';
import OrdersBanner from './shared-components/OrdersBanner';
import Footer from '../../shared-components/Footer';
import OrdersHeader from './shared-components/OrdersHeader';
import OrdersBreadcrumb from './shared-components/OrdersBreadcrumb';
import { addToast } from '../../../store/actions/ToastActions';
import Edit from './shared-components/order/forms/Edit';
import OrderPools from './shared-components/order/pools/OrderPools';
import { fetchOrders, setOrder, setPools } from '../../../store/actions/CustomerActions';

const breadcrumbs = [
	{ key: 'home', content: 'Home', to: '/', as: Link },
	{
		key: 'orders',
		content: 'Orders',
		active: true
	}
];

function Orders() {
	const dispatch = useDispatch();
	const email = useSelector(state => state.auth.email);
	const orders = useSelector(state => state.customer.orders);
	const order = useSelector(state => state.customer.order);
	const uuid = useSelector(state => state.customer.uuid);
	const [subset, setSubset] = useState(null);
	const [date, setDate] = useState('');
	const [dates, setDates] = useState(null);
	const [filter, setFilter] = useState('');

	const setStatus = (status, uuid) => {
		axios
			.put('/api/order-status', {
				uuid,
				order_status: status,
				editor: email
			})
			.then(res => {
				dispatch(fetchOrders());
				dispatch(
					addToast({
						type: 'success',
						message: res.data.message
					})
				);
			})
			.catch(err => {
				dispatch(
					addToast({
						type: 'error',
						message: err.response.data.message
					})
				);
			});
	};

	const deleteOrder = uuid => {
		axios
			.delete(`/api/delete-order/${uuid}`)
			.then(res => {
				dispatch(fetchOrders());
				dispatch(
					addToast({
						type: 'success',
						message: res.data.message
					})
				);
			})
			.catch(err => {
				dispatch(
					addToast({
						type: 'error',
						message: err.response.data.message
					})
				);
			});
	};

	useMount(() => {
		dispatch(fetchOrders());
	});

	useEffect(() => {
		if (orders) {
			const uniqBy = _.uniqBy(orders, 'received');
			const map = _.map(uniqBy, e => moment(new Date(e.received), 'L'));
			setDates(map);
		}
	}, [orders]);

	useEffect(() => {
		if (orders) {
			if (date) {
				setSubset(
					_.filter(orders, e => {
						const dateReceived = moment(e.received).format('L');
						return dateReceived === date;
					})
				);
			} else {
				setSubset(orders);
			}
		}
	}, [orders, date]);

	useEffect(() => {
		const data = _.filter(orders, e => {
			return e.uuid === uuid;
		});
		setOrder(data[0]);
	}, [uuid, orders]);

	return (
		<>
			<Modal
				size="small"
				open={!!order}
				onClose={() => dispatch(setOrder(null))}
				onUnmount={() => dispatch(fetchOrders())}
			>
				<Modal.Header>Order Number</Modal.Header>
				<Modal.Content scrolling>
					<Edit order={order} />
				</Modal.Content>
				<Modal.Actions>
					<Button content="Cancel" onClick={() => dispatch(setOrder(null))} />
				</Modal.Actions>
			</Modal>
			<Modal size="mini" open={!!uuid} onClose={() => setPools(null)} onUnmount={() => dispatch(fetchOrders())}>
				<Modal.Header>Specimen Collection</Modal.Header>
				<Modal.Content scrolling>
					<OrderPools />
				</Modal.Content>
				<Modal.Actions>
					<Button content="Cancel" onClick={() => dispatch(setPools(null))} />
				</Modal.Actions>
			</Modal>
			<Navbar />
			<OrdersBanner
				title="Client Specimen Data Collect: Orders"
				subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in augue id massa ultricies interdum. Ut mattis facilisis lacinia. Nunc auctor, eros nec pulvinar euismod, elit quam scelerisque dolor, sit amet ornare tellus dui et orci."
			/>
			<Container className="mt-30 mb-30">
				<Grid stackable>
					<OrdersBreadcrumb sections={breadcrumbs} />
					<OrdersHeader
						title="Orders"
						subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in augue id massa ultricies interdum. Ut mattis facilisis lacinia. Nunc auctor, eros nec pulvinar euismod, elit quam scelerisque dolor, sit amet ornare tellus dui et orci."
					/>
				</Grid>
				<Grid stackable reversed="mobile">
					<Grid.Column width={7}>
						<Header
							content="Filter orders by received date"
							as="h3"
							subheader="Click on date to filter orders. Orange circles means one or more orders are available."
						/>
						{date && (
							<Label color="yellow">
								{date}
								<Icon name="delete" link onClick={() => setDate('')} />
							</Label>
						)}
						<DateInput
							clearable={false}
							value={date}
							onChange={(event, { value }) => setDate(value)}
							inline
							dateFormat="L"
							marked={dates}
							markColor="orange"
						/>
					</Grid.Column>
					<Grid.Column width={9}>
						<Grid>
							<Grid.Row columns={1}>
								<Grid.Column>
									<Form>
										<Input
											icon="search"
											placeholder="Search..."
											fluid
											value={filter}
											onChange={(event, { value }) => {
												setFilter(value);
											}}
										/>
									</Form>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row columns={2} verticalAlign="middle">
								<Grid.Column>
									<span className="small">
										Showing {subset && subset.length} of {orders && orders.length}
									</span>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Button
										icon="refresh"
										primary
										circular
										size="mini"
										content="Refresh"
										onClick={() => dispatch(fetchOrders())}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>

						<>
							{_.map(
								matchSorter(subset, filter, {
									keys: ['order_number', 'customer', 'received', 'shipment']
								}),
								e => {
									return (
										<Segment
											key={e.uuid}
											className="fade-in"
											size="small"
											style={{
												backgroundColor: 'rgba(0,0,0,.05)',
												border: 0
											}}
										>
											<Label
												icon="user circle outline"
												size="small"
												attached="top"
												color="yellow"
												content={e.customer}
											/>
											<Header
												as="h4"
												style={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<span>{e.order_number}</span>
												{e.order_status && (
													<Label icon="check circle" circular size="small" color="green" content="Done" />
												)}
											</Header>
											<div>
												<span className="strong">Received:</span>{' '}
												<span className="quiet">{new Date(e.received).toDateString()}</span>
											</div>
											<p>
												<span className="strong">Item Description:</span>{' '}
												<span className="quiet">{e.description || 'No description available for this order.'}</span>
											</p>
											<Button.Group basic size="mini">
												<Popup
													size="tiny"
													trigger={<Button content="More Details" icon="vertical ellipsis" />}
													flowing
													hoverable
												>
													<p className="strong">Order Details</p>
													<div>
														<Icon name="box" /> {e.box}
													</div>
													<div>
														<Icon name="lab" /> {e.vials}
													</div>
													<div>
														<Icon name="shipping fast" /> Shipment @ {new Date(e.shipment).toDateString()}
													</div>
													<div>
														<Icon name="user circle outline" /> Reported @ {new Date(e.reported).toDateString()}
													</div>
													<div className="quiet">Comments: {e.comments}</div>
													<div className="quiet">
														{e.creator} @ {new Date(e.created).toLocaleDateString()}
													</div>
												</Popup>
												<Button disabled={e.order_status === 'Completed'} onClick={() => dispatch(setPools(e.uuid))}>
													<div
														style={{
															display: 'flex',
															alignItems: 'center'
														}}
													>
														<Icon name="lab" />
													</div>
												</Button>
												<Button
													icon="edit"
													disabled={e.order_status === 'Completed'}
													onClick={() => dispatch(setOrder(e))}
												/>
												<Button
													icon={{
														name: e.order_status ? 'lock' : 'unlock'
													}}
													onClick={() => {
														const status = e.order_status ? '' : 'Completed';
														setStatus(status, e.uuid);
													}}
												/>
												<Button
													disabled
													icon={{
														name: 'mail outline'
													}}
												/>
												<Popup
													header="Just Asking!"
													size="mini"
													position="top right"
													content={() => (
														<div>
															<p>Are you sure you like to delete this order?</p>
															<Button size="mini" negative content="Delete" onClick={() => deleteOrder(e.uuid)} />
														</div>
													)}
													on="click"
													trigger={
														<Button
															icon={{
																name: 'delete',
																color: 'red'
															}}
															disabled={e.order_status === 'Completed'}
														/>
													}
												/>
											</Button.Group>
										</Segment>
									);
								}
							)}
						</>
					</Grid.Column>
				</Grid>
			</Container>
			<Footer />
		</>
	);
}

export default Orders;
