import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import {
	Accordion,
	Button,
	Confirm,
	Form,
	Grid,
	Icon,
	Input,
	Modal,
	Popup
} from 'semantic-ui-react';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import matchSorter from 'match-sorter';
import {
	closeAlert,
	fetchInbox,
	fetchOutbox,
	fetchPools,
	fetchQuality,
	fetchViInbox,
	fetchViOutbox,
	hideModal
} from '../../../../../../../store/actions/InboxActions';
import { addToast } from '../../../../../../../store/actions/ToastActions';
import {
	modalHide,
	modalShow
} from '../../../../../../../store/actions/PoolsEditorActions';
import PoolsEditorForm from '../../../../../pools/shared-components/Editor';
import Counter from '../../../../../../shared-components/Counter';
import FormLabel from '../../../../../../shared-components/FormLabel';
import Badge from '../../../../../../shared-components/Badge';
import { authProvider } from '../../../../../../../utils/AuthProvider';
import { sendOutboxMail } from '../../../../../../auth-utils';

function AGO() {
	const [activePool, setActivePool] = useState(0);
	const [value, setValue] = useState('');
	const [deleting, setDeleting] = useState(false);
	const positive = useSelector(state => state.inbox.positive);
	const confirm = useSelector(state => state.inbox.confirm);
	const modal = useSelector(state => state.poolEditor.modal);
	const pool = useSelector(state => state.poolEditor.pool);
	const mosquitoes = useSelector(state => state.poolEditor.mosquitoes);
	const box = useSelector(state => state.poolEditor.box);
	const name = useSelector(state => state.auth.name);
	const record = useSelector(state => state.inbox.record);
	const users = useSelector(state => state.inbox.users);
	const pools = useSelector(state => state.inbox.pools);
	const email = useSelector(state => state.auth.email);

	const dispatch = useDispatch();

	const inbox = [
		{
			text: 'Aedes aegypti female',
			value: 'aedesaegyptifemale'
		},
		{
			text: 'Aedes aegypti male',
			value: 'aedesaegyptimale'
		},
		{
			text: 'Culex female',
			value: 'culexfemale'
		},
		{
			text: 'Culex male',
			value: 'culexmale'
		},
		{
			text: 'Aedes medio female',
			value: 'aedesmediofemale'
		},
		{
			text: 'Aedes medio male',
			value: 'aedesmediomale'
		},
		{
			text: 'Aedes sp female',
			value: 'aedesspfemale'
		},
		{
			text: 'Aedes sp male',
			value: 'aedesspmale'
		},
		{
			text: 'No ID',
			value: 'othermosquitocount'
		},
		{
			text: 'Uranotenia sp',
			value: 'uranoteniasp'
		},
		{
			text: 'Aedes taeniorhynchus female',
			value: 'aedestaeniorhynchusfemale'
		},
		{
			text: 'Aedes taeniorhynchus male',
			value: 'aedestaeniorhynchusmale'
		},
		{
			text: 'Culex quinquefasciatus female',
			value: 'culexquinquefasciatusfemale'
		},
		{
			text: 'Culex quinquefasciatus male',
			value: 'culexquinquefasciatusmale'
		},
		{
			text: 'Culex antillumagnorum female',
			value: 'culexantillumagnorumfemale'
		},
		{
			text: 'Culex antillumagnorum male',
			value: 'culexantillumagnorummale'
		}
	];

	const handleActive = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activePool === index ? -1 : index;
		setActivePool(newIndex);
	};

	const deletePool = id => {
		setDeleting(true);
		fetch('/api/delete-pool', {
			method: 'POST',
			body: JSON.stringify({
				id
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(json => {
				if (json.error) {
					dispatch(
						addToast({
							type: 'error',
							message: json.error
						})
					);
				} else {
					dispatch(
						addToast({
							type: 'success',
							message: json.success
						})
					);
				}
				dispatch(fetchPools(record.id));
			})
			.then(() => {
				setDeleting(false);
			})
			.catch(json => {
				dispatch(
					addToast({
						type: 'success',
						message: json.error
					})
				);
				setDeleting(false);
			});
	};

	const fetchDataset = () => {
		switch (positive) {
			case 'Inbox':
				return dispatch(fetchInbox());
			case 'Outbox':
				return dispatch(fetchOutbox());
			case 'Quality':
				return dispatch(fetchQuality());
			case 'USVI Inbox':
				return dispatch(fetchViInbox());
			case 'USVI Outbox':
				return dispatch(fetchViOutbox());
			default:
				return dispatch(fetchInbox());
		}
	};

	useMount(() => {
		dispatch(fetchPools(record.id));
	});

	const castVal = val => {
		switch (val) {
			case null:
				return '';
			case 0:
				return '0';
			default:
				return val;
		}
	};

	return (
		<div>
			{positive === 'Inbox' && (
				<Confirm open={confirm} onConfirm={() => dispatch(closeAlert())} />
			)}
			<Modal open={modal} size="mini">
				<Modal.Content>
					<Modal.Description>
						<p>
							<strong>Edit Pool</strong>
						</p>
						<p>
							<small>
								Edit the pool <strong>{pool}</strong> in the box {box} with{' '}
								{mosquitoes} mosquitoes.
							</small>
						</p>
						<PoolsEditorForm />
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => dispatch(modalHide())} size="mini">
						Cancel
					</Button>
				</Modal.Actions>
			</Modal>
			<Formik
				initialValues={{
					id: record.id,
					aedesaegyptifemale: castVal(record.aedesaegyptifemale),
					aedesaegyptimale: castVal(record.aedesaegyptimale),
					culexfemale: castVal(record.culexfemale),
					culexmale: castVal(record.culexmale),
					aedesmediofemale: castVal(record.aedesmediofemale),
					aedesmediomale: castVal(record.aedesmediomale),
					aedesspfemale: castVal(record.aedesspfemale),
					aedesspmale: castVal(record.aedesspmale),
					othermosquitocount: castVal(record.othermosquitocount),
					uranoteniasp: castVal(record.uranoteniasp),
					aedestaeniorhynchusfemale: castVal(record.aedestaeniorhynchusfemale),
					aedestaeniorhynchusmale: castVal(record.aedestaeniorhynchusmale),
					culexquinquefasciatusfemale: castVal(
						record.culexquinquefasciatusfemale
					),
					culexquinquefasciatusmale: castVal(record.culexquinquefasciatusmale),
					culexantillumagnorummale: castVal(record.culexantillumagnorummale),
					culexantillumagnorumfemale: castVal(
						record.culexantillumagnorumfemale
					),
					labidcomments: record.labidcomments || '',
					confirmedbylab: record.confirmedbylab || '',
					laboratorytech: record.laboratorytech || name,
					mosquitoextraction: record.mosquitoextraction || '',
					qa_technician: record.qa_technician || '',
					qa_results: record.qa_results || '',
					qa_comments: record.qa_comments || ''
				}}
				validationSchema={Yup.object().shape({
					confirmedbylab: Yup.string().required(
						'Please, confirm maintenance!!!'
					),
					laboratorytech: Yup.string().required(
						'Please, choose a technician!!!'
					),
					qa_technician:
						positive === 'Quality' &&
						Yup.string().required('Please, choose a technician!!!'),
					qa_results:
						positive === 'Quality' &&
						Yup.string().required('Please, choose a result!!!')
				})}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					const json = values;
					json.editdate = new Date();
					json.editor = email;

					fetch('/api/update-maintenance', {
						method: 'POST',
						body: JSON.stringify(json),
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json'
						}
					})
						.then(response => response.json())
						.then(async data => {
							setSubmitting(false);
							resetForm();
							dispatch(
								addToast({
									type: 'success',
									message: data.success
								})
							);
							fetchDataset();
							dispatch(hideModal());

							// Send email about outbox changes
							if (positive === 'Outbox') {
								try {
									const token = await authProvider.getAccessToken();
									await sendOutboxMail(
										token,
										email,
										record.barcode,
										record.date
									);
								} catch (err) {
									console.log(
										"Oops! Something it's wrong with your account. Please sign out and sign in again to refresh your account"
									);
								}
							}
						})
						.catch(error => {
							dispatch(
								addToast({
									type: 'error',
									message: error.message
								})
							);
							setSubmitting(false);
						});
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					setFieldValue,
					handleReset
				}) => (
					<Form
						onSubmit={handleSubmit}
						onReset={handleReset}
						size="mini"
						noValidate
					>
						<Grid padded stackable doubling style={{ fontSize: 11 }}>
							<Grid.Row columns={2}>
								<Grid.Column width={11}>
									<Grid doubling stackable>
										<Grid.Row columns={3}>
											{_.map(inbox, e => {
												return (
													<Grid.Column key={e.value}>
														<Counter
															label={e.text}
															name={e.value}
															value={values[e.value]}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													</Grid.Column>
												);
											})}
										</Grid.Row>
									</Grid>
								</Grid.Column>
								<Grid.Column width={5}>
									<Accordion
										styled
										defaultActiveIndex={positive === 'Quality' ? 2 : 0}
										panels={[
											{
												key: 0,
												index: 0,
												title: 'Maintenance Confirmation',
												content: {
													content: (
														<div>
															<Form.Field required>
																<FormLabel
																	text="Confirm"
																	required
																	error={
																		errors.confirmedbylab &&
																		touched.confirmedbylab &&
																		errors.confirmedbylab
																	}
																/>
																<Form.Dropdown
																	fluid
																	selection
																	search
																	options={[
																		{
																			key: 'option-1',
																			value: 'Yes',
																			text: 'Yes (Received: accounted)'
																		},
																		{
																			key: 'Yes (Out of date)',
																			value: 'Yes (Out of date)',
																			text: 'Yes (Out of date)'
																		},
																		{
																			key: 'option-2',
																			value: 'No (decomposed lizard)',
																			text: 'No (decomposed lizard)'
																		},
																		{
																			key: 'option-3',
																			value: 'No (missing)',
																			text: 'No (missing)'
																		},
																		{
																			key: 'option-4',
																			value: 'No (no access)',
																			text: 'No (no access)'
																		},
																		{
																			key: 'option-5',
																			value: 'No (no label)',
																			text: 'No (no label)'
																		},
																		{
																			key: 'option-6',
																			value: 'No (no glue)',
																			text: 'No (no glue)'
																		},
																		{
																			key: 'option-7',
																			value: 'No (no screen)',
																			text: 'No (no screen)'
																		},
																		{
																			key: 'option-8',
																			value: 'No (body fluids)',
																			text: 'No (body fluids)'
																		},
																		{
																			key: 'option-9',
																			value: 'No (other)',
																			text: 'No (other)'
																		},
																		{
																			key: 'option-10',
																			value: 'No (out of date)',
																			text: 'No (out of date)'
																		}
																	]}
																	disabled={positive === 'Quality'}
																	value={values.confirmedbylab}
																	placeholder="Select one..."
																	onChange={(e, { value }) => {
																		setFieldValue('confirmedbylab', value);
																		if (_.startsWith(value, 'No')) {
																			setFieldValue('mosquitoextraction', '');
																		}
																	}}
																/>
															</Form.Field>
															<Form.Field required>
																<FormLabel
																	required
																	text="Id. Technician"
																	error={
																		errors.laboratorytech &&
																		touched.laboratorytech &&
																		errors.laboratorytech
																	}
																/>
																<Form.Dropdown
																	fluid
																	selection
																	search
																	disabled={positive === 'Quality'}
																	value={values.laboratorytech}
																	options={users}
																	placeholder="Select name..."
																	onChange={(e, { value }) => {
																		setFieldValue('laboratorytech', value);
																	}}
																/>
															</Form.Field>
															{record.type !== 'MAST' && (
																<Form.Field>
																	<FormLabel text="Ex. Technician" />
																	<Form.Dropdown
																		placeholder="Select name..."
																		fluid
																		selection
																		search
																		clearable
																		disabled={
																			positive === 'Quality' ||
																			_.startsWith(values.confirmedbylab, 'No')
																		}
																		value={values.mosquitoextraction}
																		options={users}
																		onChange={(event, { value }) => {
																			setFieldValue(
																				'mosquitoextraction',
																				value
																			);
																		}}
																	/>
																</Form.Field>
															)}
															<Form.TextArea
																name="labidcomments"
																label="Comments"
																disabled={positive === 'Quality'}
																placeholder="Feels like commenting?"
																value={values.labidcomments}
																onChange={handleChange}
															/>
														</div>
													)
												}
											},
											{
												key: 1,
												index: 1,
												title: 'Pools Editor',
												content: {
													content:
														pools.length > 0 ? (
															<div>
																<Input
																	fluid
																	onChange={e => {
																		setValue(e.target.value);
																	}}
																	icon="filter"
																	iconPosition="left"
																	placeholder="Filter list..."
																/>
																<Accordion.Accordion
																	panels={_.map(
																		matchSorter(pools, value, {
																			keys: ['pool']
																		}),
																		e => {
																			return {
																				key: e.id,
																				index: e.id,
																				title: e.pool,
																				active: activePool === e.id,
																				onTitleClick: handleActive,
																				content: {
																					content: (
																						<div>
																							<p>
																								Pool {e.pool} in box number{' '}
																								{e.box} with {e.mosquitoes}{' '}
																								mosquitoes.
																							</p>
																							<Popup
																								inverted
																								size="mini"
																								content={() => {
																									return (
																										<span>
																											Are you sure you like to
																											delete this pool? This is
																											irreversible!!!
																										</span>
																									);
																								}}
																								trigger={
																									<Button
																										circular
																										size="mini"
																										icon="delete"
																										loading={deleting}
																										onClick={b => {
																											b.preventDefault();
																											deletePool(e.id);
																										}}
																									/>
																								}
																							/>
																							<Button
																								positive
																								circular
																								size="mini"
																								icon="pencil"
																								onClick={b => {
																									b.preventDefault();
																									dispatch(
																										modalShow(
																											e.pool,
																											e.box,
																											e.mosquitoes,
																											e.id,
																											e.sex,
																											e.technician
																										)
																									);
																								}}
																							/>
																						</div>
																					)
																				}
																			};
																		}
																	)}
																/>
															</div>
														) : (
															<div>
																<div className="placeholder full-width" />
																<div className="placeholder full-width" />
																<div className="placeholder full-width" />
															</div>
														)
												}
											},
											{
												key: 2,
												index: 2,
												title: 'Quality Assurance',
												content: {
													content: (
														<div>
															<Badge
																color="blue"
																style={{
																	marginBottom: 10,
																	width: '100%'
																}}
															>
																** Fill by quality assurance technician!!
															</Badge>
															<Form.Field
																required={positive === 'Quality'}
																disabled={positive !== 'Quality'}
															>
																<FormLabel
																	text="QA Technician"
																	error={
																		errors.qa_technician &&
																		touched.qa_technician &&
																		errors.qa_technician
																	}
																/>
																<Form.Dropdown
																	scrolling={false}
																	fluid
																	selection
																	options={users}
																	value={values.qa_technician}
																	placeholder="Select one..."
																	onChange={(e, { value }) => {
																		setFieldValue('qa_technician', value);
																	}}
																/>
															</Form.Field>
															<Form.Field
																required={positive === 'Quality'}
																disabled={positive !== 'Quality'}
															>
																<FormLabel
																	text="QA Result"
																	error={
																		errors.qa_results &&
																		touched.qa_results &&
																		errors.qa_results
																	}
																/>
																<Form.Dropdown
																	fluid
																	selection
																	options={[
																		{
																			key: 'result-1',
																			value: 'Correct count',
																			text: 'Correct count'
																		},
																		{
																			key: 'result-2',
																			value: 'Correct count / data entry',
																			text: 'Correct count / data entry'
																		},
																		{
																			key: 'result-3',
																			value:
																				'Correct count / Incorrect data entry',
																			text:
																				'Correct count / Incorrect data entry'
																		},
																		{
																			key: 'result-4',
																			value:
																				'Incorrect count / Correct data entry',
																			text:
																				'Incorrect count / Correct data entry'
																		},
																		{
																			key: 'result-5',
																			value:
																				'Incorrect count / Incorrect data entry',
																			text:
																				'Incorrect count / Incorrect data entry'
																		}
																	]}
																	value={values.qa_results}
																	placeholder="Select one..."
																	onChange={(e, { value }) => {
																		setFieldValue('qa_results', value);
																	}}
																/>
															</Form.Field>
															<Form.Field disabled={positive !== 'Quality'}>
																<FormLabel text="Comments 💬" />
																<Form.TextArea
																	name="qa_comments"
																	placeholder="Feels like commenting? Indicate mosquitoes species and amounts reported incorrectly."
																	value={values.qa_comments}
																	onChange={handleChange}
																/>
															</Form.Field>
														</div>
													)
												}
											}
										]}
									/>
									<div style={{ marginTop: 10 }}>
										<Button
											positive
											type="submit"
											fluid
											size="tiny"
											loading={isSubmitting}
										>
											<Icon name="send" /> Submit Maintenance
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default AGO;
