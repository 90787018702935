import {
	FETCH_MESSAGES_ERROR,
	FETCH_MESSAGES_SUCCESS,
	FETCH_MESSAGES_LOADING,
	MESSAGES_SIDEBAR_OPEN,
	MESSAGES_SIDEBAR_CLOSE,
	HIDE_MESSAGE
} from './types';

export const getMessages = () => {
	return dispatch => {
		dispatch({
			type: FETCH_MESSAGES_LOADING
		});

		fetch(`/api/messages`)
			.then(response => response.json())
			.then(messages => {
				dispatch({
					type: FETCH_MESSAGES_SUCCESS,
					payload: messages
				});
			})
			.catch(error => {
				dispatch({
					type: FETCH_MESSAGES_ERROR,
					payload: error.message
				});
			});
	};
};

export const close = () => {
	return {
		type: MESSAGES_SIDEBAR_CLOSE
	};
};

export const open = () => {
	return {
		type: MESSAGES_SIDEBAR_OPEN
	};
};

export const hide = () => {
	return {
		type: HIDE_MESSAGE
	};
};
