import {
	CODES_DATA_ERROR,
	CODES_DATA_LOADING,
	CODES_DATA_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	data: [],
	loading: false,
	error: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CODES_DATA_LOADING:
			return {
				...state,
				loading: true,
				error: ''
			};
		case CODES_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case CODES_DATA_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		default:
			return state;
	}
};
