import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

function Title(props) {
	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Header as="h2">{props.title}</Header>
				<p className="small quiet">{props.subtitle}</p>
			</Grid.Column>
		</Grid.Row>
	);
}

export default Title;
