import React, { useState } from 'react';
import { useMount } from 'react-use';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	Grid,
	Segment,
	Button,
	Input,
	Icon,
	Modal,
	Form,
	Table,
	Header
} from 'semantic-ui-react';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import matchSorter from 'match-sorter';
import BarcodeReader from 'react-barcode-reader';
import { fetchInbox } from '../../../../store/actions/LarvaeActions';
import Badge from '../../../shared-components/Badge';
import FormLabel from '../../../shared-components/FormLabel';
import { authProvider } from '../../../../utils/AuthProvider';
import { getTechnicians } from '../../../auth-utils';
import { addToast } from '../../../../store/actions/ToastActions';

function Inbox() {
	const dispatch = useDispatch();
	const data = useSelector(state => state.larvae.data);
	const email = useSelector(state => state.auth.email);
	const [users, setUsers] = useState(null);
	const [value, setValue] = useState('');
	const [record, setRecord] = useState(null);
	const [secondOpen, setSecondOpen] = useState(false);
	const [pool, setPool] = useState({
		pool: '',
		box: '',
		count: '',
		loading: false,
		deleting: false,
		active: null,
		edit: null
	});

	useMount(async () => {
		const token = await authProvider.getAccessToken();
		const technicians = await getTechnicians(token);
		setUsers(
			_.map(technicians.value, t => {
				return {
					key: t.mail,
					value: t.displayName,
					text: t.displayName
				};
			})
		);
	});

	return (
		<>
			<BarcodeReader
				onScan={res => {
					const item = _.filter(data, ['structureid', res]);
					setRecord(item[item.length - 1]);
				}}
			/>
			<Modal open={!!record} onClose={() => setRecord(null)}>
				<Modal.Header
					style={{
						fontSize: 12,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<div>
						<Header as="h2">
							{record && moment(record.maintanancedate).format('ll')}
							<Header.Subheader>Maintenance Date</Header.Subheader>
						</Header>
						<div>{record && record.structureid}</div>
						<div>Field Technician: {record && record.creator}</div>
						<div>Laboratory Technician: {record && record.laboratorytech}</div>
					</div>
				</Modal.Header>
				<Modal.Content>
					<Formik
						initialValues={{
							OBJECTID: (record && record.OBJECTID) || '',
							larvae_presence: (record && record.larvae_presence) || '',
							larvae_aedes_aegypti:
								(record && record.larvae_aedes_aegypti) || '',
							larvae_aedes_mediovittatus:
								(record && record.larvae_aedes_mediovittatus) || '',
							larvae_aedes_taeniorhynchus:
								(record && record.larvae_aedes_taeniorhynchus) || '',
							larvae_no_id: (record && record.larvae_no_id) || '',
							larvae_pupae_adults:
								(record && record.larvae_pupae_adults) || 'No',
							larvae_comments: (record && record.larvae_comments) || '',
							larvae_id_technician:
								(record && record.larvae_id_technician) || '',
							larvae_count: (record && record.larvae_count) || ''
						}}
						validationSchema={Yup.object().shape({
							larvae_presence: Yup.string().required('This field is required'),
							larvae_id_technician: Yup.string().required(
								'This field is required'
							)
						})}
						onSubmit={async (values, { resetForm }) => {
							fetch('/api/larvae-update', {
								method: 'post',
								body: JSON.stringify(values),
								headers: {
									Accept: 'application/json',
									'Content-Type': 'application/json'
								}
							})
								.then(res => res.json())
								.then(res => {
									if (res.error) {
										dispatch(
											addToast({
												type: 'error',
												message: res.error
											})
										);
									} else {
										resetForm();
										setRecord(null);
										dispatch(fetchInbox());
										dispatch(
											addToast({
												type: 'success',
												message: res[0].success
											})
										);
									}
								})
								.catch(error => {
									dispatch(
										addToast({
											type: 'error',
											message: error.message
										})
									);
								});
						}}
					>
						{props => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit,
								setFieldValue
							} = props;
							return (
								<Form size="small" noValidate>
									<Form.Button
										label="Add one or more pools to this maintenance."
										primary
										size="tiny"
										onClick={() => setSecondOpen(true)}
									>
										<Icon name="plus" /> Pools Editor
									</Form.Button>
									<Form.Group widths="equal">
										<Form.Field required>
											<FormLabel
												text="Presence of larvae"
												required
												error={
													errors.larvae_presence &&
													touched.larvae_presence &&
													errors.larvae_presence
												}
											/>
											<Form.Dropdown
												name="larvae_presence"
												id="larvae_presence"
												fluid
												selection
												search
												options={[
													{
														key: 'Yes (presence of larvae)',
														text: 'Yes (presence of larvae)',
														value: 'Yes (presence of larvae)'
													},
													{
														key: 'No (absence of larvae)',
														text: 'No (absence of larvae)',
														value: 'No (absence of larvae)'
													},
													{
														key: 'Missing sample',
														text: 'Missing sample',
														value: 'Missing sample'
													}
												]}
												value={values.larvae_presence}
												onChange={(e, { value }) => {
													setFieldValue('larvae_presence', value);
												}}
												placeholder="Select one..."
											/>
										</Form.Field>
										<Form.Field required>
											<FormLabel
												text="ID Technician"
												required
												error={
													errors.larvae_id_technician &&
													touched.larvae_id_technician &&
													errors.larvae_id_technician
												}
											/>
											<Form.Dropdown
												name="larvae_id_technician"
												id="larvae_id_technician"
												fluid
												selection
												search
												options={users || []}
												value={values.larvae_id_technician}
												onChange={(e, { value }) => {
													setFieldValue('larvae_id_technician', value);
												}}
												placeholder="Select one..."
											/>
										</Form.Field>
										<Form.Field>
											<FormLabel text="Any pupae or adults?" />
											<Form.Dropdown
												name="larvae_pupae_adults"
												id="larvae_pupae_adults"
												fluid
												selection
												search
												options={[
													{
														key: 'No',
														text: 'No',
														value: 'No'
													},
													{
														key: 'Pupae',
														text: 'Pupae',
														value: 'Pupae'
													},
													{
														key: 'Adult',
														text: 'Adult',
														value: 'Adult'
													},
													{
														key: 'Pupae and adults',
														text: 'Pupae and adults',
														value: 'Pupae and adults'
													}
												]}
												value={values.larvae_pupae_adults}
												onChange={(e, { value }) => {
													setFieldValue('larvae_pupae_adults', value);
												}}
												placeholder="Select one..."
											/>
										</Form.Field>
										<Form.Input
											type="number"
											id="larvae_count"
											name="larvae_count"
											label="Larvae Count"
											placeholder="quantity"
											fluid
											value={values.larvae_count}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
									</Form.Group>
									{values.larvae_presence !== 'Missing sample' && (
										<Form.Group widths="equal">
											<Form.Field>
												<FormLabel text="Aedes aegypti larvae" />
												<Form.Input
													type="number"
													name="larvae_aedes_aegypti"
													fluid
													value={values.larvae_aedes_aegypti}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
											</Form.Field>
											<Form.Field>
												<FormLabel text="Aedes mediovittatus larvae" />
												<Form.Input
													type="number"
													name="larvae_aedes_mediovittatus"
													fluid
													value={values.larvae_aedes_mediovittatus}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
											</Form.Field>
											<Form.Field>
												<FormLabel text="Aedes taeniorhynchus larvae " />
												<Form.Input
													type="number"
													name="larvae_aedes_taeniorhynchus"
													fluid
													value={values.larvae_aedes_taeniorhynchus}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
											</Form.Field>
											<Form.Field>
												<FormLabel text="No ID larvae" />
												<Form.Input
													type="number"
													name="larvae_no_id"
													fluid
													value={values.larvae_no_id}
													onBlur={handleBlur}
													onChange={handleChange}
												/>
											</Form.Field>
										</Form.Group>
									)}
									<Form.TextArea
										name="larvae_comments"
										label="Comments"
										placeholder="Feels like commenting?"
										value={values.larvae_comments}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									<Button
										positive
										type="submit"
										size="tiny"
										loading={isSubmitting}
										onClick={handleSubmit}
									>
										<Icon name="check" /> Send Maintenance
									</Button>
								</Form>
							);
						}}
					</Formik>
					{record && record.pools && (
						<Table definition size="small">
							<Table.Header>
								<Table.Row textAlign="center">
									<Table.HeaderCell>Pool</Table.HeaderCell>
									<Table.HeaderCell>Box</Table.HeaderCell>
									<Table.HeaderCell>Mosquitoes</Table.HeaderCell>
									<Table.HeaderCell>Technician</Table.HeaderCell>
									<Table.HeaderCell>Actions</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(
									_.orderBy(JSON.parse(record.pools), ['pool'], ['asc']),
									e => {
										return (
											<Table.Row key={e.pool} textAlign="center">
												<Table.Cell>{e.pool}</Table.Cell>
												<Table.Cell>{e.box}</Table.Cell>
												<Table.Cell>{e.mosquitoes}</Table.Cell>
												<Table.Cell>{e.technician}</Table.Cell>
												<Table.Cell width={2}>
													<Button
														size="mini"
														icon="close"
														loading={pool.deleting && pool.active === e.id}
														onClick={async () => {
															setPool(prevState => ({
																...prevState,
																active: e.id,
																deleting: true
															}));

															await fetch('/api/delete-pool', {
																method: 'post',
																body: JSON.stringify({
																	id: e.id
																}),
																headers: {
																	Accept: 'application/json',
																	'Content-Type': 'application/json'
																}
															})
																.then(response => response.json())
																.then(json => {
																	if (json.error) {
																		dispatch(
																			addToast({
																				type: 'error',
																				message: data[0].error
																			})
																		);
																	} else {
																		dispatch(
																			addToast({
																				type: 'success',
																				message: json.success
																			})
																		);

																		let arr = JSON.parse(record.pools);

																		arr = arr.filter(item => {
																			return item.id !== e.id;
																		});

																		setRecord({
																			...record,
																			pools: JSON.stringify(arr)
																		});

																		setPool(prevState => ({
																			...prevState,
																			active: null,
																			deleting: false
																		}));
																		dispatch(fetchInbox());
																	}
																})
																.catch(json => {
																	dispatch(
																		addToast({
																			type: 'warning',
																			message: json.error
																		})
																	);
																});

															setPool(prevState => ({
																...prevState,
																active: null,
																deleting: false
															}));
														}}
													/>
													<Button
														primary
														size="mini"
														icon="pencil"
														onClick={() => {
															setPool(prevState => ({
																...prevState,
																active: e.id,
																edit: e
															}));
															setSecondOpen(true);
														}}
													/>
												</Table.Cell>
											</Table.Row>
										);
									}
								)}
							</Table.Body>
						</Table>
					)}
				</Modal.Content>
				<Modal.Actions>
					<Button
						onClick={() => setRecord(null)}
						size="mini"
						content="Cancel"
					/>
				</Modal.Actions>
				<Modal
					size="mini"
					open={secondOpen}
					onClose={() => {
						setSecondOpen(false);
					}}
				>
					<Modal.Header>Add Pools</Modal.Header>
					<Modal.Content>
						<p>Add pools to this maintenance</p>
						<Formik
							initialValues={{
								pool: pool.edit ? pool.edit.pool : '',
								box: pool.edit ? pool.edit.box : '',
								count: pool.edit ? pool.edit.mosquitoes : '',
								technician: pool.edit ? pool.edit.technician : ''
							}}
							onSubmit={async (values, { resetForm }) => {
								setPool(prevState => ({
									...prevState,
									loading: true
								}));

								if (pool.edit) {
									fetch('/api/edit-pool', {
										method: 'post',
										body: JSON.stringify({
											id: parseInt(pool.edit.id, 10),
											pool: values.pool,
											box: parseInt(values.box, 10),
											mosquitoes: parseInt(values.count, 10),
											technician: values.technician
										}),
										headers: {
											Accept: 'application/json',
											'Content-Type': 'application/json'
										}
									})
										.then(response => response.json())
										.then(json => {
											let arr = JSON.parse(record.pools);
											arr = arr.filter(item => {
												return item.pool !== values.pool;
											});
											arr.push({
												id: parseInt(pool.edit.id, 10),
												pool: values.pool,
												box: parseInt(values.box, 10),
												mosquitoes: parseInt(values.count, 10),
												technician: values.technician
											});
											setRecord({
												...record,
												pools: JSON.stringify(arr)
											});

											dispatch(
												addToast({
													type: 'success',
													message: json[0].response
												})
											);
											dispatch(fetchInbox());
											resetForm();
											setSecondOpen(false);
										})
										.catch(error => {
											dispatch(
												addToast({
													type: 'warning',
													message: error.message
												})
											);
										});
								} else {
									fetch('/api/add-larvae-pool', {
										method: 'post',
										body: JSON.stringify({
											id: parseInt(record.OBJECTID, 10),
											pool: values.pool,
											box: parseInt(values.box, 10),
											mosquitoes: parseInt(values.count, 10),
											email,
											organization: values.pool.toString().slice(0, 5),
											technician: values.technician
										}),
										headers: {
											Accept: 'application/json',
											'Content-Type': 'application/json'
										}
									})
										.then(response => response.json())
										.then(json => {
											if (json[0].error) {
												dispatch(
													addToast({
														type: 'error',
														message: json[0].error
													})
												);
											} else {
												const arr = JSON.parse(record.pools);
												if (arr) {
													arr.push({
														id: parseInt(record.OBJECTID, 10),
														pool: values.pool,
														box: parseInt(values.box, 10),
														mosquitoes: parseInt(values.count, 10),
														technician: values.technician
													});

													setRecord({
														...record,
														pools: JSON.stringify(arr)
													});
												} else {
													setRecord({
														...record,
														pools: JSON.stringify([
															{
																id: parseInt(record.OBJECTID, 10),
																pool: values.pool,
																box: parseInt(values.box, 10),
																mosquitoes: parseInt(values.count, 10),
																technician: values.technician
															}
														])
													});
												}

												dispatch(
													addToast({
														type: 'success',
														message: json[0].success
													})
												);

												dispatch(fetchInbox());
												resetForm();
											}
										})
										.catch(error => {
											dispatch(
												addToast({
													type: 'warning',
													message: error.message
												})
											);
										});
								}

								setPool(prevState => ({
									...prevState,
									loading: false
								}));
							}}
							validationSchema={Yup.object().shape({
								pool: Yup.string()
									.max(12, 'The pool number must have 12 values')
									.min(12, 'The pool number must have 12 values')
									.matches(/^[0-9]*$/, {
										message: 'Looks like you have un letter in the number',
										excludeEmptyString: true
									})
									.required('This field is required'),
								box: Yup.number().required('This field is required'),
								count: Yup.number()
									.min(5, 'The minimum mosquito count is 5')
									.max(10, 'The maximum mosquito count is 10')
									.integer('The value must be integer')
									.required('This field is required')
							})}
						>
							{props => {
								const {
									values,
									touched,
									errors,
									isSubmitting,
									handleChange,
									handleBlur,
									handleSubmit,
									setFieldValue
								} = props;
								return (
									<Form
										onSubmit={handleSubmit}
										loading={isSubmitting}
										size="mini"
										noValidate
									>
										<Form.Field
											required
											label="Pool Number"
											id="pool"
											placeholder="Pool Number"
											type="text"
											control={Input}
											value={values.pool}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												errors.pool &&
												touched.pool && {
													content: errors.pool,
													pointing: 'above'
												}
											}
										/>
										<Form.Field
											required
											label="Box Number"
											id="box"
											placeholder="Box Number"
											type="text"
											control={Input}
											value={values.box}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												errors.box &&
												touched.box && {
													content: errors.box,
													pointing: 'above'
												}
											}
										/>
										<Form.Field
											required
											label="Mosquito Count"
											id="count"
											placeholder="Mosquito Count"
											type="text"
											control={Input}
											value={values.count}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												errors.count &&
												touched.count && {
													content: errors.count,
													pointing: 'above'
												}
											}
										/>
										<Form.Field required>
											<FormLabel
												required
												text="Pool Technician"
												error={
													errors.technician &&
													touched.technician &&
													errors.technician
												}
											/>
											<Form.Dropdown
												fluid
												selection
												search
												clearable
												value={values.technician}
												options={users}
												placeholder="Select name..."
												onChange={(e, { value }) => {
													setFieldValue('technician', value);
												}}
											/>
										</Form.Field>
										<Button
											type="submit"
											primary
											loading={pool.loading}
											size="mini"
										>
											Submit
										</Button>
									</Form>
								);
							}}
						</Formik>
					</Modal.Content>
					<Modal.Actions>
						<Button
							icon="check"
							content="All Done"
							size="mini"
							onClick={() => {
								setSecondOpen(false);
								setPool(prevState => ({
									...prevState,
									active: null,
									edit: null
								}));
							}}
						/>
					</Modal.Actions>
				</Modal>
			</Modal>
			<Grid.Row columns={1}>
				<Grid.Column>
					<div className="pre">
						<div className="pre-header x-small p-10 strong">
							{data && data.length.toLocaleString()} Entries @{' '}
							{new Date().toDateString()}
						</div>
						<Segment basic size="small">
							<Input
								fluid
								size="small"
								placeholder="Filter list..."
								value={value}
								onChange={e => setValue(e.target.value)}
								icon={
									value !== '' ? (
										<Icon
											link
											name="close"
											onClick={() => {
												setValue('');
											}}
										/>
									) : (
										'filter'
									)
								}
							/>
						</Segment>
						{data &&
							_.map(
								matchSorter(data, value, {
									keys: ['structureid']
								}),
								item => {
									return (
										<Segment
											key={item.OBJECTID}
											vertical
											size="mini"
											className="fade-in"
										>
											<Grid>
												<Grid.Row
													columns={3}
													verticalAlign="middle"
													style={{ padding: 10 }}
												>
													<Grid.Column
														mobile={3}
														tablet={3}
														computer={3}
														stretched
													>
														<Badge
															content={item.structureid.toUpperCase()}
															color="purple"
														/>
													</Grid.Column>
													<Grid.Column mobile={9} tablet={10} computer={10}>
														<div
															style={{
																color: 'rgba(0,0,0,.4)'
															}}
														>
															{moment(item.maintanancedate).format('ll')}
														</div>
														<div>
															<strong>
																Hatched Eggs: {item.hatched_eggs || 0}
															</strong>
														</div>
														<div>
															<strong>
																Collapsed Eggs: {item.collapsed_eggs || 0}
															</strong>
														</div>
														<div>
															<strong>
																Healthy Eggs: {item.healthy_eggs || 0}
															</strong>
														</div>
														<div>
															Laboratory Technician: {item.laboratorytech}
														</div>
														<div>
															Field Worker:{' '}
															<a href={`mailto:${item.creator}`}>
																{item.creator}
															</a>
														</div>
														<div>
															Comments:{' '}
															{item.labidcomments || 'No comments available'}
														</div>
													</Grid.Column>
													<Grid.Column mobile={4} tablet={3} computer={3}>
														<Button
															primary
															fluid
															floated="right"
															size="mini"
															onClick={() => setRecord(item)}
														>
															Edit
														</Button>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Segment>
									);
								}
							)}
						{!data && (
							<div>
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
							</div>
						)}
					</div>
				</Grid.Column>
			</Grid.Row>
		</>
	);
}

export default Inbox;
