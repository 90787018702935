import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLineData } from '../../../../store/actions/ChartActions';

const Chart = () => {
	const data = useSelector(state => state.charts.lineData);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchLineData);
	});

	return (
		<div style={{ height: 300 }}>
			<Line
				data={{
					labels: _.map(data, e => {
						return e.x;
					}),
					datasets: [
						{
							label: 'Pools',
							fill: false,
							lineTension: 0.4,
							borderColor: '#f9886c',
							pointRadius: 4,
							pointBorderColor: '#ffffff',
							pointBackgroundColor: '#f9886c',
							pointBorderWidth: 2,
							pointHoverRadius: 6,
							pointHoverBackgroundColor: '#f9886c',
							pointHoverBorderColor: '#ffffff',
							pointHoverBorderWidth: 3,
							data: _.map(data, e => {
								return e.y;
							})
						}
					]
				}}
				options={{
					scales: {
						xAxes: [
							{
								scaleLabel: {
									display: true,
									labelString: 'Month'
								},
								gridLines: {
									display: false
								}
							}
						]
					}
				}}
			/>
		</div>
	);
};

export default Chart;
