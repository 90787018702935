import {
	SET_ARBOVIRUS_PLATE,
	SET_ARBOVIRUS_POOLS,
	SET_ARBOVIRUS_RESULTS,
	SET_ARBOVIRUS_SEQ_PLATE,
	SET_ORMA_PLATE,
	SET_ORMA_RESULTS,
	SET_WOLBACHIA_PLATE,
	SET_WOLBACHIA_RESULTS
} from '../actions/types';

const INITIAL_STATE = {
	index: 0,
	title: 'Pools',
	subtitle: 'Use the following table to create, edit or delete super pools for the available pools.'
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_ARBOVIRUS_POOLS:
			return {
				...state,
				index: 0,
				title: 'Pools',
				subtitle: 'Use the following table to create, edit or delete super pools for the available pools.'
			};
		case SET_ARBOVIRUS_PLATE:
			return {
				...state,
				index: 1,
				title: 'Plate',
				subtitle: 'Add pools into the wells for the following plate.'
			};
		case SET_ARBOVIRUS_RESULTS:
			return {
				...state,
				index: 2,
				title: 'Results',
				subtitle: 'Add sample results for each well for the following plate.'
			};
		case SET_ARBOVIRUS_SEQ_PLATE:
			return {
				...state,
				index: 3,
				title: 'DENV SEQ Plate',
				subtitle: 'Add pools into the wells for the following DENV SEQ plate.'
			};
		case SET_WOLBACHIA_PLATE:
			return {
				...state,
				index: 4,
				title: 'Wolbachia',
				subtitle: 'Add pools into the wells for the following Wolbachia plate.'
			};
		case SET_WOLBACHIA_RESULTS:
			return {
				...state,
				index: 5,
				title: 'Wolbachia Results',
				subtitle: 'Add sample results for each well for the following plate.'
			};
		case SET_ORMA_PLATE:
			return {
				...state,
				index: 6,
				title: 'Oropouche and Mayaro Plate',
				subtitle: 'Add pools into the wells for the following plate.'
			};
		case SET_ORMA_RESULTS:
			return {
				...state,
				index: 7,
				title: 'Oropouche and Mayaro Results ',
				subtitle: 'Add sample results for each well for the following plate.'
			};
		default:
			return state;
	}
};
