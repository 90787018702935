import React, { Component } from 'react';
import {
	Image,
	Segment,
	Grid,
	Header,
	Button,
	Container
} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import NotFoundImage from '../../../assets/notfound.svg';

class NotFound extends Component {
	render() {
		return (
			<Segment attached inverted vertical padded="very">
				<Container>
					<Grid
						inverted
						container
						stackable
						verticalAlign="middle"
						textAlign="center"
					>
						<Grid.Row columns={2}>
							<Grid.Column>
								<Segment inverted>
									<Image size="large" centered src={NotFoundImage} />
								</Segment>
							</Grid.Column>
							<Grid.Column>
								<Segment inverted>
									<Header as="h1">404</Header>
									<Header as="h4">
										Looks like this page is missing. don&apos;t worry though,
										our best man is on the case.
									</Header>
									<p>
										<small>
											Meanwhile, why don&apos;t you try again by going
										</small>
									</p>
									<Button
										color="violet"
										icon="arrow left"
										size="small"
										as={NavLink}
										exact
										to="/"
										content="Back Home"
									/>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Segment>
		);
	}
}

export default NotFound;
