import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { fetchQuickFacts } from '../../store/actions/BalloonActions';

class Balloon extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visibility: 'hidden',
			opacity: 0,
			active: ''
		};
	}

	componentDidMount() {
		this.props.fetchQuickFacts();
	}

	onClick = () => {
		if (this.state.visibility === 'hidden') {
			this.props.fetchQuickFacts();
			this.setState({
				visibility: 'visible',
				opacity: 1,
				active: 'active'
			});
		} else {
			this.setState({
				visibility: 'hidden',
				opacity: 0,
				active: ''
			});
		}
	};

	render() {
		return (
			<div className="balloon-container">
				<div
					className="balloon"
					style={{
						visibility: this.state.visibility,
						opacity: this.state.opacity
					}}
				>
					<div className="balloon-title">
						<span>Latest Entries</span>
						<span
							style={{
								fontWeight: 400,
								color: 'rgba(255,255,255,0.5)'
							}}
						>
							{this.props.time}
						</span>
					</div>
					<div className="balloon-content">
						{this.props.data && (
							<div>
								<div>
									<span className="strong">Pool: </span>{' '}
									{this.props.data[0].pool}
								</div>
								<div>
									<span className="strong">Super Pool: </span>{' '}
									{this.props.data[0].superpool}
								</div>
								<div>
									<span className="strong">Box: </span> {this.props.data[0].box}
								</div>
								<div>
									<span className="strong">Plate: </span>{' '}
									{this.props.data[0].plate}
								</div>
							</div>
						)}
					</div>
					<div className="balloon-buttons">
						<Button
							content="Close"
							size="mini"
							onClick={() => {
								this.setState({
									visibility: 'hidden',
									opacity: 0,
									active: ''
								});
							}}
						/>
						<Button
							onClick={this.props.fetchQuickFacts}
							content="Reload"
							size="mini"
							loading={this.props.loading}
						/>
					</div>
				</div>
				<div
					className={`balloon-button ${this.state.active}`}
					role="button"
					tabIndex={-1}
					onClick={this.onClick}
				>
					<span className="warning-badge" />
					Quick Facts
				</div>
			</div>
		);
	}
}

const mapPropsToState = state => {
	return {
		visible: state.balloon.visible,
		loading: state.balloon.loading,
		data: state.balloon.data,
		time: state.balloon.date
	};
};

export default connect(
	mapPropsToState,
	{ fetchQuickFacts }
)(Balloon);
