import {
	QUICK_FACTS_ERROR,
	QUICK_FACTS_LOADING,
	QUICK_FACTS_SUCCESS,
	SHOW_BALLOON,
	HIDE_BALLOON
} from '../actions/types';

const INITIAL_STATE = {
	loading: false,
	error: null,
	data: null,
	date: new Date().toLocaleString(),
	visible: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case QUICK_FACTS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case QUICK_FACTS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				date: new Date().toLocaleTimeString()
			};
		case QUICK_FACTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case SHOW_BALLOON:
			return {
				...state,
				visible: true
			};
		case HIDE_BALLOON:
			return {
				...state,
				visible: false
			};
		default:
			return state;
	}
};
