import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import './style/index.css';
import App from './components/App';
import ReduxStore from './utils/ReduxStore';

ReactDOM.render(
	<Provider store={ReduxStore}>
		<App />
	</Provider>,
	document.querySelector('#root')
);
