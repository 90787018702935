import React from 'react';
import { Container, Grid, Header, Image, Button } from 'semantic-ui-react';
import { Element, Link } from 'react-scroll';
import Outbox from './shared-components/Outbox';
import image from '../../../assets/outbox.svg';
import Footer from '../../shared-components/Footer';
import Navbar from '../../shared-components/Navbar';

function Pools() {
	return (
		<>
			<Navbar />
			<Grid stackable verticalAlign="middle" padded="vertically">
				<Grid.Row columns={1} color="blue" style={{ minHeight: 400 }}>
					<Grid.Column>
						<Grid
							container
							stackable
							style={{ paddingTop: 80, paddingBottom: 80 }}
						>
							<Grid.Row columns={2} verticalAlign="middle">
								<Grid.Column>
									<Image centered src={image} height={200} />
								</Grid.Column>
								<Grid.Column>
									<Header as="h1" inverted>
										Pools Outbox
									</Header>
									<p className="quiet">
										Pool samples to detect viruses in mosquito samples through
										Reverse Transcriptase Polymerase Chain Reaction (RT-PCR)
									</p>
									<Button
										primary
										size="mini"
										content="Edit Now!!"
										as={Link}
										to="segment"
										smooth
										duration={500}
										spy
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} as={Element} name="segment">
					<Grid.Column>
						<Container text>
							<Grid stackable padded="vertically">
								<Grid.Row columns={1}>
									<Grid.Column>
										<Header as="h2">Pools Editor</Header>
										<p className="small quiet">
											Use the following list to edit or delete pools for the
											available surveillance maintenance records
										</p>
									</Grid.Column>
								</Grid.Row>
								<Outbox />
							</Grid>
						</Container>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Footer />
		</>
	);
}

export default Pools;
