import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
	Button,
	Form,
	Grid,
	Input,
	Label,
	Modal,
	Popup,
	Container
} from 'semantic-ui-react';
import {
	changePlateNumber,
	changeValue,
	finishPlate,
	hideModal,
	loadPlate,
	showModal
} from '../../../../store/actions/PlateActions';
import { getAdmin } from '../../../auth-utils';

class Plate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	onClick = index => {
		const value = this.props.wells[index].pool
			? this.props.wells[index].pool
			: '';
		this.props.showModal(index, value);
		setTimeout(() => {
			this.input && this.input.focus();
		}, 1);
	};

	handleWell = e => {
		const data = JSON.parse(JSON.stringify(this.props.wells));

		if (e.target.value !== '') {
			data[this.props.index].pool = e.target.value;
			this.props.changeValue(e.target.value, data);
		} else {
			data[this.props.index].pool = '';
			this.props.changeValue(e.target.value, data);
		}
	};

	onSubmit = () => {
		this.setState({
			loading: true
		});

		if (this.props.wells[this.props.index].pool !== '') {
			fetch('/api/add-well', {
				method: 'post',
				body: JSON.stringify({
					pool: this.props.wells[this.props.index].pool,
					email: this.props.email,
					well: this.props.wells[this.props.index].well,
					plate: this.props.plate
				}),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			})
				.then(() => {
					this.setState({
						loading: false
					});
				})
				.then(() => {
					this.props.hideModal();
				})
				.catch(error => {
					this.setState({
						loading: false
					});
					Modal.error({
						title: 'Error',
						content: `${error.message}`
					});
				});
		} else {
			this.onDelete();
		}
	};

	onCancel = () => {
		fetch('/api/delete-plate', {
			method: 'post',
			body: JSON.stringify({
				plate: this.props.plate,
				email: this.props.email
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				this.props.finishPlate();
			})
			.catch(error => {
				console.log(error.message);
			});
	};

	onDelete = () => {
		const { well } = this.props.wells[this.props.index];
		const data = JSON.parse(JSON.stringify(this.props.wells));

		fetch('/api/remove-well', {
			method: 'post',
			body: JSON.stringify({
				well,
				email: this.props.email,
				plate: this.props.plate
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				data[this.props.index].pool = '';
				data[this.props.index].className = '';
				this.props.changeValue('', data);
				this.props.hideModal();
				this.setState({
					loading: false
				});
			})
			.catch(error => {
				this.setState({
					loading: false
				});
				console.log(error.message);
			});
	};

	render() {
		return (
			<Container>
				<Modal
					size="mini"
					open={this.props.visible}
					onClose={this.props.hideModal}
				>
					<Modal.Content>
						<p className="strong small">Pool Number</p>
						<p className="quiet x-small">
							Scan the pool number and add it to the selected plate well
						</p>
						<Input
							size="small"
							fluid
							ref={input => {
								this.input = input;
							}}
							placeholder="0000..."
							value={this.props.value}
							onChange={this.handleWell}
						/>
						<p className="x-small quiet mt-10">
							Clear the input and submit if you like to remove the pool from the
							well
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button size="mini" onClick={this.props.hideModal}>
							Cancel
						</Button>
						<Button
							color="blue"
							size="mini"
							onClick={this.onSubmit}
							loading={this.state.loading}
						>
							Submit
						</Button>
					</Modal.Actions>
				</Modal>
				<Grid stackable padded="vertically">
					<Grid.Row columns={1} centered>
						<Grid.Column computer={5} tablet={8}>
							<Form size="small">
								<Form.Field
									icon="search"
									loading={this.props.plateLoading}
									size="small"
									placeholder="Plate Number.."
									value={this.props.plate}
									control={Input}
									onChange={e => {
										this.props.changePlateNumber(e.target.value);
									}}
								/>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1} centered>
						<Grid.Column>
							<div className="pre">
								<div className="pre-header x-small p-10 strong">Plate</div>
								{this.props.plate > 0 ? (
									<Grid stackable celled="internally">
										<Grid.Row columns={1}>
											<Grid.Column textAlign="right">
												<Popup
													header="Warning!!"
													content="Deleting the plate it's irreversible!!"
													size="mini"
													position="top right"
													trigger={
														<Button
															primary
															size="mini"
															content="Delete"
															loading={this.props.plateLoading}
															onClick={this.onCancel}
															disabled={this.props.plate < 1}
														/>
													}
												/>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row only="computer" columns={12}>
											{_.map(
												_.orderBy(this.props.wells, ['index'], ['asc']),
												(item, index) => {
													return (
														<Grid.Column
															key={item.well}
															textAlign="center"
															verticalAlign="middle"
														>
															<div className="x-small truncate quiet">
																<Label
																	circular
																	size="mini"
																	empty
																	color={item.pool ? 'green' : 'yellow'}
																/>
															</div>
															<div
																aria-label="Add Pool Button"
																role="button"
																tabIndex={-1}
																className="pool-button"
																onClick={() => {
																	this.onClick(index);
																}}
															>
																{item.well}
															</div>
															<div className="x-small truncate quiet">
																{item.pool
																	? _.trim(_.toString(item.pool), '4000000')
																	: 'Edit'}
															</div>
														</Grid.Column>
													);
												}
											)}
										</Grid.Row>
										<Grid.Row only="tablet" columns={6}>
											{_.map(
												_.orderBy(this.props.wells, ['index'], ['asc']),
												(item, index) => {
													return (
														<Grid.Column
															key={item.well}
															textAlign="center"
															verticalAlign="middle"
														>
															<div className="x-small truncate quiet">
																<Label
																	circular
																	size="mini"
																	empty
																	color={item.pool ? 'green' : 'yellow'}
																/>
															</div>
															<div
																aria-label="Add Pool Button"
																role="button"
																tabIndex={-1}
																className="pool-button"
																onClick={() => {
																	this.onClick(index);
																}}
															>
																{item.well}
															</div>
															<div className="x-small truncate quiet">
																{item.pool
																	? _.trim(_.toString(item.pool), '4000000')
																	: 'Edit'}
															</div>
														</Grid.Column>
													);
												}
											)}
										</Grid.Row>
										<Grid.Row only="mobile" columns={3}>
											{_.map(
												_.orderBy(this.props.wells, ['index'], ['asc']),
												(item, index) => {
													return (
														<Grid.Column
															key={item.well}
															textAlign="center"
															verticalAlign="middle"
														>
															<div className="x-small truncate quiet">
																<Label
																	circular
																	size="mini"
																	empty
																	color={item.pool ? 'green' : 'yellow'}
																/>
															</div>
															<div
																aria-label="Add Pool Button"
																role="button"
																tabIndex={-1}
																className="pool-button"
																onClick={() => {
																	this.onClick(index);
																}}
															>
																{item.well}
															</div>
															<div className="x-small truncate quiet">
																{item.pool
																	? _.trim(_.toString(item.pool), '4000000')
																	: 'Edit'}
															</div>
														</Grid.Column>
													);
												}
											)}
										</Grid.Row>
									</Grid>
								) : (
									<div>
										{this.props.loading ? (
											<div className="pre-loading x-small p-10 strong">
												Loading
											</div>
										) : (
											<div className="pre-loading x-small p-10 strong">
												{getAdmin(this.props.role)
													? 'Enter plate number on the input field to load pools'
													: 'You have no privileges to access this section'}
											</div>
										)}
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
									</div>
								)}
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		role: state.auth.role,
		email: state.auth.email,
		plate: state.plate.plate,
		wells: state.plate.wells,
		visible: state.plate.visible,
		value: state.plate.value,
		index: state.plate.index,
		date: state.plate.date,
		loading: state.plate.loading,
		plateData: state.plate.plateData,
		plateLoading: state.plate.plateLoading,
		plateError: state.plate.plateError
	};
};

export default connect(
	mapStateToProps,
	{
		loadPlate,
		showModal,
		hideModal,
		changeValue,
		finishPlate,
		changePlateNumber
	}
)(Plate);
