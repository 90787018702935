import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';

const Chart = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const loadData = async () => {
		await fetch('/api/weekly-processed')
			.then(response => response.json())
			.then(res => {
				setData({
					labels: _.map(res, e => {
						return e.zone;
					}),
					datasets: [
						{
							label: 'Success',
							backgroundColor: '#56b881',
							borderWidth: 0,
							hoverBackgroundColor: '#70d29b',
							data: _.map(res, e => {
								return e.success;
							})
						},
						{
							label: 'Pending',
							backgroundColor: '#e55e5e',
							borderWidth: 0,
							hoverBackgroundColor: '#ff6e7f',
							data: _.map(res, e => {
								return e.pending;
							})
						}
					]
				});
			})
			.catch(err => {
				setError(err.message);
			});
	};

	useEffect(() => {
		loadData().then(() => setLoading(false));
	}, []);

	return (
		<div style={{ height: 300 }} className="text-center flex-middle">
			{loading && (
				<>
					<div className="message-loading mb-10" />
					<div className="small text-center">Loading</div>
				</>
			)}
			{error && !loading && (
				<div className="small strong text-center">error</div>
			)}
			{!loading && !error && (
				<Bar
					data={data}
					options={{
						scales: {
							xAxes: [
								{
									scaleLabel: {
										display: true,
										labelString: 'Zone'
									},
									gridLines: {
										display: false
									}
								}
							]
						}
					}}
					legend={{
						display: true,
						position: 'bottom',
						labels: {
							fontStyle: 'bold',
							usePointStyle: true
						}
					}}
				/>
			)}
		</div>
	);
};

export default Chart;
