import {
	POOLS_CONFIRM_HIDE,
	POOLS_CONFIRM_SHOW,
	POOLS_DATA_ERROR,
	POOLS_DATA_LOADING,
	POOLS_DATA_SUCCESS,
	POOLS_MODAL_HIDE,
	POOLS_MODAL_SHOW
} from './types';

export const fetchOutbox = () => {
	return dispatch => {
		dispatch({
			type: POOLS_DATA_LOADING
		});

		fetch(`/api/pools-outbox`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: POOLS_DATA_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: POOLS_DATA_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const confirmShow = () => {
	return dispatch => {
		dispatch({
			type: POOLS_CONFIRM_SHOW
		});
	};
};

export const confirmHide = () => {
	return dispatch => {
		dispatch({
			type: POOLS_CONFIRM_HIDE
		});
	};
};

export const modalShow = (pool, box, mosquitoes, id, sex, technician) => {
	return dispatch => {
		dispatch({
			type: POOLS_MODAL_SHOW,
			pool,
			box,
			mosquitoes,
			id,
			sex,
			technician
		});
	};
};

export const modalHide = () => {
	return dispatch => {
		dispatch({
			type: POOLS_MODAL_HIDE
		});
	};
};
