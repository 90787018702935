import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, TextArea, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import FadeIn from 'react-fade-in';
import { Formik } from 'formik';
import { close, getMessages } from '../../store/actions/MessengerActions';
import { getAdmin } from '../auth-utils';

class Messenger extends Component {
	constructor(props) {
		super(props);

		this.state = {
			response: '',
			delete: ''
		};
	}

	componentDidMount() {
		this.props.getMessages();
	}

	deleteMessage = id => {
		fetch('/api/delete-message', {
			method: 'POST',
			body: JSON.stringify({
				id
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(json => {
				this.setState({
					delete: json[0].message
				});
				this.props.getMessages();
			})
			.then(() => {
				setTimeout(() => {
					this.setState({
						delete: ''
					});
				}, 10000);
			})
			.catch(err => {
				this.setState({
					delete: err.message
				});

				setTimeout(() => {
					this.setState({
						delete: ''
					});
				}, 10000);
			});
	};

	isToday = someDate => {
		const today =
			new Date().toDateString() === new Date(someDate).toDateString();

		if (today) {
			return `Today ${new Date(someDate).toLocaleTimeString()}`;
		}

		return new Date(someDate).toLocaleString();
	};

	render() {
		return (
			<div className="fill-black small messenger">
				{this.props.loading && (
					<div className="messages p-10">
						<div className="text-center flex-middle">
							<div className="message-loading mb-10" />
							<div className="small text-center">Loading</div>
						</div>
					</div>
				)}
				{!this.props.loading &&
					(this.props.messages && this.props.messages.length === 0 ? (
						<div className="messages p-10">
							<div className="text-center flex-middle">
								<Icon name="bell outline" />
								<div className="mt-10">No messages on this session</div>
							</div>
						</div>
					) : (
						<div className="messages p-10">
							{this.state.delete && (
								<FadeIn className="mb-10">
									<p className="small strong orange">{this.state.delete}</p>
								</FadeIn>
							)}
							{_.map(this.props.messages, e => {
								return (
									<FadeIn key={e.id}>
										<div className="italic x-small messages-date">
											{this.isToday(e.created_at)}
										</div>
										<div className="messages-inner">
											{getAdmin(this.props.role) && (
												<div
													className="messages-delete"
													role="button"
													tabIndex={-1}
													onClick={() => this.deleteMessage(e.id)}
												>
													x
												</div>
											)}
											<div className="strong">{e.subject}</div>
											<div>{e.message}</div>
											<div className="x-small">From: {e.created_by}</div>
										</div>
									</FadeIn>
								);
							})}
						</div>
					))}
				{getAdmin(this.props.role) && (
					<div
						className="p-10"
						style={{ borderTop: '1px solid rgba(255,255,255, 0.1)' }}
					>
						<p className="strong x-small mb-0">New Message</p>
						<p className="x-small">
							Create a new message for the app users. This message will be
							visible for all the users. Only administrators can create and
							delete messages.
						</p>
						<Formik
							initialValues={{
								subject: '',
								message: ''
							}}
							onSubmit={(values, { setSubmitting, resetForm }) => {
								fetch('/api/new-message', {
									method: 'POST',
									body: JSON.stringify({
										subject: values.subject,
										message: values.message,
										email: this.props.email
									}),
									headers: {
										'Content-Type': 'application/json'
									}
								})
									.then(response => response.json())
									.then(json => {
										console.log(json);
										setSubmitting(false);
										resetForm();
										this.props.getMessages();
										this.setState({
											response: json[0].message
										});
									})
									.then(() => {
										setTimeout(() => {
											this.setState({
												response: ''
											});
										}, 10000);
									})
									.catch(err => {
										this.setState({
											response: err.message
										});

										setTimeout(() => {
											this.setState({
												response: ''
											});
										}, 10000);
									});
							}}
						>
							{props => {
								const {
									values,
									isSubmitting,
									handleChange,
									handleBlur,
									handleSubmit
								} = props;
								return (
									<Form inverted size="small" onSubmit={handleSubmit}>
										{this.state.response && (
											<FadeIn className="mb-10">
												<p className="small orange">{this.state.response}</p>
											</FadeIn>
										)}
										<Form.Field
											size="small"
											required
											control={Input}
											placeholder="Subject"
											name="subject"
											value={values.subject}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										<Form.Field
											size="small"
											required
											control={TextArea}
											placeholder="Message"
											name="message"
											value={values.message}
											onBlur={handleBlur}
											onChange={handleChange}
										/>
										<Form.Button
											primary
											size="mini"
											type="submit"
											loading={isSubmitting}
										>
											Submit
										</Form.Button>
									</Form>
								);
							}}
						</Formik>
					</div>
				)}
			</div>
		);
	}
}

const mapPropsToState = state => {
	return {
		role: state.auth.role,
		email: state.auth.email,
		open: state.messenger.open,
		loading: state.messenger.loading,
		messages: state.messenger.messages
	};
};

export default connect(
	mapPropsToState,
	{ close, getMessages }
)(Messenger);
