import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Grid, Icon, Input, Label, Modal, Popup } from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { DateInput } from 'semantic-ui-calendar-react';
import { getAdmin } from '../../../auth-utils';
import { addToast } from '../../../../store/actions/ToastActions';

function SEQPlate() {
	const dispatch = useDispatch();
	const [plate, setPlate] = useState('');
	const [restoring, setRestoring] = useState(false);
	const [searching, setSearching] = useState(false);
	const [wells, setWells] = useState([]);
	const [open, setOpen] = useState(false);
	const role = useSelector(state => state.auth.role);
	const email = useSelector(state => state.auth.email);

	const formik = useFormik({
		initialValues: {
			pool: '',
			date: '',
			well: ''
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			if (values.pool) {
				axios
					.post('/api/add-denv-seq-well', {
						pool: values.pool,
						date: values.date,
						well: values.well,
						plate,
						email
					})
					.then(() => {
						dispatch(
							addToast({
								type: 'success',
								message: 'DENV SEQ pool has been successfully added.'
							})
						);
						const newWells = [...wells];
						const index = _.findIndex(wells, { well: values.well });
						if (index > -1) {
							newWells[index].pool = values.pool;
							newWells[index].date = values.date;
						}
						setWells(newWells);
					})
					.catch(err => {
						dispatch(
							addToast({
								type: 'error',
								message: err.message
							})
						);
					})
					.finally(() => {
						setSubmitting(false);
						setOpen(false);
						resetForm();
					});
			} else {
				axios
					.post('/api/remove-denv-seq-well', {
						well: values.well,
						plate
					})
					.then(() => {
						dispatch(
							addToast({
								type: 'success',
								message: 'DENV SEQ pool has been successfully removed.'
							})
						);
						const newWells = [...wells];
						const index = _.findIndex(wells, { well: values.well });
						if (index > -1) {
							newWells[index].pool = '';
							newWells[index].date = '';
						}
						setWells(newWells);
					})
					.catch(err => {
						dispatch(
							addToast({
								type: 'error',
								message: err.message
							})
						);
					})
					.finally(() => {
						setSubmitting(false);
						setOpen(false);
					});
			}
		}
	});

	const handleWell = well => {
		const current = _.find(wells, { well });
		formik.setFieldValue('pool', current.pool || '');
		formik.setFieldValue('date', current.date || new Date().toLocaleDateString());
		formik.setFieldValue('well', current.well);
		setOpen(true);
	};

	const restorePlate = async () => {
		if (plate) {
			setRestoring(true);

			await axios
				.post('/api/delete-denv-seq-plate', {
					plate,
					email
				})
				.then(() => {
					setWells([]);
					setPlate('');
				})
				.catch(err => console.error(err))
				.finally(() => setRestoring(false));
		}
	};

	useEffect(() => {
		if (plate.trim() !== '') {
			setSearching(true);

			const timeout = setTimeout(() => {
				setSearching(true);
				axios
					.post('/api/load-denv-seq-plate', {
						value: plate
					})
					.then(res => {
						setWells(res.data);
					})
					.catch(err => console.error(err))
					.finally(() => setSearching(false));
			}, 500);

			return () => clearTimeout(timeout);
		}
		setWells([]);
		return undefined;
	}, [plate]);

	return (
		<>
			<Modal size="mini" open={open} onClose={() => setOpen(false)}>
				<Modal.Content>
					<p className="strong">Pool</p>
					<p className="quiet">Scan the pool number and add it to the selected plate well</p>
					<Form>
						<DateInput
							name="date"
							value={formik.values.date}
							onChange={(event, { value }) => formik.setFieldValue('date', value)}
							onBlur={() => formik.setFieldTouched('date')}
							placeholder="mm/dd/yyyy"
							dateFormat="L"
							hideMobileKeyboard
							animation="fade"
							closable
							iconPosition="left"
							autoComplete="off"
							clearable
							clearIcon={<Icon name="remove" />}
						/>
						<Input
							name="pool"
							size="small"
							fluid
							placeholder="Pool number..."
							value={formik.values.pool}
							onChange={e => formik.setFieldValue('pool', e.target.value)}
						/>
					</Form>
					<p className="x-small quiet mt-10">Clear the input and submit if you like to remove the pool from the well</p>
				</Modal.Content>
				<Modal.Actions>
					<Button size="mini" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button
						type="submit"
						color="blue"
						size="mini"
						loading={formik.isSubmitting}
						onClick={() => formik.submitForm()}
					>
						Submit
					</Button>
				</Modal.Actions>
			</Modal>
			<Container>
				<Grid stackable padded="vertically">
					<Grid.Row columns={1} centered>
						<Grid.Column computer={5} tablet={8}>
							<Form size="small">
								<Form.Field
									icon="search"
									loading={searching}
									size="small"
									placeholder="DENV SEQ Plate Number.."
									value={plate}
									control={Input}
									onChange={e => setPlate(e.target.value)}
								/>
							</Form>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1} centered>
						<Grid.Column>
							<div className="pre">
								<div className="pre-header x-small p-10 strong">Plate</div>
								{wells.length ? (
									<Grid stackable celled="internally">
										<Grid.Row columns={1}>
											<Grid.Column textAlign="right">
												<Popup
													header="Warning!!"
													content="Deleting the plate it's irreversible!!"
													size="mini"
													position="top right"
													trigger={
														<Button
															primary
															size="mini"
															content="Delete"
															loading={restoring}
															onClick={restorePlate}
															disabled={!plate}
														/>
													}
												/>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row only="computer" columns={12}>
											{_.map(_.orderBy(wells, ['index'], ['asc']), item => {
												return (
													<Grid.Column key={item.well} textAlign="center" verticalAlign="middle">
														<div className="x-small truncate quiet">
															<Label circular size="mini" empty color={item.pool ? 'green' : 'yellow'} />
														</div>
														<div
															aria-label="Add Pool Button"
															role="button"
															tabIndex={-1}
															className="pool-button"
															onClick={() => handleWell(item.well)}
														>
															{item.well}
														</div>
														<div className="x-small truncate quiet">{item.pool ? item.pool : 'Edit'}</div>
													</Grid.Column>
												);
											})}
										</Grid.Row>
										<Grid.Row only="tablet" columns={6}>
											{_.map(_.orderBy(wells, ['index'], ['asc']), item => {
												return (
													<Grid.Column key={item.well} textAlign="center" verticalAlign="middle">
														<div className="x-small truncate quiet">
															<Label circular size="mini" empty color={item.pool ? 'green' : 'yellow'} />
														</div>
														<div
															aria-label="Add Pool Button"
															role="button"
															tabIndex={-1}
															className="pool-button"
															onClick={() => handleWell(item.well)}
														>
															{item.well}
														</div>
														<div className="x-small truncate quiet">{item.pool ? item.pool : 'Edit'}</div>
													</Grid.Column>
												);
											})}
										</Grid.Row>
										<Grid.Row only="mobile" columns={3}>
											{_.map(_.orderBy(wells, ['index'], ['asc']), item => {
												return (
													<Grid.Column key={item.well} textAlign="center" verticalAlign="middle">
														<div className="x-small truncate quiet">
															<Label circular size="mini" empty color={item.pool ? 'green' : 'yellow'} />
														</div>
														<div
															aria-label="Add Pool Button"
															role="button"
															tabIndex={-1}
															className="pool-button"
															onClick={() => handleWell(item.well)}
														>
															{item.well}
														</div>
														<div className="x-small truncate quiet">{item.pool ? item.pool : 'Edit'}</div>
													</Grid.Column>
												);
											})}
										</Grid.Row>
									</Grid>
								) : (
									<div>
										{searching ? (
											<div className="pre-loading x-small p-10 strong">Fetching wells from database...</div>
										) : (
											<div className="x-small p-10 strong">
												{getAdmin(role)
													? 'Enter plate number on the input field to load pools'
													: 'You have no privileges to access this section'}
											</div>
										)}
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
									</div>
								)}
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	);
}

export default SEQPlate;
