import React, { useEffect, useState } from 'react';
import {
	Button,
	Grid,
	Icon,
	Input,
	Segment,
	Confirm,
	Step
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import matchSorter from 'match-sorter';
import { useMount } from 'react-use';
import BarcodeReader from 'react-barcode-reader';
import {
	fetchInbox,
	fetchOutbox,
	fetchQuality,
	fetchViInbox,
	fetchViOutbox,
	getLabUsers,
	setPositive,
	showModal,
	hideModal,
	notFound,
	confirmDuplicated
} from '../../../../store/actions/InboxActions';
import Dialog from './shared-components/dialog/Dialog';
import Badge from '../../../shared-components/Badge';
import { addToast } from '../../../../store/actions/ToastActions';
import { authProvider } from '../../../../utils/AuthProvider';
import { getTechnicians } from '../../../auth-utils';
import ConfirmNotFound from './shared-components/ConfirmNotFound';
import ConfirmDuplicated from './shared-components/ConfirmDuplicated';

function Inbox() {
	const loading = useSelector(state => state.inbox.loading);
	const data = useSelector(state => state.inbox.data);
	const email = useSelector(state => state.auth.email);
	const name = useSelector(state => state.auth.name);
	const positive = useSelector(state => state.inbox.positive);
	const [sending, setSending] = useState(false);
	const [value, setValue] = useState('');
	const [confirmMissing, setConfirmMissing] = useState(false);
	const [confirmMissingItem, setConfirmMissingItem] = useState(null);
	const [confirmNoAccess, setConfirmNoAccess] = useState(false);
	const [confirmNoAccessItem, setConfirmNoAccessItem] = useState(null);
	const [duplicated, setDuplicated] = useState(null);
	const [more, setMore] = useState(50);
	const [barcode, setBarcode] = useState(null);
	const dispatch = useDispatch();

	const fetchDataset = () => {
		switch (positive) {
			case 'Inbox':
				return dispatch(fetchInbox());
			case 'Outbox':
				return dispatch(fetchOutbox());
			case 'Quality':
				return dispatch(fetchQuality());
			case 'USVI Inbox':
				return dispatch(fetchViInbox());
			case 'USVI Outbox':
				return dispatch(fetchViOutbox());
			default:
				return dispatch(fetchInbox());
		}
	};

	const getUsers = async () => {
		const token = await authProvider.getAccessToken();

		if (token) {
			const technicians = await getTechnicians(token);
			return _.map(technicians.value, t => {
				return {
					key: t.mail,
					value: t.displayName,
					text: t.displayName
				};
			});
		}

		return [];
	};

	const getLabel = status => {
		switch (status) {
			case 'removed':
				return <Badge content="Removed" color="red" />;
			case 'noaccess':
				return <Badge content="No Access" color="orange" />;
			case 'missing':
				return <Badge content="Missing" color="purple" />;
			case 'intact':
				return <Badge content="Intact" color="green" />;
			case 'notintact':
				return <Badge content="Not Intact" color="pink" />;
			default:
				return <Badge content={status} color="blue" />;
		}
	};

	const deleteMaintenance = id => {
		const url =
			positive === 'USVI Inbox' ? '/api/delete-bg' : '/api/delete-maintenance';

		fetch(url, {
			method: 'POST',
			body: JSON.stringify({ id }),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => {
				return response.json();
			})
			.then(json => {
				if (json.error) {
					dispatch(
						addToast({
							type: 'error',
							message: json.error
						})
					);
				} else {
					dispatch(
						addToast({
							type: 'success',
							message: json.success
						})
					);
					fetchDataset();
				}
			})
			.catch(error => {
				dispatch(
					addToast({
						type: 'warning',
						message: error.message
					})
				);
			});
	};

	const noAccess = id => {
		setSending(true);

		const url =
			positive === 'USVI Inbox' ? '/api/usvi-confirm' : '/api/confirm';

		fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				id,
				name,
				email,
				confirmed: 'No (no access)'
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => {
				return response.json();
			})
			.then(json => {
				if (json.error) {
					dispatch(
						addToast({
							type: 'error',
							message: json.error
						})
					);
				} else {
					dispatch(
						addToast({
							type: 'success',
							message: json.success
						})
					);
					fetchDataset();
				}
				setSending(false);
			})
			.catch(error => {
				dispatch(
					addToast({
						type: 'warning',
						message: error.message
					})
				);
				setSending(false);
			});
	};

	const missing = id => {
		setSending(true);

		const url =
			positive === 'USVI Inbox' ? '/api/usvi-confirm' : '/api/confirm';

		fetch(url, {
			method: 'POST',
			body: JSON.stringify({
				id,
				name,
				email,
				confirmed: 'No (missing)'
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(response => {
				console.log(response);
				return response.json();
			})
			.then(json => {
				if (json.error) {
					dispatch(
						addToast({
							type: 'error',
							message: json.error
						})
					);
				} else {
					dispatch(
						addToast({
							type: 'success',
							message: json.success
						})
					);
					fetchDataset();
				}
				setSending(false);
			})
			.catch(error => {
				dispatch(
					addToast({
						type: 'warning',
						message: error.message
					})
				);
				setSending(false);
			});
	};

	useMount(() => {
		fetchDataset();
		getUsers().then(data => {
			dispatch(getLabUsers(data));
		});
	});

	useEffect(() => {
		switch (positive) {
			case 'Inbox':
				return dispatch(fetchInbox());
			case 'Outbox':
				return dispatch(fetchOutbox());
			case 'Quality':
				return dispatch(fetchQuality());
			case 'USVI Inbox':
				return dispatch(fetchViInbox());
			case 'USVI Outbox':
				return dispatch(fetchViOutbox());
			default:
				return dispatch(fetchInbox());
		}
	}, [positive, dispatch]);

	return (
		<>
			<Dialog />
			<ConfirmNotFound barcode={barcode} />
			<ConfirmDuplicated
				barcode={barcode}
				trap={duplicated}
				onClick={() => {
					deleteMaintenance(duplicated.id);
					dispatch(confirmDuplicated(false));
				}}
			/>
			<BarcodeReader
				onScan={res => {
					setBarcode(res);
					dispatch(hideModal());
					dispatch(notFound(false));
					dispatch(confirmDuplicated(false));

					const trap = _.filter(data, ['barcode', res]);

					if (trap.length === 1) {
						if (trap[0].action === 'Edit') {
							dispatch(showModal(trap[0]));
						} else if (trap[0].action === 'Confirm') {
							if (trap[0].status === 'missing') {
								setConfirmMissing(true);
								setConfirmMissingItem(trap[0]);
							} else if (trap[0].status === 'noaccess') {
								setConfirmNoAccess(true);
								setConfirmNoAccessItem(trap[0]);
							}
						}
					} else if (trap && trap.length >= 2) {
						const duplicated = _.find(trap, d => {
							return d.action === 'Delete';
						});

						if (duplicated) {
							setDuplicated(duplicated);
							dispatch(confirmDuplicated(true));
						} else {
							dispatch(showModal(trap[0]));
						}
					} else {
						dispatch(notFound(true));
					}
				}}
			/>
			<Confirm
				header={() => {
					return (
						<div className="header">
							<span className="small strong">Confirm</span>
						</div>
					);
				}}
				content={() => {
					return (
						<div className="content">
							<span className="small">
								The {confirmMissingItem && confirmMissingItem.barcode} trap have
								a missing status. We need to confirm the maintenance.
							</span>
						</div>
					);
				}}
				size="mini"
				open={confirmMissing}
				onCancel={() => {
					setConfirmMissing(false);
				}}
				cancelButton={() => {
					return (
						<Button
							size="mini"
							content="Cancel"
							onClick={() => {
								setConfirmMissing(false);
							}}
						/>
					);
				}}
				confirmButton={() => {
					return (
						<Button
							loading={sending}
							primary
							size="mini"
							content="Confirm"
							onClick={() => {
								missing(confirmMissingItem.id);
								setConfirmMissing(false);
							}}
						/>
					);
				}}
			/>
			<Confirm
				header={() => {
					return (
						<div className="header">
							<span className="small strong">Confirm</span>
						</div>
					);
				}}
				content={() => {
					return (
						<div className="content">
							<span className="small">
								The {confirmNoAccessItem && confirmNoAccessItem.barcode} trap
								have a no access status. We need to confirm the maintenance.
							</span>
						</div>
					);
				}}
				size="mini"
				open={confirmNoAccess}
				onCancel={() => {
					setConfirmNoAccess(false);
				}}
				cancelButton={() => {
					return (
						<Button
							size="mini"
							content="Cancel"
							onClick={() => {
								setConfirmNoAccess(false);
							}}
						/>
					);
				}}
				confirmButton={() => {
					return (
						<Button
							primary
							size="mini"
							content="Confirm"
							onClick={() => {
								noAccess(confirmNoAccessItem.id);
								setConfirmNoAccess(false);
							}}
						/>
					);
				}}
			/>
			<Grid.Row columns={3} textAlign="center">
				<Grid.Column>
					<Button
						fluid
						size="mini"
						content="Inbox"
						toggle
						positive={positive === 'Inbox'}
						loading={positive === 'Inbox' && loading}
						onClick={() => {
							dispatch(setPositive('Inbox'));
						}}
					/>
				</Grid.Column>
				<Grid.Column>
					<Button
						fluid
						size="mini"
						content="Outbox"
						positive={positive === 'Outbox'}
						loading={positive === 'Outbox' && loading}
						onClick={() => {
							dispatch(setPositive('Outbox'));
						}}
					/>
				</Grid.Column>
				<Grid.Column>
					<Button
						fluid
						size="mini"
						content="Quality"
						positive={positive === 'Quality'}
						loading={positive === 'Quality' && loading}
						onClick={() => {
							dispatch(setPositive('Quality'));
						}}
					/>
				</Grid.Column>
				{/* <Grid.Column> */}
				{/*	<Button */}
				{/*		fluid */}
				{/*		size="mini" */}
				{/*		content="USVI Inbox" */}
				{/*		positive={positive === 'USVI Inbox'} */}
				{/*		loading={positive === 'USVI Inbox' && loading} */}
				{/*		onClick={() => { */}
				{/*			dispatch(setPositive('USVI Inbox')); */}
				{/*		}} */}
				{/*	/> */}
				{/* </Grid.Column> */}
				{/* <Grid.Column> */}
				{/*	<Button */}
				{/*		fluid */}
				{/*		size="mini" */}
				{/*		content="USVI Outbox" */}
				{/*		positive={positive === 'USVI Outbox'} */}
				{/*		loading={positive === 'USVI Outbox' && loading} */}
				{/*		onClick={() => { */}
				{/*			dispatch(setPositive('USVI Outbox')); */}
				{/*		}} */}
				{/*	/> */}
				{/* </Grid.Column> */}
			</Grid.Row>
			<Grid.Row columns={1} stretched>
				<Grid.Column>
					<Step.Group size="mini">
						<Step>
							<Icon name="qrcode" />
							<Step.Content>
								<Step.Title>Scan</Step.Title>
								<Step.Description>Scan the trap barcode</Step.Description>
							</Step.Content>
						</Step>
						<Step>
							<Icon name="edit outline" />
							<Step.Content>
								<Step.Title>Edit</Step.Title>
								<Step.Description>Edit the maintenance form</Step.Description>
							</Step.Content>
						</Step>
						<Step completed>
							<Icon name="send" />
							<Step.Content>
								<Step.Title>Submit</Step.Title>
								<Step.Description>Submit the maintenance form</Step.Description>
							</Step.Content>
						</Step>
					</Step.Group>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<div className="pre">
						<div className="pre-header x-small p-10 strong">
							{data.length.toLocaleString()} Entries @{' '}
							{new Date().toDateString()}
						</div>
						<Segment
							basic
							size="small"
							textAlign="right"
							content={
								<Input
									fluid
									onChange={e => setValue(e.target.value)}
									placeholder="Filter list or scan barcode"
									value={value}
									icon={
										value !== '' ? (
											<Icon
												link
												name="close"
												onClick={() => {
													setValue('');
												}}
											/>
										) : (
											'filter'
										)
									}
								/>
							}
						/>
						{data.length > 0 ? (
							_.slice(
								_.map(
									matchSorter(data, value, {
										keys: ['barcode']
									}),
									item => {
										return (
											<Segment key={item.key} vertical size="mini">
												<Grid>
													<Grid.Row
														columns={3}
														verticalAlign="middle"
														style={{ padding: 10 }}
													>
														<Grid.Column
															mobile={3}
															tablet={3}
															computer={3}
															stretched
														>
															{getLabel(item.status)}
														</Grid.Column>
														<Grid.Column mobile={9} tablet={10} computer={10}>
															<div
																style={{
																	color: 'rgba(0,0,0,.4)'
																}}
															>
																{moment(item.date).format('ll')}
															</div>
															<div>
																<strong>{item.barcode} </strong>{' '}
																<span
																	style={{
																		color: 'rgba(0,0,0,.4)'
																	}}
																>
																	({item.type})
																</span>
															</div>
															<div>
																Field Worker:{' '}
																<a href={`mailto:${item.creator}`}>
																	{item.creator}
																</a>{' '}
															</div>
															<div>
																Comments:{' '}
																{item.comments || 'No comments available'}
															</div>
															<div>
																Other conditions:{' '}
																{item.other_condition || 'No conditions'}
															</div>
															{item.action === 'Delete' && (
																<div
																	style={{
																		marginTop: 5
																	}}
																>
																	<Badge color="orange">Duplicated</Badge>
																</div>
															)}
														</Grid.Column>
														<Grid.Column mobile={4} tablet={3} computer={3}>
															<Button
																primary={item.action === 'Confirm'}
																negative={item.action === 'Delete'}
																fluid
																floated="right"
																size="mini"
																disabled={item.action === 'Disabled'}
																onClick={() => {
																	const record = _.filter(data, [
																		'id',
																		item.id
																	]);

																	if (item.action === 'Delete') {
																		deleteMaintenance(item.id);
																	} else if (
																		item.action === 'Confirm' &&
																		item.status === 'noaccess'
																	) {
																		noAccess(item.id);
																	} else if (
																		item.action === 'Confirm' &&
																		item.status === 'missing'
																	) {
																		missing(item.id);
																	} else {
																		dispatch(showModal(record[0]));
																	}
																}}
															>
																{item.action}
															</Button>
														</Grid.Column>
													</Grid.Row>
												</Grid>
											</Segment>
										);
									}
								),
								0,
								more
							)
						) : (
							<div>
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="placeholder full-width" />
								<div className="divider" />
								<div className="placeholder" />
								<div className="placeholder full-width" />
							</div>
						)}
					</div>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1} textAlign="center">
				<Grid.Column>
					<p
						className="small strong mb-10"
						style={{ color: 'rgba(0, 0, 0, 0.4)' }}
					>
						Showing {data.length < more ? data.length.toLocaleString() : more}{' '}
						of {data.length.toLocaleString()}
					</p>
					<Button
						primary
						size="mini"
						icon="horizontal ellipsis"
						content="Show Less"
						disabled={more <= 50}
						labelPosition="left"
						onClick={() => {
							setMore(more - 50);
						}}
					/>
					<Button
						size="mini"
						icon="horizontal ellipsis"
						content="Show More"
						color="black"
						disabled={data.length <= more}
						labelPosition="right"
						onClick={() => {
							setMore(more + 50);
						}}
					/>
				</Grid.Column>
			</Grid.Row>
		</>
	);
}

export default Inbox;
