import React, { Component } from 'react';
import { connect } from 'formik';
import { Icon } from 'semantic-ui-react';

class Counter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value
		};

		this.timer = null;
	}

	increment = () => {
		const {
			formik: { setFieldValue }
		} = this.props;

		if (this.state.value >= this.props.max) {
			return;
		}

		if (this.props.value === '') {
			setFieldValue(this.props.name, 0);
		} else {
			setFieldValue(this.props.name, parseInt(this.props.value, 10) + 1);
		}

		this.timer = setTimeout(this.increment, 200);
	};

	stopTimer = () => {
		clearTimeout(this.timer);
	};

	decrement = () => {
		const {
			formik: { setFieldValue }
		} = this.props;

		if (parseInt(this.props.value, 10) <= 0) {
			return;
		}

		setFieldValue(this.props.name, parseInt(this.props.value, 10) - 1);

		this.timer = setTimeout(this.decrement, 200);
	};

	getScientific = text => {
		let gender;
		let name;
		if (text.search(/\bfemale\b/) >= 0) {
			gender = 'female';
			name = text.replace(/\bfemale\b/, '');
		} else if (text.search(/\bmale\b/) >= 0) {
			gender = 'male';
			name = text.replace(/\bmale\b/, '');
		} else if (text === 'Other mosquito') {
			gender = 'Other mosquito';
			name = '';
		} else {
			name = text;
			gender = '';
		}

		return (
			<div className="counter-label quiet">
				{this.props.value >= 26 && (
					<span className="counter-badge">{this.props.value}</span>
				)}
				<div className="counter-label-text">
					<span>
						<em>{name}</em> {gender}
					</span>
				</div>
			</div>
		);
	};

	render() {
		return (
			<div>
				{this.props.label && this.getScientific(this.props.label)}
				<div className="counter-container">
					<button
						type="button"
						tabIndex="-1"
						onMouseDown={this.decrement}
						onMouseUp={this.stopTimer}
						className="counter-button minus"
					>
						<Icon name="minus" />
					</button>
					<div className="counter-input-container">
						<input
							id={this.props.id}
							name={this.props.name}
							type="number"
							className="counter-input"
							value={this.props.value}
							onChange={this.props.onChange}
							onBlur={this.props.onBlur}
						/>
					</div>
					<button
						type="button"
						tabIndex="-1"
						onMouseDown={this.increment}
						onMouseUp={this.stopTimer}
						className="counter-button plus"
					>
						<Icon name="plus" />
					</button>
				</div>
			</div>
		);
	}
}

export default connect(Counter);
