import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Header } from 'semantic-ui-react';
import { setModal, setRecord } from '../../../../store/actions/OrdersActions';

function OrdersModal(props) {
	const dispatch = useDispatch();
	const state = useSelector(state => state.orders);

	return (
		<Modal
			open={state.open}
			onClose={() => {
				dispatch(setModal());
				dispatch(setRecord(null));
			}}
			size="small"
		>
			<Modal.Header>
				<Header>
					{props.header}
					<Header.Subheader>{props.subheader}</Header.Subheader>
				</Header>
			</Modal.Header>
			<Modal.Content scrolling>
				<Modal.Description>{props.content}</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					size="mini"
					content="Cancel"
					onClick={() => {
						dispatch(setModal());
						dispatch(setRecord(null));
					}}
				/>
			</Modal.Actions>
		</Modal>
	);
}

export default OrdersModal;
