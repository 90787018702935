import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { setValue } from '../../../../store/actions/OrdersActions';

function OrdersSearch(props) {
	const dispatch = useDispatch();
	const value = useSelector(state => state.orders.value);

	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Form size="small">
					<Form.Input
						label={props.label}
						id="search"
						name="search"
						placeholder={props.placeholder}
						fluid
						value={value}
						onChange={e => dispatch(setValue(e.target.value))}
						icon={
							value === ''
								? 'search'
								: {
										name: 'close',
										link: true,
										onClick: () => {
											dispatch(setValue(''));
										}
								  }
						}
					/>
				</Form>
			</Grid.Column>
		</Grid.Row>
	);
}

export default OrdersSearch;
