import React from 'react';
import { Confirm, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { notFound } from '../../../../../store/actions/InboxActions';

function ConfirmNotFound(props) {
	const open = useSelector(state => state.inbox.notFound);
	const dispatch = useDispatch();

	return (
		<Confirm
			open={open}
			size="mini"
			header="Not Found"
			content={`The scanned trap ${props.barcode} was not found on the list. Please, double check, or use the input to search for the trap.`}
			cancelButton={null}
			confirmButton={() => {
				return (
					<Button
						negative
						size="mini"
						content="Close"
						onClick={() => dispatch(notFound(false))}
					/>
				);
			}}
		/>
	);
}

export default ConfirmNotFound;
