import {
	LOAD_POOLS_LOADING,
	LOAD_POOLS_SUCCESS,
	LOAD_POOLS_ERROR,
	SHOW_POOL_MODAL,
	HIDE_POOL_MODAL,
	GET_SELECTED_POOLS,
	SUBMIT_POOLS_LOADING,
	SUBMIT_POOLS_SUCCESS,
	SUBMIT_POOLS_ERROR,
	SUPER_POOL_VALUE,
	DELETE_POOLS_ERROR,
	DELETE_POOLS_LOADING,
	DELETE_POOLS_SUCCESS,
	SET_POOL_DATAINDEX,
	LOAD_POOLS_EMPTY,
	CHANGE_BOX_NUMBER,
	FETCH_BOXES_ERROR,
	FETCH_BOXES_LOADING,
	FETCH_BOXES_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	data: [],
	loading: false,
	error: '',
	date: new Date().toLocaleTimeString(),
	visible: false,
	pools: [],
	value: '',
	dataIndex: '',
	superpool: '',
	poolLoading: false,
	source: [],
	box: '',
	boxes: [],
	boxesLoading: false,
	boxesError: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CHANGE_BOX_NUMBER:
			return {
				...state,
				box: action.payload,
				loading: true
			};
		case LOAD_POOLS_LOADING:
			return {
				...state,
				loading: true,
				error: 'error',
				pools: []
			};
		case LOAD_POOLS_SUCCESS:
			return {
				...state,
				data: action.data,
				source: action.source,
				loading: false,
				date: new Date().toLocaleTimeString(),
				value: '',
				superpool: '',
				pools: []
			};
		case LOAD_POOLS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case LOAD_POOLS_EMPTY:
			return {
				...INITIAL_STATE
			};
		case SHOW_POOL_MODAL:
			return {
				...state,
				visible: true
			};
		case HIDE_POOL_MODAL:
			return {
				...state,
				visible: false
			};
		case GET_SELECTED_POOLS:
			return {
				...state,
				pools: action.payload
			};
		case SUBMIT_POOLS_LOADING:
			return {
				...state,
				poolLoading: true,
				error: ''
			};
		case SUBMIT_POOLS_SUCCESS:
			return {
				...state,
				error: '',
				poolLoading: false,
				visible: false,
				value: '',
				pools: []
			};
		case SUBMIT_POOLS_ERROR:
			return {
				...state,
				poolLoading: false,
				error: action.error
			};
		case SUPER_POOL_VALUE:
			return {
				...state,
				[action.payload.state]: action.payload.value
			};
		case DELETE_POOLS_LOADING:
			return {
				...state,
				poolLoading: true
			};
		case DELETE_POOLS_SUCCESS:
			return {
				...state,
				poolLoading: false
			};
		case DELETE_POOLS_ERROR:
			return {
				...state,
				poolLoading: false,
				error: action.error
			};
		case SET_POOL_DATAINDEX:
			return {
				...state,
				dataIndex: action.payload,
				superpool: action.superpool
			};
		case FETCH_BOXES_LOADING:
			return {
				...state,
				boxesLoading: true,
				boxesError: null
			};
		case FETCH_BOXES_SUCCESS:
			return {
				...state,
				boxes: action.payload
			};
		case FETCH_BOXES_ERROR:
			return {
				...state,
				boxesError: action.payload
			};
		default:
			return state;
	}
};
