import React, { useEffect } from 'react';
import { Image, Menu } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/icon.svg';
import { open } from '../../store/actions/MessengerActions';
import { showSidebar } from '../../store/actions/SidebarActions';
import Avatar from '../../assets/avatar.svg';

function Navbar() {
	const photo = useSelector(state => state.auth.photo);
	const name = useSelector(state => state.auth.name);
	const role = useSelector(state => state.auth.role);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(open);
		dispatch(showSidebar);
	});

	return (
		<nav>
			<Menu inverted borderless attached>
				<Menu.Item>
					<Image src={logo} alt="Logo" height={40} />
				</Menu.Item>
				<Menu.Item position="right" style={{ textAlign: 'right' }}>
					<span style={{ marginRight: 5 }}>
						<small>
							{name || 'Authenticating...'}
							<br />
							<span style={{ color: 'rgba(255,255,255,0.25)' }}>
								{role || 'Getting user role'}
							</span>
						</small>
					</span>
					<Image avatar src={photo || Avatar} alt="Avatar" />
				</Menu.Item>
				<Menu.Item
					color="black"
					icon="bell outline"
					onClick={() => dispatch(open())}
				/>
				<Menu.Item
					color="black"
					icon="ellipsis vertical"
					onClick={() => dispatch(showSidebar())}
				/>
			</Menu>
		</nav>
	);
}

export default Navbar;
