const graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
	return graph.Client.init({
		authProvider: done => {
			done(null, accessToken.accessToken);
		}
	});
}

export async function getUserDetails(accessToken) {
	const client = getAuthenticatedClient(accessToken);

	return client.api('/me').get();
}

export async function getUserGroups(accessToken) {
	const client = getAuthenticatedClient(accessToken);

	return client.api('/me/memberOf').get();
}

export async function getUserProfilePicture(accessToken) {
	const client = getAuthenticatedClient(accessToken);
	const photo = client.api('/me/photo/$value').get();

	if (photo) {
		return photo;
	}

	return console.log('No Photo');
}

export async function sendMail(accessToken, subject, message) {
	return getAuthenticatedClient(accessToken)
		.api('/me/sendMail')
		.post({
			message: {
				subject,
				body: {
					contentType: 'Text',
					content: message
				},
				toRecipients: [
					{
						emailAddress: {
							address: 'gis@prvectorcontrol.org'
						}
					}
				]
			}
		});
}

export async function sendOutboxMail(token, email, trap, date) {
	return getAuthenticatedClient(token)
		.api('/me/sendMail')
		.post({
			message: {
				subject: 'A maintenance has been edited from Outbox.',
				body: {
					contentType: 'Text',
					content: `Outbox edition: The ${trap} trap from ${date} has been edited by ${email}.`
				},
				toRecipients: [
					{
						emailAddress: {
							address: 'jomedina@prvectorcontrol.org'
						}
					}
				]
			}
		});
}

export async function getTechnicians(accessToken) {
	const client = getAuthenticatedClient(accessToken);
	return client
		.api(
			"/users?$filter=jobTitle eq 'Laboratory Technician' or jobTitle eq 'Laboratory Supervisor' or jobTitle eq 'Vector Management Technician' or jobTitle eq 'Flebotomist' or jobTitle eq 'Laboratory Research Specialist'"
		)
		.get();
}

export const getSuperAdmin = role => {
	return role === 'Super Administrator';
};

export const getAdmin = role => {
	switch (role) {
		case 'Super Administrator':
			return true;
		case 'Administrator':
			return true;
		default:
			return false;
	}
};

export const getEditor = role => {
	switch (role) {
		case 'Super Administrator':
			return true;
		case 'Administrator':
			return true;
		case 'Editor':
			return true;
		default:
			return false;
	}
};

export const getViewer = role => {
	switch (role) {
		case 'Super Administrator':
			return true;
		case 'Administrator':
			return true;
		case 'Editor':
			return true;
		case 'Viewer':
			return true;
		default:
			return false;
	}
};
