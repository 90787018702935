import React from 'react';
import { useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Icon } from 'semantic-ui-react';
import Footer from '../../../../shared-components/Footer';
import Navbar from '../../../../shared-components/Navbar';
import OrdersBanner from '../OrdersBanner';
import ClientsList from './ClientsList';
import OrdersBreadcrumb from '../OrdersBreadcrumb';
import OrdersHeader from '../OrdersHeader';
import OrdersSearch from '../OrdersSearch';
import { setValue, setModal } from '../../../../../store/actions/OrdersActions';
import OrdersSpinner from '../OrdersSpinner';
import OrdersModal from '../OrdersModal';
import ClientsForm from './ClientsForm';

const breadcrumbs = [
	{ key: 'home', content: 'Home', to: '/', as: Link },
	{
		key: 'orders',
		content: 'Orders',
		to: '/orders',
		as: Link
	},
	{ key: 'clients', content: 'Clients', active: true }
];

function Clients() {
	const dispatch = useDispatch();
	const state = useSelector(state => state.orders);

	useUnmount(() => dispatch(setValue('')));

	// constructor(props) {
	// 	super(props);
	//
	// 	this.state = {
	// 		term: '',
	// 		deleting: false,
	// 		open: false
	// 	};
	// }
	//
	// componentDidMount() {
	// 	this.props.getDirectory();
	// }
	//
	// deleteRecord = value => {
	// 	this.setState(
	// 		{
	// 			deleting: true
	// 		},
	// 		() => {
	// 			fetch(`/api/delete-organization`, {
	// 				method: 'post',
	// 				body: JSON.stringify({
	// 					code: value
	// 				}),
	// 				headers: {
	// 					Accept: 'application/json',
	// 					'Content-Type': 'application/json'
	// 				}
	// 			})
	// 				.then(response => response.json())
	// 				.then(json => {
	// 					if (json.success) {
	// 						this.props.addToast({
	// 							message: json.success,
	// 							type: 'success'
	// 						});
	// 						this.props.getDirectory();
	// 					} else {
	// 						this.props.addToast({
	// 							message: json.error,
	// 							type: 'error'
	// 						});
	// 					}
	//
	// 					this.setState({
	// 						deleting: false
	// 					});
	// 				})
	// 				.catch(error => {
	// 					this.props.addToast({
	// 						message: error,
	// 						type: 'warning'
	// 					});
	// 					this.setState({
	// 						deleting: false
	// 					});
	// 				});
	// 		}
	// 	);
	// };

	return (
		<>
			<Navbar />
			<OrdersModal
				header={
					state.record
						? `${state.record.organization}: ${state.record.name}`
						: 'New Client'
				}
				subheader="Fill all the required fields on the form to create a new project client and code card to start collecting surveillance pool data."
				content={<ClientsForm />}
			/>
			<OrdersBanner
				title="Client Specimen Data Collect: Clients"
				subtitle="Fill all the required fields on the form to create a new project client and code card to start collecting surveillance pool data."
			>
				<Button
					primary
					size="mini"
					icon
					labelPosition="left"
					onClick={() => dispatch(setModal())}
				>
					New Client
					<Icon name="right arrow" />
				</Button>
			</OrdersBanner>
			<Container text className="mt-30 mb-30">
				<Grid stackable>
					<OrdersBreadcrumb sections={breadcrumbs} />

					<OrdersHeader
						title="Clients"
						subtitle="Codes and organizations clients directory for client specimen data collect"
					/>

					{state.loading && <OrdersSpinner message="Getting Clients..." />}
					{!state.loading && (
						<OrdersSearch label="Clients Search" placeholder="Select Client" />
					)}
					{!state.loading && (
						<Grid.Row columns={1} textAlign="right">
							<Grid.Column>
								<Button
									primary
									size="mini"
									icon
									labelPosition="left"
									onClick={() => dispatch(setModal())}
								>
									New Client
									<Icon name="right arrow" />
								</Button>
							</Grid.Column>
						</Grid.Row>
					)}
					<ClientsList />
				</Grid>
			</Container>
			<Footer />
		</>
	);
}

export default Clients;
