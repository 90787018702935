import { CURRENT_MENU_ITEM, SET_MENU_COLOR } from '../actions/types';

const INITIAL_STATE = {
	current: 'home',
	color: '#222B30'
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CURRENT_MENU_ITEM:
			return {
				...state,
				current: action.payload
			};
		case SET_MENU_COLOR:
			return {
				...state,
				color: action.payload
			};
		default:
			return state;
	}
};
