import {
	CHANGE_PLATE_NUMBER,
	FINISH_PLATE,
	PLATE_CHANGE_VALUE,
	PLATE_LOAD_ERROR,
	PLATE_LOAD_SUCCESS,
	PLATE_MODAL_HIDE,
	PLATE_MODAL_SHOW
} from './types';

export const showModal = (index, value) => {
	return {
		type: PLATE_MODAL_SHOW,
		index,
		value
	};
};

export const hideModal = () => {
	return {
		type: PLATE_MODAL_HIDE
	};
};

export const changeValue = (value, data) => {
	return {
		type: PLATE_CHANGE_VALUE,
		value,
		data
	};
};

export const finishPlate = () => {
	return {
		type: FINISH_PLATE
	};
};

export const changePlateNumber = value => {
	return dispatch => {
		dispatch({
			type: CHANGE_PLATE_NUMBER,
			value
		});

		fetch('/api/load-plate', {
			method: 'post',
			body: JSON.stringify({
				value: value || 0
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: PLATE_LOAD_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: PLATE_LOAD_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const loadPlate = value => {
	return dispatch => {
		dispatch({
			type: CHANGE_PLATE_NUMBER,
			value
		});

		fetch('/api/load-plate', {
			method: 'post',
			body: JSON.stringify({
				value
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: PLATE_LOAD_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: PLATE_LOAD_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};
