import {
	ORDERS_SEARCH_VALUE,
	ORDERS_DATA_ERROR,
	ORDERS_DATA_LOADING,
	ORDERS_DATA_SUCCESS,
	ORDERS_MODAL_VISIBILITY,
	ORDERS_SET_RECORD,
	ORDERS_CONFIRMATION_VISIBILITY
} from '../actions/types';

const INITIAL_STATE = {
	value: '',
	data: null,
	loading: false,
	error: null,
	open: false,
	record: null,
	confirmation: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ORDERS_SEARCH_VALUE:
			return {
				...state,
				value: action.payload
			};
		case ORDERS_DATA_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case ORDERS_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload
			};
		case ORDERS_DATA_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case ORDERS_MODAL_VISIBILITY:
			return {
				...state,
				open: !state.open
			};
		case ORDERS_SET_RECORD:
			return {
				...state,
				record: action.payload
			};
		case ORDERS_CONFIRMATION_VISIBILITY:
			return {
				...state,
				confirmation: !state.confirmation
			};
		default:
			return state;
	}
};
