import React, { Component } from 'react';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { connect } from 'react-redux';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import { hideSidebar, showSidebar } from '../store/actions/SidebarActions';
import logo from '../assets/icon.svg';
import { authProvider } from '../utils/AuthProvider';
import ReduxStore from '../utils/ReduxStore';
import Authenticated from './Authenticated';

class App extends Component {
	render() {
		return (
			<AzureAD provider={authProvider} reduxStore={ReduxStore} forceLogin>
				{({ login, logout, accountInfo, authenticationState }) => {
					return (
						<div>
							{authenticationState === AuthenticationState.Unauthenticated && (
								<Grid
									style={{ height: '100vh', margin: 0 }}
									textAlign="center"
									verticalAlign="middle"
								>
									<Grid.Row color="black">
										<Grid.Column width={4}>
											<Image centered src={logo} />
											<Header inverted as="h4" textAlign="center">
												Laboratory Data Collector
											</Header>
											<p>
												<small>Version {process.env.REACT_APP_VERSION}</small>
											</p>
											<p>
												<Button
													content="Sign-In"
													inverted
													basic
													onClick={login}
													size="mini"
												/>
											</p>
											<p>
												Use your Puerto Rico Vector Control Unit email account
												to sign-in to the application
											</p>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							)}
							{accountInfo && <Authenticated logout={logout} />}
						</div>
					);
				}}
			</AzureAD>
		);
	}
}

const mapStateToProps = state => {
	const { auth, sidebar, pools } = state;
	return {
		authenticated: auth.authenticated,
		visible: sidebar.visible,
		rightVisible: sidebar.rightVisible,
		name: auth.name,
		email: auth.email,
		jobTitle: auth.jobTitle,
		surname: auth.surname,
		givenName: auth.givenName,
		photo: auth.photo,
		officeLocation: auth.officeLocation,
		data: pools.data,
		balloon: state.balloon.visible
	};
};

export default connect(
	mapStateToProps,
	{
		hideSidebar,
		showSidebar
	}
)(App);
