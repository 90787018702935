import React from 'react';
import { Loader, Segment, Grid } from 'semantic-ui-react';

function OrdersSpinner(props) {
	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Segment basic padded="very">
					<Loader active indeterminate size="tiny">
						{props.message}
					</Loader>
				</Segment>
			</Grid.Column>
		</Grid.Row>
	);
}

export default OrdersSpinner;
