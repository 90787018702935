import {
	SET_ARBOVIRUS_PLATE,
	SET_ARBOVIRUS_POOLS,
	SET_ARBOVIRUS_RESULTS,
	SET_ARBOVIRUS_SEQ_PLATE,
	SET_ORMA_PLATE,
	SET_ORMA_RESULTS,
	SET_WOLBACHIA_PLATE,
	SET_WOLBACHIA_RESULTS
} from './types';

export const setPools = () => {
	return {
		type: SET_ARBOVIRUS_POOLS
	};
};

export const setPlate = () => {
	return {
		type: SET_ARBOVIRUS_PLATE
	};
};

export const setSEQPlate = () => {
	return {
		type: SET_ARBOVIRUS_SEQ_PLATE
	};
};

export const setWolbachiaPlate = () => {
	return {
		type: SET_WOLBACHIA_PLATE
	};
};

export const setResults = () => {
	return {
		type: SET_ARBOVIRUS_RESULTS
	};
};

export const setWolbachiaResults = () => {
	return {
		type: SET_WOLBACHIA_RESULTS
	};
};

export const setORMAPlate = () => {
	return {
		type: SET_ORMA_PLATE
	};
};

export const setORMAResults = () => {
	return {
		type: SET_ORMA_RESULTS
	};
};
