import {
	FETCH_MESSAGES_ERROR,
	FETCH_MESSAGES_LOADING,
	FETCH_MESSAGES_SUCCESS,
	MESSAGES_SIDEBAR_CLOSE,
	MESSAGES_SIDEBAR_OPEN,
	HIDE_MESSAGE
} from '../actions/types';

const INITIAL_STATE = {
	loading: false,
	messages: null,
	error: null,
	open: false,
	message: null
};

const getMessage = messages => {
	if (messages && messages.length > 0) {
		return messages[0];
	}
	return null;
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_MESSAGES_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case FETCH_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				messages: action.payload,
				message: getMessage(action.payload)
			};
		case FETCH_MESSAGES_ERROR:
			return {
				...state,
				error: action.payload
			};
		case MESSAGES_SIDEBAR_CLOSE:
			return {
				...state,
				open: false
			};
		case MESSAGES_SIDEBAR_OPEN:
			return {
				...state,
				open: true
			};
		case HIDE_MESSAGE:
			return {
				...state,
				message: null
			};
		default:
			return state;
	}
};
