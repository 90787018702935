import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	Button,
	Container,
	Grid,
	Header,
	Image,
	Segment
} from 'semantic-ui-react';
import { useTitle } from 'react-use';
import { showRightSidebar } from '../../../store/actions/SidebarActions';
import TimeLapse from '../../../assets/timelapse.mp4';
import LabTech from '../../../assets/labTech.svg';
import Science from '../../../assets/science.svg';
import Data from '../../../assets/data.svg';
import Footer from '../../shared-components/Footer';
import Navbar from '../../shared-components/Navbar';

function Home() {
	const dispatch = useDispatch();
	const name = useSelector(state => state.auth.name);

	useTitle('Laboratory | Home');

	return (
		<>
			<Navbar />
			<Grid textAlign="center" verticalAlign="middle" color="black">
				<Grid.Row>
					<Grid.Column>
						<div
							style={{
								height: 500,
								overflow: 'hidden',
								position: 'relative'
							}}
						>
							<video
								preload="auto"
								autoPlay
								loop
								muted
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover'
								}}
							>
								<source src={TimeLapse} type="video/mp4" />
							</video>
							<div
								style={{
									position: 'absolute',
									zIndex: 2,
									top: 0,
									width: '100%',
									height: 500,
									backgroundColor: 'rgba(0,0,0,0.5)'
								}}
							>
								<Grid
									container
									inverted
									textAlign="center"
									verticalAlign="middle"
									style={{ height: '100%' }}
								>
									<Grid.Row>
										<Grid.Column>
											<Container text>
												<Header inverted size="huge">
													<Header.Content>
														MONITORING & SURVEILLANCE
														<Header.Subheader>
															Scientists have recorded around 40 different
															species of mosquitoes in Puerto Rico.{' '}
															<em>Culex</em> and <em>AedesAegypti</em> species
															are the most common mosquitoes found on the
															Island.
														</Header.Subheader>
													</Header.Content>
												</Header>
												<p
													style={{
														color: '#FFFFFF'
													}}
												>
													{name && <small>Sign in as {name}</small>}
												</p>
											</Container>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Segment attached vertical padded="very">
				<Grid
					padded="vertically"
					container
					stackable
					textAlign="center"
					relaxed
				>
					<Grid.Row>
						<Grid.Column>
							<Header size="huge">
								Laboratory Data
								<Header.Subheader>
									Mosquito Identification & Arbovirus RT-PCR Assay
								</Header.Subheader>
							</Header>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={3}>
						<Grid.Column>
							<div style={{ height: 80 }}>
								<Image
									src={LabTech}
									size="tiny"
									centered
									verticalAlign="middle"
								/>
							</div>
							<p className="strong small">Laboratory Mosquito Identification</p>
							<p className="small quiet">
								Our laboratory technicians are trained to identify mosquitoes
								based on their physical characteristics. Technicians identify
								and count the number of mosquitoes present in the surveillance
								traps.
							</p>
							<Button
								content="Collect Now"
								size="mini"
								as={NavLink}
								to="/maintenance"
							/>
						</Grid.Column>
						<Grid.Column>
							<div style={{ height: 80 }}>
								<Image
									src={Science}
									size="tiny"
									centered
									verticalAlign="middle"
								/>
							</div>
							<p className="strong small">Mosquito Arbovirus RT-PCR Assay</p>
							<p className="small quiet">
								Multi-target molecular assay intended for the qualitative
								detection and differentiation of RNA from the Dengue (DENV),
								Chikungunya (CHIKV) and Zika (ZIKV) viruses in samples.
							</p>
							<Button
								content="Collect Now"
								size="mini"
								as={NavLink}
								to="/arbovirus"
							/>
						</Grid.Column>
						<Grid.Column>
							<div style={{ height: 80 }}>
								<Image
									src={Data}
									size="tiny"
									centered
									verticalAlign="middle"
									height={60}
								/>
							</div>
							<p className="strong small">Data Dashboard</p>
							<p className="small quiet">
								Visit our data dashboard and info-graphics to explore the
								Mosquito Arbovirus RT-PCR Assay & the Laboratory Mosquito
								Identification data collected and analyze by our technicians.
							</p>
							<Button
								content="Explore Now"
								size="mini"
								onClick={() => dispatch(showRightSidebar())}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<Footer />
		</>
	);
}

export default Home;
