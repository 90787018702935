import React from 'react';
import { Breadcrumb, Grid } from 'semantic-ui-react';

function Breadcrumbs(props) {
	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Breadcrumb sections={props.sections} />
			</Grid.Column>
		</Grid.Row>
	);
}

export default Breadcrumbs;
