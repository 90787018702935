import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import {
	Button,
	Confirm,
	Form,
	Grid,
	Icon,
	Input,
	Label,
	Modal,
	Placeholder,
	Segment
} from 'semantic-ui-react';
import { useMount } from 'react-use';
import {
	confirmHide,
	confirmShow,
	fetchOutbox,
	modalHide,
	modalShow
} from '../../../../store/actions/PoolsEditorActions';
import Editor from './Editor';
import { addToast } from '../../../../store/actions/ToastActions';
import Badge from '../../../shared-components/Badge';

function Outbox() {
	const [id, setID] = useState(null);
	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(false);
	const data = useSelector(state => state.poolEditor.data);
	const confirm = useSelector(state => state.poolEditor.confirm);
	const pool = useSelector(state => state.poolEditor.pool);
	const box = useSelector(state => state.poolEditor.box);
	const mosquitoes = useSelector(state => state.poolEditor.mosquitoes);
	const modal = useSelector(state => state.poolEditor.modal);
	const dispatch = useDispatch();

	useMount(() => {
		dispatch(fetchOutbox());
	});

	const deletePool = () => {
		fetch('/api/delete-pool', {
			method: 'post',
			body: JSON.stringify({
				id
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(json => {
				if (json.error) {
					dispatch(
						addToast({
							type: 'error',
							message: json.error
						})
					);
				} else {
					dispatch(
						addToast({
							type: 'success',
							message: json.success
						})
					);
				}
			})
			.then(() => {
				dispatch(fetchOutbox());
			})
			.then(() => {
				dispatch(confirmHide());
			})
			.catch(error => {
				dispatch(
					addToast({
						type: 'error',
						message: error.message
					})
				);
			});
	};

	const filterEntries = e => {
		setValue(e.target.value);
	};

	const onChange = (e, { value }) => {
		setLoading(true);
		const pool = _.find(data, (event, index, array) => {
			return value === array[index].pool;
		});

		if (pool) {
			dispatch(modalShow(pool.pool, pool.box, pool.mosquitoes, pool.id));
			e.target.value = '';
		}

		setTimeout(() => {
			setLoading(false);
		}, 300);
	};

	return (
		<>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Confirm
						open={confirm}
						size="mini"
						header="Confirm"
						content="Are you sure you want to delete this maintenance pool? This is irreversible!!"
						cancelButton="Never mind"
						confirmButton="Let's do it"
						onCancel={() => dispatch(confirmHide())}
						onConfirm={() => deletePool()}
					/>
					<Modal open={modal} size="mini">
						<Modal.Content>
							<Modal.Description>
								<p>
									<strong>Edit Pool</strong>
								</p>
								<p>
									<small>
										Edit the pool <strong>{pool}</strong> in the box {box} with{' '}
										{mosquitoes} mosquitoes.
									</small>
								</p>
								<Editor />
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => dispatch(modalHide())} size="mini">
								Cancel
							</Button>
						</Modal.Actions>
					</Modal>
					<Form size="small">
						<Form.Field
							size="small"
							icon="qrcode"
							name="pool"
							control={Input}
							loading={loading}
							placeholder="Scan pool QR code..."
							onChange={onChange}
						/>
					</Form>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Segment>
						<Label attached="top">{data.length} Records</Label>
						<Segment
							basic
							vertical
							size="mini"
							textAlign="right"
							content={
								<Input
									size="small"
									onChange={() => filterEntries()}
									placeholder="Filter list..."
									value={value}
									icon={
										value !== '' ? (
											<Icon link name="close" onClick={() => setValue('')} />
										) : (
											'filter'
										)
									}
								/>
							}
						/>
						{data.length > 0 ? (
							_.map(
								matchSorter(data, value, {
									keys: ['pool', 'structureid']
								}),
								item => {
									return (
										<Segment key={item.key} vertical size="mini">
											<Grid>
												<Grid.Row columns={3} verticalAlign="middle">
													<Grid.Column width={3}>
														<Badge
															content={item.pool}
															color="blue"
															style={{
																marginRight: 10
															}}
														/>
													</Grid.Column>
													<Grid.Column width={9}>
														<div className="flex">
															<div className="truncate">
																<strong>{item.structureid}</strong> with{' '}
																{item.mosquitoes} mosquitoes in box #{item.box}{' '}
																@{' '}
																{new Date(
																	item.maintanancedate
																).toLocaleDateString()}{' '}
																.
															</div>
															<div className="quiet">
																Data collected by:{' '}
																{item.technician || 'Unknown'}.
															</div>
														</div>
													</Grid.Column>
													<Grid.Column textAlign="right" width={4}>
														<Button
															size="mini"
															content="Delete"
															onClick={() => {
																dispatch(confirmShow());
																setID(item.id);
															}}
														/>
														<Button
															style={{
																width: 53
															}}
															primary
															size="mini"
															content="Edit"
															onClick={() => {
																dispatch(
																	modalShow(
																		item.pool,
																		item.box,
																		item.mosquitoes,
																		item.id
																	)
																);
															}}
														/>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</Segment>
									);
								}
							)
						) : (
							<div>
								{_.times(10, () => {
									return (
										<Segment vertical key={_.uniqueId('placeholder-')}>
											<Placeholder>
												<Placeholder.Paragraph>
													<Placeholder.Line />
													<Placeholder.Line />
													<Placeholder.Line />
												</Placeholder.Paragraph>
											</Placeholder>
										</Segment>
									);
								})}
							</div>
						)}
					</Segment>
				</Grid.Column>
			</Grid.Row>
		</>
	);
}

export default Outbox;
