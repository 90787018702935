import {
	POOLS_DATA_SUCCESS,
	POOLS_DATA_LOADING,
	POOLS_DATA_ERROR,
	POOLS_CONFIRM_HIDE,
	POOLS_CONFIRM_SHOW,
	POOLS_MODAL_HIDE,
	POOLS_MODAL_SHOW
} from '../actions/types';

const INITIAL_STATE = {
	data: [],
	loading: false,
	error: null,
	dataIndex: '',
	confirm: false,
	modal: false,
	pool: null,
	mosquitoes: null,
	box: null,
	id: null,
	sex: null,
	sending: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case POOLS_DATA_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case POOLS_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				data: action.payload
			};
		case POOLS_DATA_ERROR:
			return {
				...state,
				loading: false,
				error: null,
				data: []
			};
		case POOLS_CONFIRM_HIDE:
			return {
				...state,
				confirm: false
			};
		case POOLS_CONFIRM_SHOW:
			return {
				...state,
				confirm: true
			};
		case POOLS_MODAL_SHOW:
			return {
				...state,
				modal: true,
				pool: action.pool,
				mosquitoes: action.mosquitoes,
				box: action.box,
				id: action.id,
				sex: action.sex,
				technician: action.technician
			};
		case POOLS_MODAL_HIDE:
			return {
				...state,
				modal: false,
				pool: null,
				mosquitoes: null,
				box: null,
				sending: false,
				id: null,
				sex: null,
				technician: null
			};
		default:
			return state;
	}
};
