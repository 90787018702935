import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Header, Image, Modal } from 'semantic-ui-react';
import Science from '../../../assets/science.svg';
import {
	setORMAPlate,
	setORMAResults,
	setPlate,
	setPools,
	setResults,
	setSEQPlate,
	setWolbachiaPlate,
	setWolbachiaResults
} from '../../../store/actions/ArbovirusActions';
import { showPositive } from '../../../store/actions/PositiveActions';
import { showRightSidebar } from '../../../store/actions/SidebarActions';
import { getAdmin } from '../../auth-utils';
import Footer from '../../shared-components/Footer';
import Navbar from '../../shared-components/Navbar';
import Export from './shared-components/Export';
import OmraPlate from './shared-components/orma/Plate';
import Results from './shared-components/orma/Results';
import Plate from './shared-components/Plate';
import Pools from './shared-components/Pools';
import PositiveSamples from './shared-components/PositiveSamples';
import Report from './shared-components/Report';
import SEQPlate from './shared-components/SEQPlate';
import Wolbachia from './shared-components/Wolbachia';
import WolbResults from './shared-components/WolbResults';

function Mass() {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(true);
	const open = useSelector(state => state.positive.open);
	const role = useSelector(state => state.auth.role);
	const index = useSelector(state => state.arbovirus.index);
	const title = useSelector(state => state.arbovirus.title);
	const subtitle = useSelector(state => state.arbovirus.subtitle);

	useEffect(() => {
		if (role) {
			const isAdmin = getAdmin(role);
			if (isAdmin) {
				setDisabled(false);
			}
		}
	}, [role]);

	return (
		<>
			<Navbar />
			<Grid stackable verticalAlign="middle" padded="vertically">
				<Modal size="large" open={open}>
					<Modal.Content style={{ padding: 0 }}>
						<PositiveSamples />
					</Modal.Content>
				</Modal>
				<Grid.Row columns={1} color="blue" style={{ minHeight: 400 }}>
					<Grid.Column>
						<Grid container stackable style={{ paddingTop: 80, paddingBottom: 80 }}>
							<Grid.Row columns={2} verticalAlign="middle">
								<Grid.Column>
									<Image centered src={Science} height={200} />
								</Grid.Column>
								<Grid.Column>
									<Header as="h1" inverted>
										Mosquito Arbovirus Surveillance System (MASS)
									</Header>
									<p className="quiet">
										Multi-target molecular assay intended for the qualitative detection and differentiation of RNA from
										the Dengue (DENV), Chikungunya (CHIKV) and Zika (ZIKV) viruses in mosquito samples through Reverse
										Transcriptase Polymerase Chain Reaction (RT-PCR).
									</p>
									<Button
										disabled={disabled}
										primary
										size="mini"
										content="Data Dashboard"
										onClick={() => dispatch(showRightSidebar())}
									/>
									<Button
										disabled={disabled}
										inverted
										size="mini"
										content="Positive Results"
										onClick={() => dispatch(showPositive())}
									/>
									<Export />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						<Container text>
							<Grid padded="vertically">
								<Grid.Row columns={1}>
									<Grid.Column>
										<Header as="h2">{title}</Header>
										<p className="small quiet">{subtitle}</p>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={4}>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 0}
											content="Pools"
											size="mini"
											onClick={() => dispatch(setPools())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 1}
											content="Plates"
											size="mini"
											onClick={() => dispatch(setPlate())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 2}
											content="Results"
											size="mini"
											onClick={() => dispatch(setResults())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 3}
											content="DENV SEQ Plates"
											size="mini"
											onClick={() => dispatch(setSEQPlate())}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={4}>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 4}
											content="Wolbachia Plates"
											size="mini"
											onClick={() => dispatch(setWolbachiaPlate())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 5}
											content="Wolbachia Results"
											size="mini"
											onClick={() => dispatch(setWolbachiaResults())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 6}
											content="ORMA Plates"
											size="mini"
											onClick={() => dispatch(setORMAPlate())}
										/>
									</Grid.Column>
									<Grid.Column>
										<Button
											disabled={disabled}
											fluid
											positive={index === 7}
											content="ORMA Results"
											size="mini"
											onClick={() => dispatch(setORMAResults())}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="mb-30">
				{index === 0 && <Pools />}
				{index === 1 && <Plate />}
				{index === 2 && <Report />}
				{index === 3 && <SEQPlate />}
				{index === 4 && <Wolbachia />}
				{index === 5 && <WolbResults />}
				{index === 6 && <OmraPlate />}
				{index === 7 && <Results />}
			</div>
			<Footer />
		</>
	);
}

export default Mass;
