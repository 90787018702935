import React from 'react';
import { Formik } from 'formik';
import { Form, Grid, Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Counter from '../../../../../../shared-components/Counter';
import {
	fetchInbox,
	fetchOutbox,
	fetchQuality,
	fetchViInbox,
	fetchViOutbox,
	hideModal
} from '../../../../../../../store/actions/InboxActions';
import FormLabel from '../../../../../../shared-components/FormLabel';
import { addToast } from '../../../../../../../store/actions/ToastActions';

function BG() {
	const users = useSelector(state => state.inbox.users);
	const positive = useSelector(state => state.inbox.positive);
	const record = useSelector(state => state.inbox.record);
	const name = useSelector(state => state.auth.name);
	const email = useSelector(state => state.auth.email);

	const dispatch = useDispatch();

	const fetchDataset = () => {
		switch (positive) {
			case 'Inbox':
				return dispatch(fetchInbox());
			case 'Outbox':
				return dispatch(fetchOutbox());
			case 'Quality':
				return dispatch(fetchQuality());
			case 'USVI Inbox':
				return dispatch(fetchViInbox());
			case 'USVI Outbox':
				return dispatch(fetchViOutbox());
			default:
				return dispatch(fetchInbox());
		}
	};

	const castValue = val => {
		switch (val) {
			case null:
				return '';
			case 0:
				return '0';
			default:
				return val;
		}
	};

	return (
		<Formik
			initialValues={{
				id: record.id,
				aedesfemale: castValue(record.aedesfemale),
				aedesmale: castValue(record.aedesmale),
				aedesunknown: castValue(record.aedesunknown),
				aedesaegyptifemale: castValue(record.aedesaegyptifemale),
				aedesaegyptimale: castValue(record.aedesaegyptimale),
				aedesaegyptiunknown: castValue(record.aedesaegyptiunknown),
				aedesmediofemale: castValue(record.aedesmediofemale),
				aedesmediomale: castValue(record.aedesmediomale),
				aedesmediounknown: castValue(record.aedesmediounknown),
				aedestaeniorhynchusfemale: castValue(record.aedestaeniorhynchusfemale),
				aedestaeniorhynchusmale: castValue(record.aedestaeniorhynchusmale),
				aedestaeniorhynchusunknown: castValue(
					record.aedestaeniorhynchusunknown
				),
				anophelesgrabhamiifemale: castValue(record.anophelesgrabhamiifemale),
				anophelesgrabhamiimale: castValue(record.anophelesgrabhamiimale),
				anophelesgrabhamiiunknown: castValue(record.anophelesgrabhamiiunknown),
				deinoceritescancerfemale: castValue(record.deinoceritescancerfemale),
				deinoceritescancermale: castValue(record.deinoceritescancermale),
				deinoceritescancerunknown: castValue(record.deinoceritescancerunknown),
				mansoniadyarifemale: castValue(record.mansoniadyarifemale),
				mansoniadyarimale: castValue(record.mansoniadyarimale),
				mansoniadyariunknown: castValue(record.mansoniadyariunknown),
				psorophorajohnstoniifemale: castValue(
					record.psorophorajohnstoniifemale
				),
				psorophorajohnstoniimale: castValue(record.psorophorajohnstoniimale),
				psorophorajohnstoniiunknown: castValue(
					record.psorophorajohnstoniiunknown
				),
				uranotenialowiifemale: castValue(record.uranotenialowiifemale),
				uranotenialowiimale: castValue(record.uranotenialowiimale),
				uranotenialowiiunknown: castValue(record.uranotenialowiiunknown),
				unknown: castValue(record.unknown),
				labidcomments: record.labidcomments || '',
				laboratorytech: record.laboratorytech || name,
				confirmedbylab: record.confirmedbylab || ''
			}}
			validationSchema={Yup.object().shape({
				confirmedbylab: Yup.string().required('Please, confirm maintenance!!!'),
				laboratorytech: Yup.string().required('Please, choose a technician!!!')
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				const json = values;
				json.editdate = new Date();
				json.editor = email;
				fetch('/api/update-bg', {
					method: 'post',
					body: JSON.stringify(json),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(response => response.json())
					.then(data => {
						if (data.success) {
							setSubmitting(false);
							resetForm();
							dispatch(
								addToast({
									type: 'success',
									message: data.success
								})
							);
							fetchDataset();
							dispatch(hideModal());
						} else {
							setSubmitting(false);
							dispatch(
								addToast({
									type: 'error',
									message: data.error
								})
							);
						}
					})
					.catch(error => {
						dispatch(
							addToast({
								type: 'error',
								message: error.message
							})
						);
						setSubmitting(false);
					});
			}}
		>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				handleReset
			}) => (
				<Form
					noValidate
					onSubmit={handleSubmit}
					onReset={handleReset}
					size="tiny"
				>
					<Grid padded stackable doubling style={{ fontSize: 11 }}>
						<Grid.Row columns={2}>
							<Grid.Column width={11}>
								<Grid doubling stackable>
									<Grid.Row columns={3}>
										<Grid.Column>
											<Counter
												label="Aedes female"
												name="aedesfemale"
												value={values.aedesfemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes male"
												name="aedesmale"
												value={values.aedesmale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes unknown"
												name="aedesunknown"
												value={values.aedesunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes aegypti female"
												name="aedesaegyptifemale"
												value={values.aedesaegyptifemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes aegypti male"
												name="aedesaegyptimale"
												value={values.aedesaegyptimale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes aegypti unknown"
												name="aedesaegyptiunknown"
												value={values.aedesaegyptiunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes medio female"
												name="aedesmediofemale"
												value={values.aedesmediofemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes medio male"
												name="aedesmediomale"
												value={values.aedesmediomale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes medio unknown"
												name="aedesmediounknown"
												value={values.aedesmediounknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes taeniorhynchus female"
												name="aedestaeniorhynchusfemale"
												value={values.aedestaeniorhynchusfemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes taeniorhynchus male"
												name="aedestaeniorhynchusmale"
												value={values.aedestaeniorhynchusmale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Aedes taeniorhynchus unknown"
												name="aedestaeniorhynchusunknown"
												value={values.aedestaeniorhynchusunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Anopheles grabhamii female"
												name="anophelesgrabhamiifemale"
												value={values.anophelesgrabhamiifemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Anopheles grabhamii male"
												name="anophelesgrabhamiimale"
												value={values.anophelesgrabhamiimale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Anopheles grabhamii unknown"
												name="anophelesgrabhamiiunknown"
												value={values.anophelesgrabhamiiunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Deinocerites cancer female"
												name="deinoceritescancerfemale"
												value={values.deinoceritescancerfemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Deinocerites cancer male"
												name="deinoceritescancermale"
												value={values.deinoceritescancermale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Deinocerites cancer unknown"
												name="deinoceritescancerunknown"
												value={values.deinoceritescancerunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Mansonia dyari female"
												name="mansoniadyarifemale"
												value={values.mansoniadyarifemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Mansonia dyari male"
												name="mansoniadyarimale"
												value={values.mansoniadyarimale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Mansonia dyari unknown"
												name="mansoniadyariunknown"
												value={values.mansoniadyariunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Psorophora johnstonii female"
												name="psorophorajohnstoniifemale"
												value={values.psorophorajohnstoniifemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Psorophora johnstonii male"
												name="psorophorajohnstoniimale"
												value={values.psorophorajohnstoniimale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Psorophora johnstonii unknown"
												name="psorophorajohnstoniiunknown"
												value={values.psorophorajohnstoniiunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Uranotenia lowii female"
												name="uranotenialowiifemale"
												value={values.uranotenialowiifemale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Uranotenia lowii male"
												name="uranotenialowiimale"
												value={values.uranotenialowiimale}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Uranotenia lowii unknown"
												name="uranotenialowiiunknown"
												value={values.uranotenialowiiunknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
										<Grid.Column>
											<Counter
												label="Unknown species/Unknown sex"
												name="unknown"
												value={values.unknown}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
							<Grid.Column width={5}>
								<Form.TextArea
									name="labidcomments"
									label="Comments 💬"
									placeholder="Feels like commenting?"
									value={values.labidcomments}
									onChange={handleChange}
								/>
								<Form.Field required>
									<FormLabel
										required
										search
										text="Id. Technician"
										error={
											errors.laboratorytech &&
											touched.laboratorytech &&
											errors.laboratorytech
										}
									/>
									<Form.Dropdown
										fluid
										selection
										search
										value={values.laboratorytech}
										options={users}
										placeholder="Select name..."
										onChange={(e, { value }) => {
											setFieldValue('laboratorytech', value);
										}}
									/>
								</Form.Field>
								<Form.Field required>
									<FormLabel
										required
										text="Confirmed by lab"
										error={
											errors.confirmedbylab &&
											touched.confirmedbylab &&
											errors.confirmedbylab
										}
									/>
									<Form.Dropdown
										name="confirmedbylab"
										selection
										control={Dropdown}
										fluid
										search
										value={values.confirmedbylab}
										placeholder="Select one..."
										options={[
											{
												key: 'Yes',
												value: 'Yes',
												text: 'Yes (Received)'
											},
											{
												key: 'No',
												value: 'No',
												text: 'No (Not received)'
											},
											{
												key: 'No',
												value: 'No',
												text: 'No (out of date)'
											}
										]}
										onChange={(e, { value }) => {
											setFieldValue('confirmedbylab', value);
										}}
									/>
								</Form.Field>
								<Form.Button
									fluid
									positive
									type="submit"
									size="tiny"
									loading={isSubmitting}
									content="Submit Maintenance"
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			)}
		</Formik>
	);
}

export default BG;
