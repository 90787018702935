import { HIDE_SIDEBAR, SHOW_RIGHT_SIDEBAR, SHOW_SIDEBAR } from './types';

export const showSidebar = () => {
	return {
		type: SHOW_SIDEBAR
	};
};

export const hideSidebar = () => {
	return {
		type: HIDE_SIDEBAR
	};
};

export const showRightSidebar = () => {
	return {
		type: SHOW_RIGHT_SIDEBAR
	};
};
