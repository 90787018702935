import React from 'react';
import { useDispatch } from 'react-redux';
import { Segment, Grid, Button, Icon } from 'semantic-ui-react';
import Badge from '../../../../shared-components/Badge';
import {
	setModal,
	setRecord
} from '../../../../../store/actions/OrdersActions';

function ClientListItem(props) {
	const dispatch = useDispatch();
	const { item } = props;

	return (
		<Segment vertical size="mini" style={{ padding: 10 }}>
			<Grid>
				<Grid.Row columns={3} verticalAlign="middle">
					<Grid.Column mobile={3} tablet={2} computer={2} stretched>
						<Badge color="green" content={item.code} />
					</Grid.Column>
					<Grid.Column mobile={9} tablet={11} computer={11}>
						<div style={{ color: 'rgba(0,0,0,.4)' }}>
							{new Date(item.created_date).toLocaleDateString()}
						</div>
						<div>
							<strong>{item.organization}</strong>
						</div>
						<div>
							<strong>{item.name}</strong>
						</div>
						<div>
							{item.address}. {item.city} {item.state}{' '}
							{item.country.toUpperCase()}. {item.zipcode}
						</div>
						<div>
							<a href={`mailto:${item.email}`} style={{ marginRight: 10 }}>
								{item.email}
							</a>
							<a href={`tel:${item.phone}`}>{item.phone}</a>
						</div>
					</Grid.Column>
					<Grid.Column mobile={4} tablet={3} computer={3} textAlign="right">
						<Button
							icon
							size="mini"
							circular
							primary
							onClick={() => {
								dispatch(setModal());
								dispatch(setRecord(item));
							}}
						>
							<Icon name="pencil" />
						</Button>
						<Button
							icon
							size="mini"
							circular
							negative
							onClick={() => console.log('Delete')}
						>
							<Icon name="delete" />
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
}

export default ClientListItem;
