import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Container,
	Form,
	Grid,
	Header,
	Icon,
	Image,
	Menu,
	Segment
} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { sendMail } from '../auth-utils';
import Tech from '../../assets/mosquito-tech.svg';
import Mosquitoes from '../../assets/mosquitoe.svg';
import { authProvider } from '../../utils/AuthProvider';

function Footer() {
	const [text, setText] = useState({
		subject: '',
		message: ''
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const email = useSelector(state => state.auth.email);
	const name = useSelector(state => state.auth.name);

	const handleChange = evt => {
		const { value } = evt.target;
		setText({
			...text,
			[evt.target.name]: value
		});
	};

	const sendSupportMail = async () => {
		setLoading(true);

		try {
			const token = await authProvider.getAccessToken();

			await sendMail(token, text.subject, text.message);

			setLoading(false);
			setError('');
			setText({
				subject: '',
				message: ''
			});
		} catch (err) {
			setLoading(false);
			setError(
				"Oops! Something it's wrong with your account. Please sign out and sign in again to refresh your account"
			);
		}
	};

	return (
		<>
			<Segment secondary vertical attached padded="very" style={{ border: 0 }}>
				<Container text>
					<Grid stackable>
						<Grid.Row columns={2} verticalAlign="middle">
							<Grid.Column textAlign="center">
								<Image src={Tech} size="small" centered />
								<Header as="h3">
									<Header.Content>
										Contact Us
										<Header.Subheader>
											We are here to help! Get answers to technical questions
											about this tool.
										</Header.Subheader>
									</Header.Content>
								</Header>
							</Grid.Column>
							<Grid.Column>
								<Form size="mini">
									<p>
										From: <span className="strong">{email}</span>
									</p>
									<Form.Input
										name="subject"
										fluid
										label="Subject"
										placeholder="Email subject..."
										value={text.subject}
										onChange={handleChange}
									/>
									<Form.TextArea
										name="message"
										label="Message"
										placeholder="How we can help?"
										value={text.message}
										onChange={handleChange}
									/>
									<p className="small quiet">
										Please provide as much detail as possible. Issues: steps you
										have taken and examples.
									</p>
									<Form.Button
										primary
										size="mini"
										loading={loading}
										content="Send"
										onClick={sendSupportMail}
									/>
								</Form>
								{error && <p className="small orange">{error}</p>}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Segment>
			<Segment
				inverted
				vertical
				attached
				padded="very"
				style={{ backgroundColor: '#f9886c' }}
			>
				<Grid verticalAlign="middle" stackable>
					<Grid.Row>
						<Grid.Column width={6}>
							<Image src={Mosquitoes} height={250} />
						</Grid.Column>
						<Grid.Column width={10}>
							<Header size="huge" inverted>
								Our laboratory monitors and surveys <em>Aedes aegypti</em>{' '}
								mosquitoes
							</Header>
							<p>
								The data collected will inform and support the mosquito control
								efforts conducted by the PRVCU and in partnership with
								collaborating agencies
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<Segment inverted vertical attached padded="very">
				<Grid padded="vertically" container divided stackable>
					<Grid.Row>
						<Grid.Column mobile={16} computer={6}>
							<Header as="h5" inverted>
								Laboratory
							</Header>
							<p>
								<small>
									Our laboratory technicians are trained to identify mosquitoes
									based on their physical characteristics. Technicians identify
									and count the number of mosquitoes present in the surveillance
									traps.
								</small>
							</p>
							<p>
								<small>
									<a
										href="http://prvectorcontrol.org/surveillance/"
										target="_blank"
										rel="noopener noreferrer"
										style={{
											color: 'rgba(255,255,255,0.5)'
										}}
									>
										Learn More
									</a>
								</small>
							</p>
							<Header as="h5" inverted>
								Contact
							</Header>
							<p>
								<small>
									<a
										href="mailto:gis@prvectorcontrol.org"
										style={{
											color: 'rgba(255,255,255,0.5)'
										}}
									>
										gis@prvectorcontrol.org
									</a>
								</small>
							</p>
						</Grid.Column>
						<Grid.Column mobile={16} computer={4}>
							<Menu vertical inverted fluid text>
								<Menu.Item>
									<Menu.Header>Menu</Menu.Header>
									<Menu.Menu>
										<Menu.Item exact to="/" as={NavLink} name="Home" />
										<Menu.Item
											to="/Maintenance"
											as={NavLink}
											name="Mosquito Identification"
										/>
										<Menu.Item
											to="/arbovirus"
											as={NavLink}
											name="Sample Pools"
										/>
									</Menu.Menu>
								</Menu.Item>
								<Menu.Item>
									<Menu.Header>Account</Menu.Header>
									<Menu.Menu>
										<Menu.Item>
											Signed in as <strong>{name}</strong>
										</Menu.Item>
									</Menu.Menu>
								</Menu.Item>
							</Menu>
						</Grid.Column>
						<Grid.Column mobile={16} computer={6}>
							<Header as="h5" inverted>
								Mosquítalo
							</Header>
							<div>
								<p>
									<small>
										The Puerto Rico Vector Control Unit developed the{' '}
										<strong>Mosquítalo</strong> application to encourage you to
										take a direct role in the elimination of mosquito breeding
										sites. The <em>Aedes aegypti</em> mosquito is raised in our
										homes, communities and schools.
									</small>
								</p>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
			<Segment
				inverted
				vertical
				attached
				textAlign="center"
				style={{ borderTop: '1px solid rgba(255,255,255,0.1)' }}
			>
				<div>
					<small>
						Made with <Icon name="heart" color="red" /> in Puerto Rico
					</small>
				</div>
				<small>
					Copyright © {new Date().getFullYear()} Puerto Rico Vector Control Unit
				</small>
			</Segment>
		</>
	);
}

export default Footer;
