import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { fetchOutbox } from '../../../../store/actions/PoolsEditorActions';
import { fetchPools } from '../../../../store/actions/InboxActions';
import { addToast } from '../../../../store/actions/ToastActions';
import FormLabel from '../../../shared-components/FormLabel';

class Editor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			min: 1,
			max: 20
		};
	}

	componentDidMount() {
		const { type } = this.props.record;
		if (type === 'MAST') {
			this.setState({ min: 10, max: 50 });
		} else if (type === 'BG') {
			this.setState({ min: 5, max: 50 });
		} else {
			this.setState({ min: 1, max: 20 });
		}
	}

	render() {
		return (
			<Formik
				initialValues={{
					pool: this.props.pool,
					box: this.props.box,
					mosquitoes: this.props.mosquitoes,
					sex: this.props.sex,
					technician: this.props.technician
				}}
				onSubmit={async values => {
					this.setState({
						loading: true
					});

					fetch('/api/edit-pool', {
						method: 'post',
						body: JSON.stringify({
							id: this.props.id,
							pool: values.pool,
							box: values.box,
							mosquitoes: values.mosquitoes,
							sex: values.sex,
							technician: values.technician
						}),
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json'
						}
					})
						.then(response => response.json())
						.then(json => {
							if (json[0].error) {
								this.props.addToast({
									type: 'error',
									message: json[0].error
								});
								this.setState({
									loading: false
								});
							} else {
								this.props.addToast({
									type: 'success',
									message: json[0].response
								});
								this.props.fetchPools(this.props.record.id);
								this.setState({
									loading: false
								});
							}
						})
						.catch(error => {
							this.props.addToast({
								type: 'warning',
								message: error.message
							});
							this.setState({
								loading: false
							});
						});

					this.props.fetchOutbox();
				}}
				validationSchema={Yup.object().shape({
					pool: Yup.string()
						.max(12, 'The pool number must have 12 values')
						.min(12, 'The pool number must have 12 values')
						.matches(/^[0-9]*$/, {
							message: 'Looks like you have un letter in the number',
							excludeEmptyString: true
						})
						.required('This field is required'),
					box: Yup.number().required('This field is required'),
					mosquitoes: Yup.number()
						.min(
							this.state.min,
							`The minimum mosquito count is ${this.state.min}`
						)
						.max(
							this.state.max,
							`The maximum mosquito count is ${this.state.max}`
						)
						.integer('The value must be integer')
						.required('This field is required'),
					technician: Yup.string().required('This field is required')
				})}
			>
				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						setFieldValue,
						setFieldTouched,
						handleChange,
						handleBlur,
						handleSubmit
					} = props;
					return (
						<Form onSubmit={handleSubmit} loading={isSubmitting} size="mini">
							<Form.Field
								required
								label="Pool Number"
								id="pool"
								placeholder="Pool Number"
								type="text"
								control={Input}
								value={values.pool}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									errors.pool &&
									touched.pool && {
										content: errors.pool,
										pointing: 'above'
									}
								}
							/>
							<Form.Field
								required
								label="Box Number"
								id="box"
								placeholder="Box Number"
								type="text"
								control={Input}
								value={values.box}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									errors.box &&
									touched.box && {
										content: errors.box,
										pointing: 'above'
									}
								}
							/>
							<Form.Field
								required
								label="Mosquito Count"
								id="mosquitoes"
								placeholder="Mosquito Count"
								type="text"
								control={Input}
								value={values.mosquitoes}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									errors.mosquitoes &&
									touched.mosquitoes && {
										content: errors.mosquitoes,
										pointing: 'above'
									}
								}
							/>
							<Form.Field required>
								<FormLabel
									required
									text="Id. Technician"
									error={
										errors.laboratorytech &&
										touched.laboratorytech &&
										errors.laboratorytech
									}
								/>
								<Form.Dropdown
									fluid
									selection
									search
									value={values.technician}
									options={this.props.users}
									placeholder="Select name..."
									onChange={(e, { value }) => {
										setFieldValue('technician', value);
									}}
								/>
							</Form.Field>
							{this.props.record.type === 'BG' && (
								<Form.Field
									name="sex"
									label="Sex"
									value={values.sex}
									control={Dropdown}
									fluid
									selection
									search
									clearable
									onChange={(event, { value }) => {
										setFieldValue('sex', value);
									}}
									onBlur={() => setFieldTouched('sex')}
									options={[
										{
											key: 'Female',
											value: 'Female',
											text: 'Female'
										},
										{
											key: 'Male',
											value: 'Male',
											text: 'Male'
										}
									]}
								/>
							)}
							<Button
								type="submit"
								primary
								loading={this.state.loading}
								size="mini"
							>
								Submit
							</Button>
						</Form>
					);
				}}
			</Formik>
		);
	}
}

const mapStateToProps = state => {
	return {
		pool: state.poolEditor.pool,
		mosquitoes: state.poolEditor.mosquitoes,
		sex: state.poolEditor.sex,
		box: state.poolEditor.box,
		id: state.poolEditor.id,
		technician: state.poolEditor.technician,
		sending: state.pools.sending,
		record: state.inbox.record,
		users: state.inbox.users
	};
};

export default connect(
	mapStateToProps,
	{ fetchOutbox, addToast, fetchPools }
)(Editor);
