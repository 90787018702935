import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import { useFormik } from 'formik';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Grid, Header, Icon, Input, Modal, Progress, Table } from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import { getAdmin } from '../../../../auth-utils';
import Switch from '../../../../shared-components/Switch';
import WolbPDFDocument from '../WolbPDFDocument';

function Results() {
	const [plate, setPlate] = useState('');
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState([]);
	const [term, setTerm] = useState('');
	const role = useSelector(state => state.auth.role);

	const exportToCSV = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const dataset = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		FileSaver.saveAs(dataset, 'report.xlsx');
	};

	const getData = () => {
		axios
			.post('/api/orma/results', {
				plate
			})
			.then(r => {
				setData(r.data);
			})
			.catch(e => console.log(e))
			.finally(() => setLoading(false));
	};

	const formik = useFormik({
		initialValues: {
			orma_orov_ct_value: '',
			orma_orov_ctrl: '',
			orma_orov_threshold: '',
			orma_mayv_ct_value: '',
			orma_mayv_ctrl: '',
			orma_mayv_threshold: '',
			orma_negative_ctrl: '',
			orma_results: '',
			orma_virus: '',
			orma_status: ''
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			axios
				.post('/api/orma/add-results', {
					pool: checked,
					plate,
					orma_orov_ct_value: values.orma_orov_ct_value,
					orma_orov_ctrl: values.orma_orov_ctrl,
					orma_orov_threshold: values.orma_orov_threshold,
					orma_mayv_ct_value: values.orma_mayv_ct_value,
					orma_mayv_ctrl: values.orma_mayv_ctrl,
					orma_mayv_threshold: values.orma_mayv_threshold,
					orma_negative_ctrl: values.orma_negative_ctrl,
					orma_results: values.orma_results,
					orma_virus: values.orma_virus
				})
				.then(() => {
					_.forEach(checked, pool => {
						const i = _.findIndex(data, { pool });
						if (i !== -1) {
							data[i].orma_orov_ct_value = values.orma_orov_ct_value;
							data[i].orma_orov_ctrl = values.orma_orov_ctrl;
							data[i].orma_orov_threshold = values.orma_orov_threshold;
							data[i].orma_mayv_ct_value = values.orma_mayv_ct_value;
							data[i].orma_mayv_ctrl = values.orma_mayv_ctrl;
							data[i].orma_mayv_threshold = values.orma_mayv_threshold;
							data[i].orma_negative_ctrl = values.orma_negative_ctrl;
							data[i].orma_results = values.orma_results;
							data[i].orma_virus = values.orma_virus;
							data[i].orma_status = 'Success';
						}
					});
				})
				.catch(e => console.log(e))
				.finally(() => {
					setChecked([]);
					setSubmitting(false);
					setVisible(false);
					resetForm();
				});
		}
	});

	return (
		<>
			<Modal size="small" open={visible}>
				<Modal.Content>
					<p className="strong">Add Results</p>
					<p className="small quiet">You have selected {checked.length} to add results in bulk.</p>
					<Form size="small">
						<Form.Group widths="equal">
							<Form.Input
								id="orma_orov_ct_value"
								name="orma_orov_ct_value"
								type="number"
								label="OROV CTValue"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_orov_ct_value}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="orma_orov_ctrl"
								name="orma_orov_ctrl"
								type="number"
								label="OROV Ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_orov_ctrl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="orma_orov_threshold"
								name="orma_orov_threshold"
								type="number"
								label="OROV Threshold"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_orov_threshold}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="orma_mayv_ct_value"
								name="orma_mayv_ct_value"
								type="number"
								label="MAYV CTValue"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_mayv_ct_value}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="orma_mayv_ctrl"
								name="orma_mayv_ctrl"
								type="number"
								label="MAYV Ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_mayv_ctrl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="orma_mayv_threshold"
								name="orma_mayv_threshold"
								type="number"
								label="MAYV Threshold"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_mayv_threshold}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="orma_negative_ctrl"
								name="orma_negative_ctrl"
								type="number"
								label="Negative Ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.orma_negative_ctrl}
								onChange={formik.handleChange}
							/>
							<Form.Dropdown
								id="orma_results"
								name="orma_results"
								label="Result"
								selection
								fluid
								size="small"
								header="Select Result"
								placeholder="Positive..."
								value={formik.values.orma_results}
								onChange={(e, { value }) => formik.setFieldValue('orma_results', value)}
								options={[
									{
										key: 'Negative',
										value: 'Negative',
										text: 'Negative'
									},
									{
										key: 'Positive',
										value: 'Positive',
										text: 'Positive'
									}
								]}
							/>
							<Form.Dropdown
								id="orma_virus"
								name="orma_virus"
								label="Virus"
								selection
								fluid
								clearable
								upward
								size="small"
								header="Select Virus"
								placeholder="wsp..."
								value={formik.values.orma_virus}
								onChange={(e, { value }) => formik.setFieldValue('orma_virus', value)}
								options={[
									{
										key: 'OROV',
										value: 'OROV',
										text: 'OROV'
									},
									{
										key: 'MAYV',
										value: 'MAYV',
										text: 'MAYV'
									}
								]}
							/>
						</Form.Group>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button
						size="mini"
						content="Cancel"
						onClick={() => {
							setVisible(false);
							setChecked([]);
							formik.resetForm();
						}}
					/>
					<Button
						size="mini"
						primary
						content="Submit"
						loading={formik.isSubmitting}
						onClick={() => formik.submitForm()}
					/>
				</Modal.Actions>
			</Modal>

			<Modal size="mini" open={open}>
				<Modal.Content>
					<Modal.Description>
						<Grid textAlign="center">
							<Grid.Row>
								<Grid.Column>
									<p className="small strong">
										Generating report for plate {plate} on {new Date().toLocaleString()}
									</p>
									<p className="x-small quiet">Please wait a few seconds until the report it is generated.</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<PDFDownloadLink document={<WolbPDFDocument data={data} plate={plate} />} fileName="report.pdf">
										{({ loading }) =>
											loading ? (
												<Progress percent={60} color="yellow" size="tiny" />
											) : (
												<Button size="mini" positive content="Download" />
											)
										}
									</PDFDownloadLink>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button negative size="mini" onClick={() => setOpen(false)} content="Cancel" />
				</Modal.Actions>
			</Modal>
			<Container>
				<Grid stackable>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Input
								loading={loading}
								icon="search"
								iconPosition="left"
								size="small"
								placeholder="Plate number.."
								value={plate}
								onChange={e => setPlate(e.target.value)}
								style={{ marginRight: 5 }}
							/>
							<Button
								positive
								size="small"
								content="Search"
								onClick={() => {
									setLoading(true);
									setData([]);
									getData();
								}}
							/>
						</Grid.Column>
						<Grid.Column floated="right" textAlign="right">
							<Button
								style={{ minWidth: 100 }}
								size="mini"
								primary
								onClick={() => exportToCSV()}
								disabled={data.length === 0}
								content="Export to excel"
							/>
							{/* <Button */}
							{/*	style={{ minWidth: 100 }} */}
							{/*	size="mini" */}
							{/*	primary */}
							{/*	color="orange" */}
							{/*	onClick={() => setOpen(true)} */}
							{/*	disabled={data.length === 0} */}
							{/*	content="Export to *.pdf" */}
							{/* /> */}
							<Button
								style={{ minWidth: 100 }}
								disabled={checked.length === 0}
								size="mini"
								positive
								onClick={() => {
									setVisible(true);
									formik.setFieldValue('orma_orov_ct_value', data[0].orma_orov_ct_value || '');
									formik.setFieldValue('orma_orov_ctrl', data[0].orma_orov_ctrl || '');
									formik.setFieldValue('orma_orov_threshold', data[0].orma_orov_threshold || '');
									formik.setFieldValue('orma_mayv_ct_value', data[0].orma_mayv_ct_value || '');
									formik.setFieldValue('orma_mayv_ctrl', data[0].orma_mayv_ctrl || '');
									formik.setFieldValue('orma_mayv_threshold', data[0].orma_mayv_threshold || '');
									formik.setFieldValue('orma_negative_ctrl', data[0].orma_negative_ctrl || '');
									formik.setFieldValue('orma_results', data[0].orma_results || '');
									formik.setFieldValue('orma_virus', data[0].orma_virus || '');
								}}
								content="Add Results"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							{data.length ? (
								<>
									<p className="strong small mb-0">Control Values</p>
									<p className="quiet small">Add control values for the following plate results.</p>
									<Table stackable textAlign="center" size="small" celled>
										<Table.Header>
											<Table.Row textAlign="right">
												<Table.HeaderCell colSpan="14">
													<Input
														name="filter"
														placeholder="Filter by pool or super..."
														iconPosition="left"
														size="small"
														value={term}
														icon={
															term ? <Icon link name="close" onClick={() => setTerm('')} /> : <Icon name="search" />
														}
														onChange={e => setTerm(e.target.value)}
													/>
												</Table.HeaderCell>
											</Table.Row>
											<Table.Row>
												<Table.HeaderCell>
													<Switch
														checked={checked.length === data.length}
														onChange={e => {
															if (e.target.checked) {
																setChecked(
																	_.map(
																		matchSorter(data, term, {
																			keys: ['pool']
																		}),
																		item => {
																			return item.pool;
																		}
																	)
																);
															} else {
																setChecked([]);
															}
														}}
													/>
												</Table.HeaderCell>
												<Table.HeaderCell>Pool</Table.HeaderCell>
												<Table.HeaderCell>Well</Table.HeaderCell>
												<Table.HeaderCell>OROV CTValue</Table.HeaderCell>
												<Table.HeaderCell>OROV Ctrl</Table.HeaderCell>
												<Table.HeaderCell>OROV Threshold</Table.HeaderCell>
												<Table.HeaderCell>MAYV CTValue</Table.HeaderCell>
												<Table.HeaderCell>MAYV Ctrl</Table.HeaderCell>
												<Table.HeaderCell>MAYV Threshold</Table.HeaderCell>
												<Table.HeaderCell>Negative Ctrl</Table.HeaderCell>
												<Table.HeaderCell>Results</Table.HeaderCell>
												<Table.HeaderCell>Virus</Table.HeaderCell>
												<Table.HeaderCell>Status</Table.HeaderCell>
												<Table.HeaderCell />
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{matchSorter(data, term, {
												keys: ['pool']
											}).map(item => {
												return (
													<Table.Row key={item.pool}>
														<Table.Cell>
															<Switch
																checked={_.includes(checked, item.pool)}
																onChange={() => {
																	setChecked(_.xor(checked, [item.pool]));
																}}
															/>
														</Table.Cell>
														<Table.Cell>{item.pool}</Table.Cell>
														<Table.Cell>{item.orma_well}</Table.Cell>
														<Table.Cell>{item.orma_orov_ct_value}</Table.Cell>
														<Table.Cell>{item.orma_orov_ctrl}</Table.Cell>
														<Table.Cell>{item.orma_orov_threshold}</Table.Cell>
														<Table.Cell>{item.orma_mayv_ct_value}</Table.Cell>
														<Table.Cell>{item.orma_mayv_ctrl}</Table.Cell>
														<Table.Cell>{item.orma_mayv_threshold}</Table.Cell>
														<Table.Cell>{item.orma_negative_ctrl}</Table.Cell>
														<Table.Cell>{item.orma_results}</Table.Cell>
														<Table.Cell>{item.orma_virus}</Table.Cell>
														<Table.Cell>{item.orma_status === 'Success' ? 'Success' : 'Pending'}</Table.Cell>
														<Table.Cell>
															<Button
																size="mini"
																icon="pencil"
																compact
																circular
																positive
																onClick={() => {
																	setVisible(true);
																	setChecked([item.pool]);
																	formik.setFieldValue('orma_orov_ct_value', data[0].orma_orov_ct_value || '');
																	formik.setFieldValue('orma_orov_ctrl', data[0].orma_orov_ctrl || '');
																	formik.setFieldValue('orma_orov_threshold', data[0].orma_orov_threshold || '');
																	formik.setFieldValue('orma_mayv_ct_value', data[0].orma_mayv_ct_value || '');
																	formik.setFieldValue('orma_mayv_ctrl', data[0].orma_mayv_ctrl || '');
																	formik.setFieldValue('orma_mayv_threshold', data[0].orma_mayv_threshold || '');
																	formik.setFieldValue('orma_negative_ctrl', data[0].orma_negative_ctrl || '');
																	formik.setFieldValue('orma_results', data[0].orma_results || '');
																	formik.setFieldValue('orma_virus', data[0].orma_virus || '');
																}}
															/>
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
										<Table.Footer fullWidth>
											<Table.Row>
												<Table.HeaderCell>
													<Switch
														checked={checked.length === data.length}
														onChange={e => {
															if (e.target.checked) {
																setChecked(
																	_.map(
																		matchSorter(data, term, {
																			keys: ['pool', 'superpool']
																		}),
																		item => {
																			return item.pool;
																		}
																	)
																);
															} else {
																setChecked([]);
															}
														}}
													/>
												</Table.HeaderCell>
												<Table.HeaderCell colSpan="14" textAlign="left">
													<Header size="tiny">
														<Header.Content>
															Showing{' '}
															{
																matchSorter(data, term, {
																	keys: ['pool', 'superpool']
																}).length
															}{' '}
															of {data.length}
														</Header.Content>
													</Header>
												</Table.HeaderCell>
											</Table.Row>
										</Table.Footer>
									</Table>
								</>
							) : (
								<div className="pre">
									<div className="pre-header x-small p-10 strong">Results</div>
									<div>
										{loading ? (
											<div className="pre-loading x-small p-10 strong">Loading</div>
										) : (
											<div className="x-small p-10 strong">
												{getAdmin(role)
													? 'Enter plate number on the input field to load results'
													: 'You have no privileges to access this section'}
											</div>
										)}
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
									</div>
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	);
}

export default Results;
