import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	BrowserRouter as Router,
	NavLink,
	Route,
	Switch
} from 'react-router-dom';
import { Button, Icon, Image, Menu, Sidebar } from 'semantic-ui-react';
import Home from './pages/home/Home';
import Mass from './pages/mass/Mass';
import Maintenance from './pages/maintenance/Maintenance';
import PoolsEditor from './pages/pools/Pools';
import Clients from './pages/orders/shared-components/clients/Clients';
import { getUser } from '../store/actions/AuthActions';
import { hideSidebar, showSidebar } from '../store/actions/SidebarActions';
import { close, open } from '../store/actions/MessengerActions';
import {
	getUserDetails,
	getUserGroups,
	getUserProfilePicture
} from './auth-utils';
import logo2 from '../assets/logo.svg';
import Charts from './pages/mass/shared-components/Charts';
import { authProvider } from '../utils/AuthProvider';
import NotFound from './pages/404/NotFound';
import Toast from './shared-components/Toast';
import Message from './shared-components/Message';
import Messenger from './shared-components/Messenger';
import Balloon from './shared-components/Balloon';
import Dashboard from './pages/dashboard/Dashboard';
import Larvae from './pages/larvae/Larvae';
import Orders from './pages/orders/Orders';
import Export from './pages/export/Export';

class Authenticated extends Component {
	componentDidMount() {
		this.getProfile().then(() => {});
		this.getGroups().then(() => {});
	}

	getGroups = async () => {
		const token = await authProvider.getAccessToken();

		if (token) {
			try {
				const groups = await getUserGroups(token);
				groups.value.forEach(group => {
					switch (group.displayName) {
						case 'WebAppSuperAdministrator':
							return this.props.getUser({
								prop: 'role',
								value: 'Super Administrator'
							});
						case 'WebAppAdministrator':
							return this.props.getUser({
								prop: 'role',
								value: 'Administrator'
							});
						case 'WebAppEditor':
							return this.props.getUser({
								prop: 'role',
								value: 'Editor'
							});
						case 'WebAppViewer':
							return this.props.getUser({
								prop: 'role',
								value: 'Viewer'
							});
						default:
							return '';
					}
				});
			} catch (err) {
				console.log(err);
			}
		}
	};

	getProfile = async () => {
		const token = await authProvider.getAccessToken();

		if (token) {
			try {
				const user = await getUserDetails(token);
				const photo = await getUserProfilePicture(token);

				this.props.getUser({
					prop: 'email',
					value: user.mail
				});

				this.props.getUser({
					prop: 'name',
					value: user.displayName
				});

				this.props.getUser({
					prop: 'givenName',
					value: user.givenName
				});

				this.props.getUser({
					prop: 'surname',
					value: user.surname
				});

				this.props.getUser({
					prop: 'jobTitle',
					value: user.jobTitle
				});

				this.props.getUser({
					prop: 'officeLocation',
					value: user.officeLocation
				});

				if (photo) {
					const fileReaderInstance = new FileReader();
					fileReaderInstance.readAsDataURL(photo);
					fileReaderInstance.onload = () => {
						this.props.getUser({
							prop: 'photo',
							value: fileReaderInstance.result
						});
					};
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	render() {
		return (
			<Router>
				<Toast />
				<Message />
				<Balloon />
				<Sidebar
					as={Menu}
					vertical
					inverted
					animation="push"
					onHide={() => this.props.hideSidebar()}
					visible={this.props.visible}
					style={{ backgroundColor: '#2d2d2d' }}
				>
					<Menu.Item>
						<Image src={logo2} alt="Logo" height={42} />
					</Menu.Item>
					<Menu.Item>
						<div>Mosquito Surveillance System (MOSS)</div>
						<div>
							<small>Version {process.env.REACT_APP_VERSION}</small>
						</div>
					</Menu.Item>
					<Menu.Item>
						<Menu.Menu>
							<Menu.Item exact to="/" as={NavLink} name="Home" icon="home" />
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item>
						<Menu.Header color="teal">Surveillance</Menu.Header>
						<Menu.Menu>
							<Menu.Item
								to="/maintenance"
								as={NavLink}
								name="Mosquito Surveillance Identification"
								icon="angle right"
							/>
							<Menu.Item
								to="/larvae"
								as={NavLink}
								name="Larvae Identification"
								icon="angle right"
							/>
							<Menu.Item
								to="/pools_editor"
								as={NavLink}
								name="Pools Editor"
								icon="angle right"
							/>
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item>
						<Menu.Header>Client Specimen Data Collect</Menu.Header>
						<Menu.Menu>
							<Menu.Item
								to="/orders"
								exact
								as={NavLink}
								name="Orders"
								icon="angle right"
							/>
							<Menu.Item
								to="/orders/clients"
								as={NavLink}
								name="Clients"
								icon="angle right"
							/>
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item>
						<Menu.Header>Mass</Menu.Header>
						<Menu.Menu>
							<Menu.Item
								to="/arbovirus"
								as={NavLink}
								name="Mosquito Mass Surveillance System"
								icon="angle right"
							/>
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item>
						<Menu.Header>Data</Menu.Header>
						<Menu.Menu>
							<Menu.Item
								to="/export"
								as={NavLink}
								name="Export"
								icon="angle right"
							/>
						</Menu.Menu>
					</Menu.Item>
					<Menu.Item>
						<Menu.Header>Signed in as</Menu.Header>
						<Menu.Menu>
							<Menu.Item>
								<Icon name="user" />
								{this.props.name}
							</Menu.Item>
							<Menu.Item>
								<Icon name="mail" />
								{this.props.email}
							</Menu.Item>
							{this.props.jobTitle && (
								<Menu.Item>
									<Icon name="briefcase" />
									{this.props.jobTitle}
								</Menu.Item>
							)}
							<Menu.Item>
								<Icon name="privacy" />
								Role: {this.props.role}
							</Menu.Item>
							{this.props.officeLocation && (
								<Menu.Item>
									<Icon name="building" />
									{this.props.officeLocation}
								</Menu.Item>
							)}

							<Menu.Item>
								<Button onClick={this.props.logout} primary size="mini">
									Sign Out
								</Button>
							</Menu.Item>
						</Menu.Menu>
					</Menu.Item>
				</Sidebar>
				<Sidebar
					animation="overlay"
					direction="right"
					visible={this.props.messages}
					onHide={() => this.props.close()}
					style={{ backgroundColor: '#2d2d2d' }}
				>
					<Messenger />
				</Sidebar>
				<Sidebar
					width="very wide"
					animation="push"
					direction="right"
					visible={this.props.rightVisible}
					onHide={() => this.props.hideSidebar()}
					style={{ backgroundColor: '#2d2d2d' }}
				>
					<Charts />
				</Sidebar>
				<Sidebar.Pushable>
					<Sidebar.Pusher
						dimmed={
							this.props.visible ||
							this.props.rightVisible ||
							this.props.messages
						}
					>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/maintenance" component={Maintenance} />
							<Route path="/arbovirus" component={Mass} />
							<Route path="/pools_editor" component={PoolsEditor} />
							<Route path="/larvae" component={Larvae} />
							<Route path="/dashboard" component={Dashboard} />
							<Route exact path="/orders" component={Orders} />
							<Route path="/orders/clients" component={Clients} />
							<Route path="/export" name="Export" component={Export} />
							<Route path="/*" component={NotFound} />
						</Switch>
					</Sidebar.Pusher>
				</Sidebar.Pushable>
			</Router>
		);
	}
}

const mapStateToProps = state => {
	const { auth, sidebar, pools, messenger } = state;
	return {
		authenticated: auth.authenticated,
		visible: sidebar.visible,
		rightVisible: sidebar.rightVisible,
		name: auth.name,
		email: auth.email,
		jobTitle: auth.jobTitle,
		surname: auth.surname,
		givenName: auth.givenName,
		photo: auth.photo,
		role: auth.role,
		officeLocation: auth.officeLocation,
		data: pools.data,
		messages: messenger.open
	};
};

export default connect(
	mapStateToProps,
	{
		getUser,
		hideSidebar,
		showSidebar,
		open,
		close
	}
)(Authenticated);
