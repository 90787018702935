import {
	POSITIVE_RESULTS_CLEAR,
	POSITIVE_RESULTS_ERROR,
	POSITIVE_RESULTS_HIDE,
	POSITIVE_RESULTS_LOADING,
	POSITIVE_RESULTS_POOL,
	POSITIVE_RESULTS_SHOW,
	POSITIVE_RESULTS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	data: null,
	exportData: null,
	loading: false,
	error: null,
	open: false,
	longitude: -66.45,
	latitude: 18.2,
	zoom: 7.5,
	pool: '',
	properties: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case POSITIVE_RESULTS_SHOW:
			return {
				...state,
				open: true
			};
		case POSITIVE_RESULTS_HIDE:
			return {
				...state,
				open: false
			};
		case POSITIVE_RESULTS_LOADING:
			return {
				...state,
				loading: true,
				error: null
			};
		case POSITIVE_RESULTS_SUCCESS:
			return {
				...state,
				data: action.payload,
				exportData: action.exportData,
				loading: false,
				error: null
			};
		case POSITIVE_RESULTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		case POSITIVE_RESULTS_POOL:
			return {
				...state,
				pool: action.payload,
				properties: action.properties
			};
		case POSITIVE_RESULTS_CLEAR:
			return {
				...state,
				pool: '',
				properties: null
			};
		default:
			return state;
	}
};
