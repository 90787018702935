import { Formik } from 'formik';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Form, Grid, Input, Popup, Table } from 'semantic-ui-react';
import {
	deletePools,
	getSelected,
	handleValue,
	hidePoolModal,
	loadBoxes,
	loadPools,
	setDataIndex,
	showPoolModal,
	submitPools
} from '../../../../store/actions/PoolsActions';
import { getAdmin } from '../../../auth-utils';
import FormLabel from '../../../shared-components/FormLabel';
import Switch from '../../../shared-components/Switch';

const Pools = ({
	role,
	loading,
	data,
	pools,
	superpool,
	email,
	box,
	poolLoading,
	dataIndex,
	loadPools,
	getSelected,
	submitPools,
	handleValue,
	setDataIndex
}) => {
	const [term, setTerm] = useState('');

	const handleChange = (value, checked, index) => {
		const updatedData = [...data];
		updatedData[index].checked = !updatedData[index].checked;

		if (checked) {
			const array = _.concat(pools, value);
			getSelected(array);
		} else {
			const array = _.remove(pools, n => n !== value);
			getSelected(array);
		}
	};

	return (
		<Container text>
			<Grid stackable>
				<Grid.Row columns={1} centered>
					<Grid.Column width={8}>
						<Form size="small">
							<Form.Field
								disabled={!getAdmin(role)}
								fluid
								size="small"
								icon={loading ? 'spinner' : 'box'}
								loading={loading}
								placeholder="Box number..."
								control={Input}
								onChange={(e, { value }) => {
									setTimeout(() => {
										loadPools(value);
									}, 200);
								}}
							/>
						</Form>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						{data.length > 0 ? (
							<div className="pre">
								<div className="pre-header x-small p-10 strong">Box ({data.length} Pools)</div>
								<div
									className="p-10 grid-row"
									style={{
										display: 'flex',
										'flex-direction': 'row',
										'justify-content': 'space-between',
										borderBottom: '1px solid rgba(0,0,0,0.1)'
									}}
								>
									<div>
										<Input
											className="p-0"
											icon="filter"
											size="small"
											placeholder="Filter pools..."
											onChange={e => {
												setTerm(e.target.value);
												setDataIndex(e.target.value, '');
											}}
										/>
									</div>
									{pools.length > 0 && (
										<div>
											<Input
												className="p-0"
												style={{ padding: 0, marginRight: 5 }}
												icon="lab"
												size="small"
												placeholder="Add super pool..."
												value={superpool || ''}
												onChange={e => {
													handleValue('superpool', e.target.value);
												}}
											/>
											<Button
												positive
												size="small"
												content="Add"
												onClick={() => {
													submitPools(superpool, pools, email, box);
												}}
												loading={poolLoading}
											/>
										</div>
									)}
								</div>
								<Table
									striped
									singleLine
									unstackable
									basic="very"
									size="small"
									tableData={matchSorter(data, term, { keys: ['pool'] })}
									renderBodyRow={(item, index) => (
										<Table.Row key={item.id}>
											<Table.Cell width={1} textAlign="center">
												<Switch
													name={item.pool}
													value={item.key}
													checked={item.checked}
													onChange={e => {
														handleChange(e.target.value, e.target.checked, index);
													}}
												/>
											</Table.Cell>
											<Table.Cell width={7}>
												<div className="small quiet">{item.pool}</div>
												{item.well && (
													<div className="small quiet">
														Status:{' '}
														<span className={`${item.status === 'Success' ? 'green' : 'orange'}`}>{item.status}</span>
													</div>
												)}
												{item.wolb_well && (
													<div className="small quiet">
														Wolbachia status:{' '}
														<span className={`${item.wolb_status === 'Success' ? 'green' : 'orange'}`}>
															{item.wolb_status || 'Pending'}
														</span>
													</div>
												)}
												{item.mosquitoes > 0 && <div className="quiet small">Mosquitoes Count: {item.mosquitoes}</div>}
												<div className="quiet small">Pool Date: {new Date(item.date).toDateString()}</div>
											</Table.Cell>
											<Table.Cell width={4} textAlign="center">
												<span className="quiet small">{item.superpool}</span>
											</Table.Cell>
											<Table.Cell width={2}>
												<Popup
													position="top right"
													on="click"
													size="mini"
													content={() => (
														<div>
															<p className="strong">Edit Super Pool</p>
															<p className="quiet">Add super pool number to the selected pool.</p>
															<Formik
																initialValues={{
																	superpool: item.superpool || ''
																}}
																onSubmit={values => {
																	submitPools(values.superpool, dataIndex, email, box);
																}}
															>
																{props => {
																	const { values, handleChange, handleBlur, handleSubmit } = props;
																	return (
																		<Form size="mini" onSubmit={handleSubmit}>
																			<Form.Field>
																				<FormLabel text="Super Pool" />
																				<Input
																					name="superpool"
																					value={values.superpool}
																					onChange={handleChange}
																					onBlur={handleBlur}
																				/>
																			</Form.Field>
																			<Form.Button
																				type="submit"
																				primary
																				size="mini"
																				content="Save"
																				loading={poolLoading}
																			/>
																		</Form>
																	);
																}}
															</Formik>
														</div>
													)}
													trigger={
														<Button
															fluid
															primary
															size="mini"
															content="Edit"
															onClick={() => {
																setDataIndex(item.pool, item.superpool);
															}}
														/>
													}
												/>
											</Table.Cell>
										</Table.Row>
									)}
								/>
							</div>
						) : (
							<div className="pre">
								<div className="pre-header x-small p-10 strong">Box</div>
								{loading ? (
									<div className="pre-loading x-small p-10 strong">Loading</div>
								) : (
									<div className="pre-loading x-small p-10 strong">
										{getAdmin(role)
											? 'Enter box number on the input field to load box'
											: 'You have no privileges to access this section'}
									</div>
								)}
								<div>
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
								</div>
							</div>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	);
};

const mapStateToProps = state => ({
	role: state.auth.role,
	authenticated: state.auth.authenticated,
	name: state.auth.givenName,
	email: state.auth.email,
	value: state.pools.value,
	visible: state.pools.visible,
	data: state.pools.data,
	loading: state.pools.loading,
	error: state.pools.error,
	date: state.pools.date,
	pools: state.pools.pools,
	poolLoading: state.pools.poolLoading,
	superpool: state.pools.superpool,
	dataIndex: state.pools.dataIndex,
	source: state.pools.source,
	box: state.pools.box,
	boxes: state.pools.boxes
});

export default connect(
	mapStateToProps,
	{
		getSelected,
		showPoolModal,
		hidePoolModal,
		loadPools,
		submitPools,
		handleValue,
		deletePools,
		setDataIndex,
		loadBoxes
	}
)(Pools);
