import React, { useState, useEffect } from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';

const WeeklyTrapsChart = () => {
	const [dataset, setDataset] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [percent, setPercent] = useState('0%');

	const loadData = async () => {
		await fetch('/api/weekly-traps')
			.then(response => response.json())
			.then(res => {
				setDataset([res[0].value, res[1].value]);
				let val = (res[0].value / (res[0].value + res[1].value)) * 100;
				val = Math.round(val);
				setPercent(Number.isNaN(val) ? `0%` : `${val}%`);
				setLoading(false);
			})
			.catch(err => {
				setError(err.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		loadData().then(() => setLoading(false));
		Chart.pluginService.register({
			beforeDraw(chart) {
				if (chart.config.options.elements.center) {
					const { ctx } = chart.chart;
					const centerConfig = chart.config.options.elements.center;
					const fontStyle = centerConfig.fontStyle || 'Lato';
					const txt = centerConfig.title;
					const color = centerConfig.color || '#000';
					const maxFontSize = centerConfig.maxFontSize || 20;
					const sidePadding = centerConfig.sidePadding || 20;
					const sidePaddingCalculated =
						(sidePadding / 100) * (chart.innerRadius * 2);
					ctx.font = `20px ${fontStyle}`;

					const stringWidth = ctx.measureText(txt).width;
					const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

					const widthRatio = elementWidth / stringWidth;
					const newFontSize = Math.floor(30 * widthRatio);
					const elementHeight = chart.innerRadius * 2;

					let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
					let { minFontSize } = centerConfig;
					const lineHeight = centerConfig.lineHeight || 33;
					let wrapText = false;

					if (minFontSize === undefined) {
						minFontSize = 20;
					}

					if (minFontSize && fontSizeToUse < minFontSize) {
						fontSizeToUse = minFontSize;
						wrapText = true;
					}

					ctx.textAlign = 'center';
					ctx.textBaseline = 'middle';
					const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
					let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
					ctx.font = `${fontSizeToUse}px ${fontStyle}`;
					ctx.fillStyle = color;

					if (!wrapText) {
						ctx.fillText(txt, centerX, centerY);
						return;
					}

					const words = txt.split(' ');
					let line = '';
					const lines = [];

					for (let n = 0; n < words.length; n += 1) {
						const testLine = `${line + words[n]} `;
						const metrics = ctx.measureText(testLine);
						const testWidth = metrics.width;
						if (testWidth > elementWidth && n > 0) {
							lines.push(line);
							line = `${words[n]} `;
						} else {
							line = testLine;
						}
					}

					centerY -= (lines.length / 2) * lineHeight;

					for (let n = 0; n < words.length; n += 1) {
						ctx.fillText(lines[n], centerX, centerY);
						centerY += lineHeight;
					}
					ctx.fillText(line, centerX, centerY);
				}
			}
		});
	}, []);

	return (
		<div style={{ height: 300 }} className="text-center flex-middle">
			{loading && (
				<>
					<div className="message-loading mb-10" />
					<div className="small text-center">Loading</div>
				</>
			)}
			{error && !loading && (
				<div className="small strong text-center">error</div>
			)}
			{!loading && !error && (
				<Doughnut
					data={{
						labels: ['Processed', 'Not Processed'],
						datasets: [
							{
								data: dataset,
								backgroundColor: ['#3bb2d0', '#8a8acb'],
								hoverBackgroundColor: ['#55ccea', '#a4a4e5'],
								borderWidth: 0
							}
						]
					}}
					legend={{
						display: true,
						position: 'left',
						usePointStyle: true,
						labels: {
							generateLabels(chart) {
								const { data } = chart;
								if (data.labels.length && data.datasets.length) {
									return data.labels.map((label, i) => {
										const meta = chart.getDatasetMeta(0);
										const ds = data.datasets[0];
										const { getValueAtIndexOrDefault } = Chart.helpers;
										const arcOpts = chart.options.elements.arc;
										const fill = getValueAtIndexOrDefault(
											ds.backgroundColor,
											i,
											arcOpts.backgroundColor
										);
										return {
											text: `${ds.data[i]} ${label}`,
											fillStyle: fill,
											strokeStyle: 'rgba(255,255,255,0.5)',
											lineWidth: 1,
											hidden: Number.isNaN(ds.data[i]) || meta.data[i].hidden,
											index: i
										};
									});
								}
								return [];
							}
						}
					}}
					options={{
						animation: {
							animateScale: true
						},
						elements: {
							center: {
								title: percent,
								color: 'rgba(255,255,255,0.5)'
							}
						}
					}}
				/>
			)}
		</div>
	);
};

export default WeeklyTrapsChart;
