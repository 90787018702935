import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dropdown, Form, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import {
	fetchPools,
	hideModal
} from '../../../../../../store/actions/InboxActions';
import { addToast } from '../../../../../../store/actions/ToastActions';
import FormLabel from '../../../../../shared-components/FormLabel';
import OVI from './forms/OVI';
import AGO from './forms/AGO';
import BG from './forms/BG';

function Dialog() {
	const [open, setOpen] = useState(false);
	const [min, setMin] = useState(2);
	const [max, setMax] = useState(20);
	const visible = useSelector(state => state.inbox.visible);
	const record = useSelector(state => state.inbox.record);
	const email = useSelector(state => state.auth.email);
	const positive = useSelector(state => state.inbox.positive);
	const users = useSelector(state => state.inbox.users);
	const name = useSelector(state => state.auth.name);

	const dispatch = useDispatch();

	useEffect(() => {
		if (open && record) {
			if (record.type === 'MAST') {
				setMin(10);
				setMax(50);
			} else if (record.type === 'BG') {
				setMin(5);
				setMax(50);
			} else {
				setMin(1);
				setMax(20);
			}
		}
	}, [open, record]);

	return (
		<>
			<Modal open={open} size="mini">
				<Modal.Content>
					<Modal.Description>
						<p className="strong small">Add Pool</p>
						<Formik
							initialValues={{
								pool: '',
								box: '',
								mosquitoes: '',
								sex: '',
								technician: name || ''
							}}
							validationSchema={Yup.object().shape({
								pool: Yup.string()
									.max(12, 'The pool number must have 12 values')
									.min(12, 'The pool number must have 12 values')
									.matches(/^[0-9]*$/, {
										message: 'Looks like you have un letter in the number',
										excludeEmptyString: true
									})
									.required('The pool number is required'),
								box: Yup.number().required('This box number is required'),
								mosquitoes: Yup.number()
									.min(min, `The minimum mosquito count is ${min}`)
									.max(max, `The maximum mosquito count is ${max}`)
									.integer('The value must be integer')
									.required('This mosquito quantity is required'),
								technician: Yup.string().required('The pool number is required')
							})}
							onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
								fetch('/api/insert-pool', {
									crossDomain: true,
									method: 'POST',
									body: JSON.stringify({
										id: parseInt(record.id, 10),
										pool: values.pool,
										box: parseInt(values.box, 10),
										mosquitoes: parseInt(values.mosquitoes, 10),
										email,
										organization: parseInt(values.pool.slice(0, 5), 10),
										technician: values.technician
									}),
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json'
									}
								})
									.then(response => response.json())
									.then(data => {
										if (data[0].error) {
											dispatch(
												addToast({
													type: 'error',
													message: data[0].error
												})
											);
											setSubmitting(false);
											setStatus({ success: false });
										} else {
											dispatch(
												addToast({
													type: 'success',
													message: data[0].success
												})
											);
											dispatch(fetchPools(record.id));
											setSubmitting(false);
											setStatus({ success: true });
											resetForm();
										}
									})
									.catch(err => {
										dispatch(
											addToast({
												type: 'warning',
												message: err.message
											})
										);
										setSubmitting(false);
										setStatus({ success: false });
									});
							}}
						>
							{({
								values,
								errors,
								touched,
								setFieldValue,
								setFieldTouched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting
							}) => (
								<Form
									onSubmit={handleSubmit}
									size="mini"
									loading={isSubmitting}
								>
									<Form.Field required>
										<FormLabel
											text="Pool"
											required
											error={errors.pool && touched.pool && errors.pool}
										/>
										<Form.Input
											name="pool"
											placeholder="Pool"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.pool}
										/>
									</Form.Field>
									<Form.Field required>
										<FormLabel
											required
											text="Box"
											error={errors.box && touched.box && errors.box}
										/>
										<Form.Input
											name="box"
											placeholder="Box"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.box}
										/>
									</Form.Field>
									<Form.Field required>
										<FormLabel
											required
											text="Mosquitoes"
											error={
												errors.mosquitoes &&
												touched.mosquitoes &&
												errors.mosquitoes
											}
										/>
										<Form.Input
											name="mosquitoes"
											placeholder="Mosquitoes"
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.mosquitoes}
										/>
									</Form.Field>
									<Form.Field required>
										<FormLabel
											required
											text="Pool Technician"
											error={
												errors.technician &&
												touched.technician &&
												errors.technician
											}
										/>
										<Form.Dropdown
											fluid
											selection
											search
											clearable
											disabled={positive === 'Quality'}
											value={values.technician}
											options={users}
											placeholder="Select name..."
											onChange={(e, { value }) => {
												setFieldValue('technician', value);
											}}
										/>
									</Form.Field>
									{record.type === 'BG' && (
										<Form.Field
											name="sex"
											label="Sex"
											value={values.sex}
											control={Dropdown}
											fluid
											selection
											search
											clearable
											onChange={(event, { value }) => {
												if (value === '') {
													setFieldValue('sex', '');
												}
												setFieldValue('sex', value);
											}}
											onBlur={() => setFieldTouched('sex')}
											options={[
												{
													key: 'Female',
													value: 'Female',
													text: 'Female'
												},
												{
													key: 'Male',
													value: 'Male',
													text: 'Male'
												}
											]}
										/>
									)}
									<Form.Button
										primary
										size="mini"
										type="submit"
										disabled={isSubmitting}
										loading={isSubmitting}
									>
										Submit
									</Form.Button>
								</Form>
							)}
						</Formik>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => setOpen(false)} size="mini">
						Cancel
					</Button>
				</Modal.Actions>
			</Modal>
			<Modal open={visible} onClose={() => dispatch(hideModal())}>
				<Modal.Header
					style={{
						fontSize: 12,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<div>
						<h3>
							{record.barcode} ({record.type})
						</h3>
						<div
							style={{
								color: 'rgba(0,0,0,0.5',
								fontWeight: 'normal'
							}}
						>
							<div>{moment(record.date).format('ll')}</div>
							<div>
								Trap Status: {_.capitalize(record.status)}{' '}
								{record.condition && `(${record.condition})`}
							</div>
							<div>
								Field Worker:{' '}
								<a href={`mailto:${record.creator}`}>{record.creator}</a>
							</div>
						</div>
					</div>
					{record.type === 'AGO' && (
						<div>
							<Button
								primary
								content="Add Pools"
								size="mini"
								onClick={() => {
									setOpen(true);
								}}
							/>
						</div>
					)}
					{record.type === 'MAST' && (
						<div>
							<Button
								primary
								content="Add Pools"
								size="mini"
								onClick={() => {
									setOpen(true);
								}}
							/>
						</div>
					)}
					{record.type === 'BG' &&
						(positive === 'Inbox' || positive === 'Outbox') && (
							<div>
								<Button
									primary
									content="Add Pools"
									size="mini"
									onClick={() => {
										setOpen(true);
									}}
								/>
							</div>
						)}
				</Modal.Header>
				<Modal.Content scrolling style={{ padding: 0 }}>
					<Modal.Description>
						{record.type === 'AGO' && <AGO />}
						{record.type === 'MAST' && <AGO />}
						{record.type === 'BG' &&
							(positive === 'Inbox' || positive === 'Outbox') && <AGO />}
						{record.type === 'BG' &&
							!(positive === 'Inbox' || positive === 'Outbox') && <BG />}
						{record.type === 'OVI' && <OVI />}
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						onClick={() => dispatch(hideModal())}
						size="mini"
						content="Cancel"
					/>
				</Modal.Actions>
			</Modal>
		</>
	);
}

export default Dialog;
