import {
	LARVAE_INBOX_ERROR,
	LARVAE_INBOX_SUCCESS,
	LARVAE_INBOX_LOADING,
	LARVAE_INBOX_ACTIVE
} from './types';

export const fetchInbox = () => {
	return dispatch => {
		dispatch({
			type: LARVAE_INBOX_LOADING
		});

		fetch(`/api/larvae-inbox`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LARVAE_INBOX_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: LARVAE_INBOX_ERROR,
					payload: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchOutbox = () => {
	return dispatch => {
		dispatch({
			type: LARVAE_INBOX_LOADING
		});

		fetch(`/api/larvae-outbox`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LARVAE_INBOX_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: LARVAE_INBOX_ERROR,
					payload: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const setActive = active => {
	return {
		type: LARVAE_INBOX_ACTIVE,
		payload: active
	};
};
