import React from 'react';

const Switch = props => {
	return (
		<label htmlFor={props.name} className="switch-container">
			<input
				type="checkbox"
				id={props.name}
				name={props.name}
				value={props.value}
				checked={props.checked}
				disabled={props.disabled}
				onChange={props.onChange}
			/>
			<div className="switch" />
		</label>
	);
};

export default Switch;
