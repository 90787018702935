import {
	LINE_CHART_ERROR,
	LINE_CHART_LOADING,
	LINE_CHART_SUCCESS,
	SIDEBAR_FETCH_POOLS_ERROR,
	SIDEBAR_FETCH_POOLS_LOADING,
	SIDEBAR_FETCH_POOLS_SUCCESS,
	SIDEBAR_FETCH_SUPERPOOLS_ERROR,
	SIDEBAR_FETCH_SUPERPOOLS_LOADING,
	SIDEBAR_FETCH_SUPERPOOLS_SUCCESS,
	WEEK_CHART_ERROR,
	WEEK_CHART_LOADING,
	WEEK_CHART_SUCCESS
} from './types';

export const fetchLineData = () => {
	return dispatch => {
		dispatch({
			type: LINE_CHART_LOADING
		});

		fetch(`/api/pools-year`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LINE_CHART_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: LINE_CHART_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchweeklyData = () => {
	return dispatch => {
		dispatch({
			type: WEEK_CHART_LOADING
		});

		fetch(`/api/pools-weekly`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: WEEK_CHART_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: WEEK_CHART_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchTopFive = () => {
	return dispatch => {
		dispatch({
			type: SIDEBAR_FETCH_POOLS_LOADING
		});

		fetch(`/api/top`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: SIDEBAR_FETCH_POOLS_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: SIDEBAR_FETCH_POOLS_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchTopFiveSuperPools = () => {
	return dispatch => {
		dispatch({
			type: SIDEBAR_FETCH_SUPERPOOLS_LOADING
		});

		fetch(`/api/topSuperPool`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: SIDEBAR_FETCH_SUPERPOOLS_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: SIDEBAR_FETCH_SUPERPOOLS_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};
