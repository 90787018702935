import _ from 'lodash';
import {
	CHANGE_BOX_NUMBER,
	DELETE_POOLS_ERROR,
	DELETE_POOLS_LOADING,
	DELETE_POOLS_SUCCESS,
	GET_SELECTED_POOLS,
	HIDE_POOL_MODAL,
	LOAD_POOLS_EMPTY,
	LOAD_POOLS_ERROR,
	LOAD_POOLS_SUCCESS,
	SET_POOL_DATAINDEX,
	SHOW_POOL_MODAL,
	SUBMIT_POOLS_ERROR,
	SUBMIT_POOLS_LOADING,
	SUBMIT_POOLS_SUCCESS,
	SUPER_POOL_VALUE,
	FETCH_BOXES_ERROR,
	FETCH_BOXES_SUCCESS,
	FETCH_BOXES_LOADING
} from './types';

export const loadBoxes = () => {
	return dispatch => {
		dispatch({
			type: FETCH_BOXES_LOADING
		});

		fetch('/api/boxes')
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: FETCH_BOXES_SUCCESS,
					payload: data
				});
			})
			.catch(err => {
				dispatch({
					type: FETCH_BOXES_ERROR,
					payload: err.messages
				});
			});
	};
};

export const handleValue = (state, value) => {
	return {
		type: SUPER_POOL_VALUE,
		payload: {
			state,
			value
		}
	};
};

export const loadPools = value => {
	if (value === '') {
		return dispatch => {
			dispatch({
				type: LOAD_POOLS_EMPTY
			});
		};
	}

	return dispatch => {
		dispatch({
			type: CHANGE_BOX_NUMBER,
			payload: value
		});

		fetch('/api/pools', {
			method: 'post',
			body: JSON.stringify({
				box: value
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(response => response.json())
			.then(data => {
				_.forEach(data, e => {
					e.checked = false;
				});
				dispatch({
					type: LOAD_POOLS_SUCCESS,
					data
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_POOLS_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const submitPools = (value, pools, email, box) => {
	return dispatch => {
		dispatch({
			type: SUBMIT_POOLS_LOADING
		});

		fetch('/api/superpool', {
			method: 'post',
			body: JSON.stringify({
				pools: pools.toString(),
				value,
				email
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				dispatch({
					type: SUBMIT_POOLS_SUCCESS
				});
			})
			.then(() => {
				fetch('/api/pools', {
					method: 'post',
					body: JSON.stringify({
						box
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					}
				})
					.then(response => response.json())
					.then(data => {
						_.forEach(data, e => {
							e.checked = false;
						});
						dispatch({
							type: LOAD_POOLS_SUCCESS,
							data
						});
					})
					.catch(error => {
						dispatch({
							type: LOAD_POOLS_ERROR,
							error: error.message || `Unexpected Error!!!`
						});
					});
			})
			.catch(error => {
				dispatch({
					type: SUBMIT_POOLS_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const deletePools = (pools, email) => {
	return dispatch => {
		dispatch({
			type: DELETE_POOLS_LOADING
		});

		fetch('/api/delete-super-pools', {
			method: 'post',
			body: JSON.stringify({
				pools: pools.toString(),
				email
			}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(() => {
				dispatch({
					type: DELETE_POOLS_SUCCESS
				});
			})
			.then(() => {
				fetch(`/api/pools`)
					.then(response => response.json())
					.then(data => {
						dispatch({
							type: LOAD_POOLS_SUCCESS,
							data
						});
					})
					.catch(error => {
						dispatch({
							type: LOAD_POOLS_ERROR,
							error: error.message || `Unexpected Error!!!`
						});
					});
			})
			.catch(error => {
				dispatch({
					type: DELETE_POOLS_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const showPoolModal = items => {
	return {
		type: SHOW_POOL_MODAL,
		payload: items
	};
};

export const hidePoolModal = () => {
	return {
		type: HIDE_POOL_MODAL
	};
};

export const getSelected = items => {
	return {
		type: GET_SELECTED_POOLS,
		payload: items
	};
};

export const setDataIndex = (value, superpool) => {
	return {
		type: SET_POOL_DATAINDEX,
		payload: value,
		superpool
	};
};
