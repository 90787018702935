import React from 'react';

function FormLabel({ error, text, required }) {
	return (
		<div className="label">
			{error && <div className="error-badge" />}
			<div>
				<div className="error-text">
					{text} {required && <span className="label-asterisk">*</span>}
				</div>
				<div className="error-label">{error}</div>
			</div>
		</div>
	);
}

export default FormLabel;
