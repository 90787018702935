import React, { useEffect, useState } from 'react';
import {
	Button,
	Grid,
	Image,
	Segment,
	Menu,
	Icon,
	Modal,
	Dropdown,
	Form
} from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link, Element } from 'react-scroll';
import { defaults } from 'react-chartjs-2';
import { showSidebar } from '../../../store/actions/SidebarActions';
import { open } from '../../../store/actions/MessengerActions';
import Logo from '../../../assets/dark-logo.svg';
import ChartMonthlyPools from './shared-components/ChartMonthlyPools';
import ChartWeeklyPools from './shared-components/ChartWeeklyPools';
import WeeklyTrapsChart from './shared-components/WeeklyTrapsChart';
import WeeklyCountChart from './shared-components/WeeklyCountChart';
import ConfirmedTrapsChart from './shared-components/ChartConfirmedTraps';

const Dashboard = () => {
	const [visible, setVisible] = useState(false);
	const [date, setDate] = useState('');
	const dispatch = useDispatch();
	_.merge(defaults, {
		scale: {
			scaleLabel: {
				padding: {
					bottom: 0
				}
			},
			ticks: {
				autoSkipPadding: 5,
				maxRotation: 0,
				beginAtZero: true
			},
			gridLines: {
				color: 'rgba(255,255,255,0.05)',
				zeroLineColor: 'rgba(255,255,255,0.1)'
			}
		},
		global: {
			defaultFontColor: `rgba(255,255,255,0.5)`,
			defaultFontFamily: `Lato,'Helvetica Neue',Arial,Helvetica,sans-serif`,
			defaultFontSize: 10,
			tooltips: {
				backgroundColor: '#2d2d2d',
				borderColor: 'rgba(255,255,255,0.1)',
				borderWidth: 1,
				bodyFontColor: `rgba(255,255,255,0.5)`,
				bodySpacing: 5,
				cornerRadius: 3,
				xPadding: 10,
				yPadding: 10
			},
			legend: {
				display: false,
				labels: {
					boxWidth: 15
				}
			},
			maintainAspectRatio: false
		}
	});

	useEffect(() => {
		dispatch(open);
		dispatch(showSidebar);
	});

	console.log(date);

	return (
		<>
			<Modal open={visible} onClose={() => setVisible(false)} size="mini">
				<Modal.Header>Export Dataset</Modal.Header>
				<Modal.Content>
					<p className="small quiet">
						Select data to export by country, date and zone.
					</p>
					<div>
						<Form size="tiny">
							<Form.Field
								name="country"
								label="Country"
								required
								control={Dropdown}
								selection
								options={[
									{
										key: 'PR',
										text: 'Puerto Rico',
										value: 'Puerto Rico'
									},
									{
										key: 'VI',
										text: 'U.S. Virgin Islands',
										value: 'U.S. Virgin Islands'
									}
								]}
							/>
							<Form.Field
								name="zone"
								label="Zones"
								required
								multiple
								control={Dropdown}
								selection
								options={[
									{
										key: 'PR',
										text: 'Puerto Rico',
										value: 'Puerto Rico'
									},
									{
										key: 'VI',
										text: 'U.S. Virgin Islands',
										value: 'U.S. Virgin Islands'
									}
								]}
							/>
							<Form.Field
								name="date"
								label="Date Range"
								required
								onChange={(event, { value }) => setDate(value)}
								value={date}
								animation="fadeIn"
								control={DatesRangeInput}
								markColor="blue"
								hideMobileKeyboard
								initialDate={new Date()}
								maxDate={new Date()}
								clearable
								dateFormat="YYYY-MM-DD"
							/>
						</Form>
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button negative size="mini" onClick={() => setVisible(false)}>
						Cancel
					</Button>
					<Button primary size="mini">
						Export
					</Button>
				</Modal.Actions>
			</Modal>
			<Grid padded inverted style={{ height: '100vh' }} stretched stackable>
				{/* Menu */}
				<Grid.Row color="black" style={{ height: '6rem' }}>
					<Grid.Column>
						<Segment inverted className="dark-segment" size="mini">
							<Grid>
								<Grid.Row columns={2} verticalAlign="middle">
									<Grid.Column>
										<Image src={Logo} alt="Logo" height={42} />
									</Grid.Column>
									<Grid.Column textAlign="right">
										<Button
											content="Export"
											color="purple"
											size="mini"
											onClick={() => setVisible(true)}
										/>
										<Icon
											link
											name="vertical ellipsis"
											color="purple"
											size="large"
											onClick={() => dispatch(showSidebar())}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				{/* Content Area */}
				<Grid.Row color="black" style={{ height: 'calc(100% - 6rem)' }}>
					<Grid.Column computer={3} only="computer">
						<Grid stretched>
							<Grid.Row>
								<Grid.Column>
									<Segment inverted className="dark-segment" size="tiny">
										<Menu vertical inverted fluid size="tiny">
											<Menu.Item>
												<Menu.Header>MASS</Menu.Header>
												<Menu.Menu>
													<Menu.Item
														as={Link}
														to="pools"
														containerId="container"
														spy
														smooth
														activeClass="active"
														offset={-10}
														duration={500}
														content="Pools Summary"
													/>
												</Menu.Menu>
											</Menu.Item>
											<Menu.Item>
												<Menu.Header>Surveillance</Menu.Header>
												<Menu.Menu>
													<Menu.Item
														as={Link}
														to="processed"
														containerId="container"
														spy
														smooth
														activeClass="active"
														offset={-10}
														duration={500}
														content="Weekly Summary"
													/>
													<Menu.Item
														as={Link}
														to="confirmed"
														containerId="container"
														spy
														smooth
														activeClass="active"
														offset={-10}
														duration={500}
														content="Current week processed traps by zone"
													/>
												</Menu.Menu>
											</Menu.Item>
										</Menu>
									</Segment>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
					<Grid.Column mobile={16} computer={13}>
						<Grid stretched>
							<Grid.Row>
								<Grid.Column>
									<Segment
										id="container"
										as={Element}
										inverted
										className="dark-segment scroll-y"
										size="mini"
									>
										<Grid stackable padded>
											<Grid.Row columns={2} as={Element} name="pools">
												<Grid.Column>
													<div className="dark-box-header">
														Monthly pools count
													</div>
													<div className="dark-box-container">
														<ChartMonthlyPools />
													</div>
													<div className="dark-box-footer">
														Updated @ {new Date().toLocaleString()}
													</div>
												</Grid.Column>
												<Grid.Column>
													<div className="dark-box-header">
														Weekly pools count
													</div>
													<div className="dark-box-container">
														<ChartWeeklyPools />
													</div>
													<div className="dark-box-footer">
														Updated @ {new Date().toLocaleString()}
													</div>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row columns={2} as={Element} name="processed">
												<Grid.Column>
													<div className="dark-box-header">
														Current week processed traps
													</div>
													<div className="dark-box-container">
														<WeeklyTrapsChart />
													</div>
													<div className="dark-box-footer">
														Updated @ {new Date().toLocaleString()}
													</div>
												</Grid.Column>
												<Grid.Column>
													<div className="dark-box-header">
														Current week{' '}
														<span className="italic">Aedes aegypti</span> count
														by municipality
													</div>
													<div className="dark-box-container">
														<WeeklyCountChart />
													</div>
													<div className="dark-box-footer">
														Updated @ {new Date().toLocaleString()}
													</div>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row columns={1} as={Element} name="confirmed">
												<Grid.Column>
													<div className="dark-box-header">
														Current week processed traps by zone
													</div>
													<div className="dark-box-container">
														<ConfirmedTrapsChart />
													</div>
													<div className="dark-box-footer">
														Updated @ {new Date().toLocaleString()}
													</div>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Segment>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

export default Dashboard;
