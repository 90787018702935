import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import MenuReducer from './MenuReducer';
import InboxReducer from './InboxReducer';
import PoolsReducer from './PoolsReducer';
import PlateReducer from './PlateReducer';
import ResultsReducer from './ResultsReducer';
import SidebarReducer from './SidebarReducer';
import ChartReducer from './ChartReducer';
import PoolsEditorReducer from './PoolsEditorReducer';
import PositiveReducer from './PositiveReducer';
import BalloonReducer from './BalloonReducer';
import ToastReducer from './ToastReducer';
import CodesReducer from './CodesReducer';
import ArbovirusReducer from './ArbovirusReducer';
import MessengerReducer from './MessengerReducer';
import LarvaeReducer from './LarvaeReducer';
import OrdersReducer from './OrdersReducer';
import CustomerReducer from './CustomerReducer';

export default combineReducers({
	auth: AuthReducer,
	menu: MenuReducer,
	inbox: InboxReducer,
	pools: PoolsReducer,
	plate: PlateReducer,
	results: ResultsReducer,
	sidebar: SidebarReducer,
	charts: ChartReducer,
	poolEditor: PoolsEditorReducer,
	positive: PositiveReducer,
	balloon: BalloonReducer,
	toast: ToastReducer,
	codes: CodesReducer,
	arbovirus: ArbovirusReducer,
	messenger: MessengerReducer,
	larvae: LarvaeReducer,
	orders: OrdersReducer,
	customer: CustomerReducer
});
