import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { fetchweeklyData } from '../../../../store/actions/ChartActions';

const ChartWeeklyPools = () => {
	const data = useSelector(state => state.charts.weeklyData);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchweeklyData);
	});

	return (
		<div style={{ height: 300 }}>
			<Bar
				data={{
					labels: _.map(data, e => {
						return e.week;
					}),
					datasets: [
						{
							label: 'Pools',
							backgroundColor: '#f9886c',
							borderWidth: 0,
							hoverBackgroundColor: '#e55e5e',
							data: _.map(data, e => {
								return e.count;
							})
						}
					]
				}}
				options={{
					scales: {
						xAxes: [
							{
								scaleLabel: {
									display: true,
									labelString: 'Week'
								},
								gridLines: {
									display: false
								}
							}
						]
					}
				}}
			/>
		</div>
	);
};

export default ChartWeeklyPools;
