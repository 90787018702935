import {
	HIDE_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	SHOW_SIDEBAR
} from '../actions/types';

const INITIAL_STATE = {
	visible: false,
	rightVisible: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SHOW_SIDEBAR:
			return {
				...state,
				visible: true
			};
		case SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				rightVisible: true
			};
		case HIDE_SIDEBAR:
			return INITIAL_STATE;
		default:
			return state;
	}
};
