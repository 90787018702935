import { TOAST_ADD_ITEM, TOAST_AUTO_DELETE, TOAST_DELETE_ITEM } from './types';

export const deleteToast = index => {
	return dispatch => {
		dispatch({
			type: TOAST_DELETE_ITEM,
			payload: index
		});
	};
};

export const addToast = item => {
	return dispatch => {
		dispatch({
			type: TOAST_ADD_ITEM,
			payload: item
		});

		setTimeout(() => {
			dispatch({
				type: TOAST_AUTO_DELETE,
				payload: item
			});
		}, 10000);
	};
};
