import {
	CHECK_VALUE_CLOSE,
	CHECK_VALUE_OPEN,
	CONFIRM_NOT_FOUND,
	CONFIRM_DUPLICATED,
	GET_LAB_USERS,
	HANDLE_CHANGE_INBOX,
	HIDE_INBOX_MODAL,
	INBOX_SET_ACTIVE,
	INBOX_SET_VALUE,
	LOAD_INBOX_ERROR,
	LOAD_INBOX_LOADING,
	LOAD_INBOX_SUCCESS,
	LOAD_RECORD_POOLS_ERROR,
	LOAD_RECORD_POOLS_LOADING,
	LOAD_RECORD_POOLS_SUCCESS,
	SET_EX_TECH,
	SET_ID_TECH,
	SHOW_INBOX_MODAL
} from './types';

const duplicates = (property, array) => {
	// eslint-disable-next-line no-unused-vars
	let duplicate = false;
	const object = {};

	// eslint-disable-next-line array-callback-return
	array.map(item => {
		if (item[property] in object) {
			object[item[property]].action = 'Disabled';
			// eslint-disable-next-line no-param-reassign
			item.action = 'Delete';
			duplicate = true;
		} else {
			object[item[property]] = item;
			// eslint-disable-next-line no-param-reassign
			item.action =
				item.status === 'noaccess' || item.status === 'missing'
					? 'Confirm'
					: 'Edit';
		}
	});

	return array;
};

export const fetchInbox = () => {
	return dispatch => {
		dispatch({
			type: LOAD_INBOX_LOADING
		});

		fetch(`/api/inbox`)
			.then(response => response.json())
			.then(data => {
				const rows = duplicates('record', data);
				dispatch({
					type: LOAD_INBOX_SUCCESS,
					data: rows
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_INBOX_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchOutbox = () => {
	return dispatch => {
		dispatch({
			type: LOAD_INBOX_LOADING
		});

		fetch(`/api/outbox`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LOAD_INBOX_SUCCESS,
					data
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_INBOX_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchQuality = () => {
	return dispatch => {
		dispatch({
			type: LOAD_INBOX_LOADING
		});

		fetch(`/api/quality`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LOAD_INBOX_SUCCESS,
					data
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_INBOX_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchViInbox = () => {
	return dispatch => {
		dispatch({
			type: LOAD_INBOX_LOADING
		});

		fetch(`/api/viInbox`)
			.then(response => response.json())
			.then(data => {
				const rows = duplicates('record', data);
				dispatch({
					type: LOAD_INBOX_SUCCESS,
					data: rows
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_INBOX_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchViOutbox = () => {
	return dispatch => {
		dispatch({
			type: LOAD_INBOX_LOADING
		});

		fetch(`/api/viOutbox`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LOAD_INBOX_SUCCESS,
					data
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_INBOX_ERROR,
					error: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const fetchPools = id => {
	return dispatch => {
		dispatch({
			type: LOAD_RECORD_POOLS_LOADING
		});

		fetch(`/api/record-pools/${id}`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: LOAD_RECORD_POOLS_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: LOAD_RECORD_POOLS_ERROR,
					payload: error.message
				});
			});
	};
};

export const setExTech = value => {
	return {
		type: SET_EX_TECH,
		payload: value
	};
};

export const setIDTech = value => {
	return {
		type: SET_ID_TECH,
		payload: value
	};
};

export const showModal = data => {
	return {
		type: SHOW_INBOX_MODAL,
		payload: data
	};
};

export const hideModal = () => {
	return {
		type: HIDE_INBOX_MODAL
	};
};

export const handleChange = ({ prop, value }) => {
	return dispatch => {
		if (value >= 26) {
			dispatch({
				type: CHECK_VALUE_OPEN
			});
		}

		dispatch({
			type: HANDLE_CHANGE_INBOX,
			payload: { prop, value }
		});
	};
};

export const closeAlert = () => {
	return {
		type: CHECK_VALUE_CLOSE
	};
};

export const getLabUsers = data => {
	return dispatch => {
		dispatch({
			type: GET_LAB_USERS,
			payload: data
		});
	};
};

export const setPositive = text => {
	return {
		type: INBOX_SET_ACTIVE,
		payload: text
	};
};

export const setValue = text => {
	return {
		type: INBOX_SET_VALUE,
		payload: text
	};
};

export const notFound = val => {
	return {
		type: CONFIRM_NOT_FOUND,
		payload: val
	};
};

export const confirmDuplicated = val => {
	return {
		type: CONFIRM_DUPLICATED,
		payload: val
	};
};
