import React, { Component } from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import prvcuLogo from '../../../../assets/prvcuLogo.jpg';
import prstLogo from '../../../../assets/prstLogo.jpg';

class PdfDocument extends Component {
	render() {
		const styles = {
			pageNumber: {
				position: 'absolute',
				fontSize: 11,
				bottom: 30,
				left: 0,
				right: 0,
				textAlign: 'center',
				color: 'grey'
			},
			row: {
				display: 'flex',
				flexDirection: 'row',
				borderBottomWidth: 1,
				borderBottomColor: '#000000',
				borderBottomStyle: 'solid'
			},
			column: {
				flex: 1,
				textAlign: 'center',
				borderLeftWidth: 1,
				borderLeftColor: '#000000',
				borderLeftStyle: 'solid',
				paddingBottom: 5,
				paddingTop: 5
			}
		};

		return (
			<Document size="LETTER">
				<Page
					style={{
						paddingTop: 35,
						paddingBottom: 60,
						paddingHorizontal: 35,
						fontSize: 11,
						fontFamily: 'Times-Roman'
					}}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between'
						}}
					>
						<Image src={prvcuLogo} style={{ height: 30, width: 110.4 }} />
						<Image src={prstLogo} style={{ height: 30, width: 89.37 }} />
					</View>
					<Text
						style={{
							fontSize: 14,
							textAlign: 'center',
							marginBottom: 20,
							marginTop: 20
						}}
					>
						Mosquito Arbovirus RT-PCR Assay Report
					</Text>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: 20
						}}
					>
						<View style={{ marginRight: 10, flex: 1 }}>
							<Text style={{ marginBottom: 10, fontWeight: 'bold' }}>
								Client Information
							</Text>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Name:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Organization:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Address Line 1:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Address Line 2:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Email:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Phone:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
						</View>
						<View style={{ marginLeft: 10, flex: 1 }}>
							<Text style={{ marginBottom: 10, fontWeight: 'bold' }}>
								Test Information
							</Text>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Location:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								>
									<Text>Molecular Biology Laboratory.</Text>
								</View>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								>
									<Text>Puerto Rico Vector Control Unit, San Juan, PR</Text>
								</View>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Date Test Performed:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>PCR Plate # {this.props.plate}</Text>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Test performed and reported by:</Text>
							</View>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								>
									<Text>J. Medina Quintana</Text>
								</View>
							</View>
						</View>
					</View>
					<View>
						<Text
							style={{
								marginBottom: 20,
								textAlign: 'justify',
								borderBottomWidth: 1,
								borderBottomColor: '#000000',
								borderBottomStyle: 'solid',
								borderTopWidth: 1,
								borderTopColor: '#000000',
								borderTopStyle: 'solid',
								paddingTop: 5,
								paddingBottom: 5
							}}
						>
							<Text style={{ fontWeight: 'bold' }}>Purpose:</Text> Multi-target
							molecular assay intended for the qualitative detection and
							differentiation of RNA from the Dengue (DENV), Chikungunya (CHIKV)
							and Zika (ZIKV) viruses in mosquito samples through Reverse
							Transcriptase Polymerase Chain Reaction (RT-PCR). Laboratory
							Developed Test for Research Use Only (RUO).
						</Text>
					</View>
					<View style={{ backgroundColor: '#ffac25', marginBottom: 20 }}>
						<Text style={{ textAlign: 'center', fontSize: 14, padding: 5 }}>
							Results Report
						</Text>
					</View>
					<View
						style={{
							borderTopWidth: 1,
							borderTopColor: '#000000',
							borderTopStyle: 'solid',
							borderRightWidth: 1,
							borderRightColor: '#000000',
							borderRightStyle: 'solid',
							marginBottom: 20
						}}
					>
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<View
								style={{
									flex: 1,
									borderLeftWidth: 1,
									borderLeftColor: '#000000',
									borderLeftStyle: 'solid'
								}}
							/>
							<Text
								style={{
									flex: 3,
									textAlign: 'center',
									borderLeftWidth: 1,
									borderLeftColor: '#000000',
									borderLeftStyle: 'solid',
									paddingBottom: 5,
									paddingTop: 5
								}}
							>
								Results: CT value
							</Text>
							<Text style={styles.column} />
						</View>
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<Text style={styles.column}>Specimen ID</Text>
							<Text style={styles.column}>DENV</Text>
							<Text style={styles.column}>CHIKV</Text>
							<Text style={styles.column}>ZIKV</Text>
							<Text style={styles.column}>Interpretation</Text>
						</View>
						{this.props.data
							? this.props.data.map(e => {
									return (
										<View key={e.key} style={styles.row}>
											<Text style={styles.column}>{e.pool}</Text>
											<Text style={styles.column}>{e.den}</Text>
											<Text style={styles.column}>{e.chik}</Text>
											<Text style={styles.column}>{e.zik}</Text>
											<Text style={styles.column}>{e.result}</Text>
										</View>
									);
							  })
							: ''}
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<Text style={styles.column}>Negative Control</Text>
							<Text style={styles.column}>{this.props.data[0].negControl}</Text>
							<Text style={styles.column}>{this.props.data[0].negControl}</Text>
							<Text style={styles.column}>{this.props.data[0].negControl}</Text>
							<Text style={styles.column}>Negative</Text>
						</View>
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<Text style={styles.column}>DENV Control</Text>
							<Text style={styles.column}>{this.props.data[0].denControl}</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>Positive to DENV</Text>
						</View>
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<Text style={styles.column}>CHIKV Control</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>
								{this.props.data[0].chikControl}
							</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>Positive to CHIKV</Text>
						</View>
						<View style={[styles.row, { backgroundColor: '#ddd' }]}>
							<Text style={styles.column}>ZIKV Control</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>0</Text>
							<Text style={styles.column}>{this.props.data[0].zikControl}</Text>
							<Text style={styles.column}>Positive to ZIKV</Text>
						</View>
					</View>
					<View style={{ marginBottom: 20 }}>
						<Text>Approved and Certified by:</Text>
						<Text>Joanelis Medina Quintana, MS</Text>
						<Text>Laboratory Research Specialist</Text>
						<Text>First Verification</Text>
						<Text>PR Vector Control Unit</Text>
						<Text>Office: 787-523-1592 ext 1000</Text>
						<Text>Email: jomedina@prvectorcontrol.org</Text>
					</View>
					<View style={{ marginBottom: 20 }}>
						<Text>
							Second Verification is necessary if positive values are reported.
						</Text>
					</View>
					<View style={{ marginBottom: 20, width: '35%' }}>
						<Text>Info. Second Hand verification Contact:</Text>
						<View style={{ display: 'flex', marginBottom: 5 }}>
							<View
								style={{
									flex: 1,
									borderBottomWidth: 1,
									borderBottomColor: '#000000',
									borderBottomStyle: 'solid',
									height: 10,
									marginBottom: 5
								}}
							/>
							<View
								style={{
									flex: 1,
									borderBottomWidth: 1,
									borderBottomColor: '#000000',
									borderBottomStyle: 'solid',
									height: 10,
									marginBottom: 5
								}}
							/>
							<View
								style={{
									flex: 1,
									borderBottomWidth: 1,
									borderBottomColor: '#000000',
									borderBottomStyle: 'solid',
									height: 10,
									marginBottom: 5
								}}
							/>
							<View
								style={{
									flex: 1,
									borderBottomWidth: 1,
									borderBottomColor: '#000000',
									borderBottomStyle: 'solid',
									height: 10,
									marginBottom: 5
								}}
							/>
							<View
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: 5
								}}
							>
								<Text>Date verified:</Text>
								<View
									style={{
										flex: 1,
										borderBottomWidth: 1,
										borderBottomColor: '#000000',
										borderBottomStyle: 'solid'
									}}
								/>
							</View>
						</View>
					</View>
					<Text
						style={styles.pageNumber}
						render={({ pageNumber }) =>
							`${pageNumber} | Plate ${this.props.plate} `
						}
						fixed
					/>
				</Page>
			</Document>
		);
	}
}

export default PdfDocument;
