import React, { useEffect, useState } from 'react';
import { Container, Grid, Dropdown, Form, Checkbox } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import _ from 'lodash';
import is from 'is_js';
import moment from 'moment';
import { useMount, useUpdateEffect } from 'react-use';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../shared-components/Navbar';
import Banner from './shared-components/Banner';
import Breadcrumbs from './shared-components/Breadcrumbs';
import Title from './shared-components/Title';
import Footer from '../../shared-components/Footer';
import { addToast } from '../../../store/actions/ToastActions';

function Export() {
	const dispatch = useDispatch();
	const [array, setArray] = useState(null);
	const [tables, setTables] = useState([]);
	const [names, setNames] = useState([]);
	const [columns, setColumns] = useState([]);
	const [select, setSelect] = useState('');
	const [orderTbBy, setOrderTbBy] = useState('');
	const [sortTbBy, setSortTbBy] = useState('DESC');
	const [fromDate, setFromDate] = useState(
		moment()
			.startOf('month')
			.format('YYYY-MM-DD')
	);
	const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
	const [loading, setLoading] = useState(false);
	const [dates, setDates] = useState(null);
	const [query, setQuery] = useState({
		table: null,
		columns: null,
		orderBy: null,
		sortBy: null,
		fromDate: null,
		toDate: null,
		municipalities: null
	});
	const [checked, setChecked] = useState(false);

	const toCSV = obj => {
		setLoading(true);
		const items = obj;
		const replacer = (key, value) => (value === null ? '' : value);
		const header = Object.keys(items[0]);
		let csv = items.map(row =>
			header
				.map(fieldName => JSON.stringify(row[fieldName], replacer))
				.join(',')
		);
		csv.unshift(header.join(','));
		csv = csv.join('\r\n');

		const downloadLink = document.createElement('a');
		const blob = new Blob(['\ufeff', csv]);
		const url = URL.createObjectURL(blob);
		const fileName = query && query.table;
		downloadLink.href = url;
		downloadLink.download = `${fileName}.csv`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		setLoading(false);
	};

	const handleSubmit = (e, { table, columns }) => {
		e.preventDefault();
		axios
			.post('/api/export-query', {
				table,
				columns,
				orderBy: orderTbBy,
				sortBy: sortTbBy,
				fromDate,
				toDate
			})
			.then(response => {
				const json = response.data;
				toCSV(json);
			})
			.catch(error => {
				if (error) {
					dispatch(
						addToast({
							type: 'error',
							message: error.message
						})
					);
					setLoading(false);
				}
			});
	};

	const handleChange = (e, data) => {
		if (data.checked) {
			setColumns([...columns, data.value]);
		} else {
			const array = [...columns];
			const index = array.indexOf(data.value);
			if (index !== -1) {
				array.splice(index, 1);
				setColumns(array);
			}
		}
	};

	const getDates = () => {
		axios
			.post('/api/export-table-dates', {
				table: array[select][0].tbl
			})
			.then(response => {
				const json = response.data;
				const unique = _.uniqBy(json, 'export_date');
				const days = _.map(unique, u => {
					if (is.not.empty(u)) {
						return moment(u.export_date, 'YYYY-MM-DD').toDate();
					}
					return null;
				});
				setDates(days);
			})
			.catch(error => {
				if (error) {
					dispatch(
						addToast({
							type: 'error',
							message: error.message
						})
					);
				}
			});
	};

	useMount(() => {
		axios
			.get('/api/export')
			.then(response => {
				const group = _.groupBy(response.data, 'tbl');
				setArray(group);
				const uniqBy = _.uniqBy(response.data, 'txt');
				setNames(
					_.map(uniqBy, e => {
						return e.txt;
					})
				);
			})
			.catch(error => console.log(error));
	});

	useEffect(() => {
		if (array) {
			const keys = _.keys(array);
			setTables(keys);
		}
	}, [array]);

	useEffect(() => {
		setQuery({
			table: select,
			columns,
			orderBy: orderTbBy,
			sortBy: sortTbBy,
			fromDate,
			toDate
		});
	}, [select, columns, orderTbBy, sortTbBy, fromDate, toDate]);

	useUpdateEffect(() => {
		if (select) {
			getDates();
		}
	}, [select]);

	useEffect(() => {
		if (checked) {
			setColumns(
				_.map(array[select], e => {
					return e.col;
				})
			);
		} else {
			setColumns([]);
		}
	}, [checked, select, array]);

	return (
		<>
			<Navbar />
			<Banner
				title="Data: Export"
				subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in augue id massa ultricies interdum. Ut mattis facilisis lacinia. Nunc auctor, eros nec pulvinar euismod, elit quam scelerisque dolor, sit amet ornare tellus dui et orci."
			/>
			<Container text className="mt-30 mb-30">
				<Grid stackable>
					<Breadcrumbs
						sections={[
							{ key: 'home', content: 'Home', to: '/', as: Link },
							{
								key: 'export',
								content: 'Export',
								active: true
							}
						]}
					/>
					<Title
						title="Export"
						subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in augue id massa ultricies interdum. Ut mattis facilisis lacinia. Nunc auctor, eros nec pulvinar euismod, elit quam scelerisque dolor, sit amet ornare tellus dui et orci."
					/>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Form
								onSubmit={e => {
									setLoading(true);
									handleSubmit(e, query);
								}}
							>
								<Form.Field
									required
									label="Select table"
									control={Dropdown}
									placeholder="Select Table"
									fluid
									selection
									search
									loading={!tables}
									options={_.map(tables, (e, index) => {
										return {
											key: e,
											text: names[index],
											value: e
										};
									})}
									onChange={(e, { value }) => {
										setSelect(value);
										setColumns([]);
										setChecked(false);
									}}
								/>

								{select && (
									<Grid stackable>
										<span className="mt-10 strong">
											Select one or more columns from the table to export.
										</span>
										<Grid.Row>
											<Grid.Column>
												<Form.Field
													control={Checkbox}
													toggle
													label="Select all columns"
													checked={checked}
													onChange={() => setChecked(!checked)}
												/>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row columns={3}>
											{_.map(array[select], (e, index) => {
												return (
													<Grid.Column key={index}>
														<Form.Field
															control={Checkbox}
															label={e.col}
															value={e.col}
															checked={is.inArray(e.col, columns)}
															onChange={handleChange}
														/>
													</Grid.Column>
												);
											})}
										</Grid.Row>
									</Grid>
								)}

								{select && columns && (
									<>
										<p className="strong quiet">
											Select column to for data sorting.
										</p>
										<Form.Group widths="equal">
											<Form.Field
												required
												label="Sort By"
												control={Dropdown}
												placeholder="Select column"
												fluid
												selection
												search
												value={orderTbBy}
												disabled={!select}
												options={_.map(columns, e => {
													return {
														key: e,
														text: e,
														value: e
													};
												})}
												onChange={(e, { value }) => {
													setOrderTbBy(value);
												}}
											/>

											<Form.Field
												required
												label="Sort Options"
												control={Dropdown}
												placeholder="Select options"
												fluid
												selection
												search
												value={sortTbBy}
												disabled={!select}
												options={[
													{
														key: 'ASC',
														text: 'Ascending',
														value: 'ASC'
													},
													{
														key: 'DESC',
														text: 'Descending',
														value: 'DESC'
													}
												]}
												onChange={(e, { value }) => {
													setSortTbBy(value);
												}}
											/>
										</Form.Group>
										<p className="strong quiet">
											Select date range for data to export. Try not to select
											more than 3 months from today.
										</p>
										<Form.Group widths="equal">
											<Form.Field
												required
												name="from"
												label="From Date"
												placeholder="yyyy-mm-dd"
												value={fromDate}
												iconPosition="left"
												control={DateInput}
												maxDate={toDate}
												onChange={(event, { value }) => {
													setFromDate(value);
												}}
												marked={dates}
												dateFormat="YYYY-MM-DD"
												markColor="orange"
											/>
											<Form.Field
												required
												name="to"
												label="To Date"
												placeholder="yyyy-mm-dd"
												value={toDate}
												iconPosition="left"
												maxDate={new Date()}
												control={DateInput}
												onChange={(event, { value }) => {
													setToDate(value);
												}}
												marked={dates}
												dateFormat="YYYY-MM-DD"
												markColor="orange"
											/>
										</Form.Group>
									</>
								)}
								<Form.Button
									disabled={is.any.falsy([
										select,
										columns,
										orderTbBy,
										sortTbBy,
										fromDate,
										toDate
									])}
									loading={loading}
									content="Export"
								/>
							</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
			<Footer />
		</>
	);
}

export default Export;
