import axios from 'axios';
import {
	CUSTOMER_ORDERS_SUCCESS,
	CUSTOMER_ORDERS_ERROR,
	CUSTOMER_ORDERS_LOADING,
	CUSTOMER_ORDERS_ORDER,
	CUSTOMER_ORDERS_POOLS,
	CUSTOMER_ORDER_POOL,
	CUSTOMER_POOLS_LOADING,
	CUSTOMER_POOLS_SUCCESS,
	CUSTOMER_POOLS_ERROR
} from './types';

export const fetchOrders = () => {
	return dispatch => {
		dispatch({
			type: CUSTOMER_ORDERS_LOADING
		});

		axios
			.get('/api/orders')
			.then(response => {
				dispatch({
					type: CUSTOMER_ORDERS_SUCCESS,
					payload: response.data
				});
			})
			.catch(error => {
				dispatch({
					type: CUSTOMER_ORDERS_ERROR,
					payload: error.response.data.message
				});
			});
	};
};

export const fetchPools = uuid => {
	return dispatch => {
		dispatch({
			type: CUSTOMER_POOLS_LOADING
		});

		axios
			.get(`/api/record-pools/${uuid}`)
			.then(response => {
				dispatch({
					type: CUSTOMER_POOLS_SUCCESS,
					payload: response.data
				});
			})
			.catch(error => {
				dispatch({
					type: CUSTOMER_POOLS_ERROR,
					payload: error.response.data.message
				});
			});
	};
};

export const setOrder = order => {
	return {
		type: CUSTOMER_ORDERS_ORDER,
		payload: order
	};
};

export const setPools = order => {
	return {
		type: CUSTOMER_ORDERS_POOLS,
		payload: order
	};
};

export const setRecord = record => {
	return {
		type: CUSTOMER_ORDER_POOL,
		payload: record
	};
};
