import _ from 'lodash';
import {
	TOAST_ADD_ITEM,
	TOAST_DELETE_ITEM,
	TOAST_AUTO_DELETE
} from '../actions/types';

const INITIAL_STATE = {
	list: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TOAST_ADD_ITEM:
			return {
				...state,
				list: _.concat(state.list, action.payload)
			};
		case TOAST_DELETE_ITEM:
			return {
				...state,
				list: state.list.filter((item, index) => index !== action.payload)
			};
		case TOAST_AUTO_DELETE:
			return {
				...state,
				list: state.list.filter(item => item !== action.payload)
			};
		default:
			return state;
	}
};
