import React, { useState, useEffect } from 'react';
import { Form, Input, Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addToast } from '../../../../../store/actions/ToastActions';
import { getData } from '../../../../../store/actions/OrdersActions';
import { Countries } from '../../../../../utils/Countries';

function ClientsForm() {
	const dispatch = useDispatch();
	const state = useSelector(state => state.orders);
	const email = useSelector(state => state.auth.email);
	const [url, setUrl] = useState('/api/add-organization');

	useEffect(() => {
		if (state.record) setUrl('/api/edit-organization');
	}, [state]);

	return (
		<Formik
			initialValues={{
				organization: state.record ? state.record.organization : '',
				code: state.record ? state.record.code : '',
				name: state.record ? state.record.name : '',
				address: state.record ? state.record.address : '',
				city: state.record ? state.record.city : '',
				state: state.record ? state.record.state : '',
				country: state.record ? state.record.country : '',
				zipcode: state.record ? state.record.zipcode : '',
				phone: state.record ? state.record.phone : '',
				email: state.record ? state.record.email : '',
				user: email
			}}
			onSubmit={(
				values,
				{ setSubmitting, setErrors, setStatus, resetForm }
			) => {
				if (state.record) values.id = state.record.id;

				fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(values)
				})
					.then(response => {
						return response.json();
					})
					.then(json => {
						if (json.success) {
							dispatch(
								addToast({
									message: json.success,
									type: 'success'
								})
							);
							resetForm();
							setStatus({ success: true });
							dispatch(getData('/api/organizations'));
						} else {
							dispatch(
								addToast({
									message: json.error,
									type: 'error'
								})
							);
						}

						setSubmitting(false);
					})
					.catch(error => {
						setStatus({ success: false });
						setSubmitting(false);
						setErrors({ submit: error.message });
					});
			}}
			validationSchema={Yup.object().shape({
				organization: Yup.string().required('This field is required'),
				name: Yup.string().required('This field is required'),
				address: Yup.string().required('This field is required'),
				city: Yup.string().required('This field is required'),
				country: Yup.string().required('This field is required'),
				email: Yup.string()
					.email('Please add a valid email')
					.required('This field is required'),
				zipcode: Yup.string().required('This field is required'),
				code: Yup.string()
					.min(5, 'The code must be 5 digits')
					.max(5, 'The code must be 5 digits')
					.required('This field is required')
			})}
		>
			{props => {
				const {
					values,
					errors,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					touched,
					status,
					setFieldValue,
					setFieldTouched
				} = props;
				return (
					<Form
						size="small"
						onSubmit={handleSubmit}
						loading={isSubmitting}
						success={!!status && !!status.success}
						error={!!errors.submit}
						noValidate
					>
						<Form.Group widths="equal">
							<Form.Field
								width={12}
								required
								label="Organization"
								placeholder="Puerto Rico Vector Control Unit"
								name="organization"
								type="text"
								control={Input}
								value={values.organization}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									errors.organization &&
									touched.organization && {
										content: errors.organization,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
							<Form.Field
								width={4}
								placeholder="16401"
								required
								label="Code"
								name="code"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.code}
								error={
									errors.code &&
									touched.code && {
										content: errors.code,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="Name"
								placeholder="Nicole Nazario Maldonado"
								name="name"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.name}
								error={
									errors.name &&
									touched.name && {
										content: errors.name,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
							<Form.Field
								required
								label="Address"
								name="address"
								placeholder="105 Carr. # 21, Bo. Monacillos"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.address}
								error={
									errors.address &&
									touched.address && {
										content: errors.address,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="City"
								name="city"
								placeholder="San Juan"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.city}
								error={
									errors.city &&
									touched.city && {
										content: errors.city,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
							<Form.Field
								label="State"
								name="state"
								placeholder="California"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.state}
							/>
							<Form.Field
								required
								label="Country"
								name="country"
								id="country"
								placeholder="Puerto Rico"
								type="text"
								onChange={(e, { value }) => {
									setFieldValue('country', value);
								}}
								onBlur={() => setFieldTouched('country', true, true)}
								control={Dropdown}
								value={values.country}
								selection
								clearable
								search
								scrolling
								options={Countries}
								error={
									errors.country &&
									touched.country && {
										content: errors.country,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="Zip Code"
								name="zipcode"
								type="text"
								placeholder="00936"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.zipcode}
								error={
									errors.zipcode &&
									touched.zipcode && {
										content: errors.zipcode,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field
								required
								label="E-mail"
								name="email"
								placeholder="info@prvectorcontrol.org"
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								control={Input}
								value={values.email}
								error={
									errors.email &&
									touched.email && {
										content: errors.email,
										pointing: 'above',
										size: 'tiny'
									}
								}
							/>
							<Form.Field
								placeholder="787-523-5860"
								label="Phone"
								name="phone"
								type="text"
								onBlur={handleBlur}
								control={Input}
								value={values.phone}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Button
							primary
							size="mini"
							type="submit"
							loading={isSubmitting}
						>
							Register
						</Form.Button>
					</Form>
				);
			}}
		</Formik>
	);
}

export default ClientsForm;
