import React from 'react';
import { Confirm, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { confirmDuplicated } from '../../../../../store/actions/InboxActions';

function ConfirmDuplicated(props) {
	const open = useSelector(state => state.inbox.duplicated);
	const dispatch = useDispatch();

	return (
		<Confirm
			open={open}
			size="mini"
			header="Duplicated"
			content={`The ${props.barcode} trap was found duplicated. Please delete trap to continue.`}
			onCancel={() => {
				dispatch(confirmDuplicated(false));
			}}
			cancelButton={() => {
				return (
					<Button
						size="small"
						content="Cancel"
						onClick={() => {
							dispatch(confirmDuplicated(false));
						}}
					/>
				);
			}}
			confirmButton={() => {
				return (
					<Button
						negative
						size="small"
						content="Delete"
						onClick={props.onClick}
					/>
				);
			}}
		/>
	);
}

export default ConfirmDuplicated;
