import React from 'react';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Grid } from 'semantic-ui-react';
import { getData } from '../../../../../store/actions/OrdersActions';
import ClientListItem from './ClientListItem';

function ClientsList() {
	const dispatch = useDispatch();
	const state = useSelector(state => state.orders);

	useMount(() => {
		dispatch(getData('/api/organizations'));
	});

	if (!state.data) {
		return null;
	}

	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<div className="pre">
					{_.orderBy(
						matchSorter(state.data, state.value, {
							keys: ['name', 'organization', 'code']
						}),
						['code'],
						['asc']
					).map(e => {
						return <ClientListItem key={e.id} item={e} />;
					})}
				</div>
			</Grid.Column>
		</Grid.Row>
	);
}

export default ClientsList;
