import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import { useFormik } from 'formik';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import { Button, Container, Form, Grid, Header, Icon, Input, Modal, Progress, Table } from 'semantic-ui-react';
import * as XLSX from 'xlsx';
import { getAdmin } from '../../../auth-utils';
import Switch from '../../../shared-components/Switch';
import PdfDocument from './PdfDocument';

function Report() {
	// GLOBAL STATE
	const role = useSelector(state => state.auth.role);
	// LOCAL STATE
	const [plate, setPlate] = useState('');
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState([]);
	const [term, setTerm] = useState('');

	const exportToCSV = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const dataset = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		FileSaver.saveAs(dataset, 'report.xlsx');
	};

	const getData = () => {
		axios
			.post('/api/results', {
				plate: plate || 0
			})
			.then(r => {
				setData(r.data);
			})
			.catch(e => console.log(e))
			.finally(() => setLoading(false));
	};

	const formik = useFormik({
		initialValues: {
			den: '',
			denControl: '',
			denThreshold: '',
			chik: '',
			chikControl: '',
			chikThreshold: '',
			zik: '',
			zikControl: '',
			zikThreshold: '',
			negControl: '',
			result: '',
			dengueSerotype: '',
			positive_control_d1: '',
			positive_control_d2: '',
			positive_control_d3: '',
			positive_control_d4: '',
			mapa_date: '',
			mdsa_date: '',
			denv_mdsa_ct_value: ''
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			axios
				.post('/api/add-results', {
					pool: checked,
					den: values.den,
					chik: values.chik,
					zik: values.zik,
					result: values.result,
					dengueSerotype: values.dengueSerotype,
					plate,
					negControl: values.negControl,
					denControl: values.denControl,
					chikControl: values.chikControl,
					zikControl: values.zikControl,
					denThreshold: values.denThreshold,
					chikThreshold: values.chikThreshold,
					zikThreshold: values.zikThreshold,
					positive_control_d1: values.positive_control_d1,
					positive_control_d2: values.positive_control_d2,
					positive_control_d3: values.positive_control_d3,
					positive_control_d4: values.positive_control_d4,
					mapa_date: values.mapa_date,
					mdsa_date: values.mdsa_date,
					denv_mdsa_ct_value: values.denv_mdsa_ct_value
				})
				.then(() => {
					_.forEach(checked, pool => {
						const i = _.findIndex(data, { pool });
						if (i !== -1) {
							data[i].den = values.den || 0;
							data[i].denControl = values.denControl || 0;
							data[i].denThreshold = values.denThreshold || 0;
							data[i].chik = values.chik || 0;
							data[i].chikControl = values.chikControl || 0;
							data[i].chikThreshold = values.chikThreshold || 0;
							data[i].zik = values.zik || 0;
							data[i].zikControl = values.zikControl || 0;
							data[i].zikThreshold = values.zikThreshold || 0;
							data[i].negControl = values.negControl || 0;
							data[i].result = values.result;
							data[i].dengueSerotype = values.dengueSerotype;
							data[i].positive_control_d1 = values.positive_control_d1 || 0;
							data[i].positive_control_d2 = values.positive_control_d2 || 0;
							data[i].positive_control_d3 = values.positive_control_d3 || 0;
							data[i].positive_control_d4 = values.positive_control_d4 || 0;
							data[i].mapa_date = values.mapa_date;
							data[i].mdsa_date = values.mdsa_date;
							data[i].denv_mdsa_ct_value = values.denv_mdsa_ct_value || 0;
						}
					});
				})
				.catch(e => console.log(e))
				.finally(() => {
					setChecked([]);
					setSubmitting(false);
					setVisible(false);
					resetForm();
				});
		}
	});

	return (
		<Container>
			<Modal size="small" open={visible}>
				<Modal.Content>
					<p className="strong">Add Results</p>
					<p className="small quiet">You have selected {checked.length} to add results in bulk.</p>
					<Form size="small">
						<Form.Group widths="equal">
							<Form.Input
								id="den"
								name="den"
								type="number"
								label="Dengue CT Value"
								size="small"
								placeholder="0.001"
								value={formik.values.den}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="denControl"
								name="denControl"
								type="number"
								label="Dengue Control"
								size="small"
								placeholder="0.001"
								value={formik.values.denControl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="denThreshold"
								name="denThreshold"
								type="number"
								label="Dengue Threshold"
								size="small"
								placeholder="0.001"
								value={formik.values.denThreshold}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="chik"
								name="chik"
								type="number"
								label="Chikungunya CT Value"
								size="small"
								placeholder="0.001"
								value={formik.values.chik}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="chikControl"
								name="chikControl"
								type="number"
								label="Chikungunya Control"
								size="small"
								placeholder="0.001"
								value={formik.values.chikControl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="chikThreshold"
								name="chikThreshold"
								type="number"
								label="Chikungunya Threshold"
								size="small"
								placeholder="0.001"
								value={formik.values.chikThreshold}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="zik"
								name="zik"
								type="number"
								label="Zika CT Value"
								size="small"
								placeholder="0.001"
								value={formik.values.zik}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="zikControl"
								name="zikControl"
								type="number"
								label="Zika Control"
								size="small"
								placeholder="0.001"
								value={formik.values.zikControl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="zikThreshold"
								name="zikThreshold"
								type="number"
								label="Zika Threshold"
								size="small"
								placeholder="0.001"
								value={formik.values.zikThreshold}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="negControl"
								name="negControl"
								type="number"
								label="Negative Control"
								style={{ textAlign: 'center' }}
								size="small"
								placeholder="0.001"
								value={formik.values.negControl}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="positive_control_d1"
								name="positive_control_d1"
								type="number"
								label="Positive Control D1"
								style={{ textAlign: 'center' }}
								size="small"
								placeholder="0.001"
								value={formik.values.positive_control_d1}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="positive_control_d2"
								name="positive_control_d2"
								type="number"
								label="Positive Control D2"
								style={{ textAlign: 'center' }}
								size="small"
								placeholder="0.001"
								value={formik.values.positive_control_d2}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="positive_control_d3"
								name="positive_control_d3"
								type="number"
								label="Positive Control D3"
								style={{ textAlign: 'center' }}
								size="small"
								placeholder="0.001"
								value={formik.values.positive_control_d3}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="positive_control_d4"
								name="positive_control_d4"
								type="number"
								label="Positive Control D4"
								style={{ textAlign: 'center' }}
								size="small"
								placeholder="0.001"
								value={formik.values.positive_control_d4}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field
								id="mapa_date"
								name="mapa_date"
								label="MAPA Date"
								size="small"
								placeholder="MM-DD-YYYY HH:mm:ss"
								value={formik.values.mapa_date}
								onChange={(event, { value }) => {
									formik.setFieldValue('mapa_date', value);
								}}
								closable
								clearable
								animation="none"
								dateTimeFormat="MM-DD-YYYY HH:mm:ss"
								control={DateTimeInput}
							/>
							<Form.Input
								id="mdsa_date"
								name="mdsa_date"
								label="MDSA Date"
								size="small"
								placeholder="MM-DD-YYYY HH:mm:ss"
								value={formik.values.mdsa_date}
								onChange={(event, { value }) => {
									formik.setFieldValue('mdsa_date', value);
								}}
								closable
								clearable
								animation="none"
								dateTimeFormat="MM-DD-YYYY HH:mm:ss"
								control={DateTimeInput}
							/>
							<Form.Input
								id="denv_mdsa_ct_value"
								name="denv_mdsa_ct_value"
								type="number"
								label="Denv MDSA CT Value"
								size="small"
								placeholder="0.001"
								value={formik.values.denv_mdsa_ct_value}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Dropdown
							id="result"
							name="result"
							label="Result"
							selection
							fluid
							size="small"
							header="Select Result"
							placeholder="Positive..."
							value={formik.values.result}
							onChange={(e, { value }) => formik.setFieldValue('result', value)}
							options={[
								{
									key: 'Negative',
									value: 'Negative',
									text: 'Negative'
								},
								{
									key: 'Positive to DenV',
									value: 'Positive to DenV',
									text: 'Positive to DenV'
								},
								{
									key: 'Positive to ChikV',
									value: 'Positive to ChikV',
									text: 'Positive to ChikV'
								},
								{
									key: 'Positive to ZikV',
									value: 'Positive to ZikV',
									text: 'Positive to ZikV'
								}
							]}
						/>
						<Form.Dropdown
							id="dengueSerotype"
							name="dengueSerotype"
							label="Dengue Serotype"
							selection
							fluid
							clearable
							upward
							size="small"
							header="Select Serotype"
							placeholder="DEN-01..."
							value={formik.values.dengueSerotype}
							onChange={(e, { value }) => formik.setFieldValue('dengueSerotype', value)}
							options={[
								{
									key: 'DENV-1',
									value: 'DENV-1',
									text: 'DENV-1'
								},
								{
									key: 'DENV-2',
									value: 'DENV-2',
									text: 'DENV-2'
								},
								{
									key: 'DENV-3',
									value: 'DENV-3',
									text: 'DENV-3'
								},
								{
									key: 'DENV-4',
									value: 'DENV-4',
									text: 'DENV-4'
								},
								{
									key: 'DENV-2 & DENV-3',
									value: 'DENV-2 & DENV-3',
									text: 'DENV-2 & DENV-3'
								}
							]}
						/>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button
						size="mini"
						content="Cancel"
						onClick={() => {
							setVisible(false);
							setChecked([]);
							formik.resetForm();
						}}
					/>
					<Button
						size="mini"
						primary
						content="Submit"
						loading={formik.isSubmitting}
						onClick={() => formik.submitForm()}
					/>
				</Modal.Actions>
			</Modal>
			<Modal size="mini" open={open}>
				<Modal.Content>
					<Modal.Description>
						<Grid textAlign="center">
							<Grid.Row>
								<Grid.Column>
									<p className="small strong">
										Generating report for plate {plate} on {new Date().toLocaleString()}
									</p>
									<p className="x-small quiet">Please wait a few seconds until the report it is generated.</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<PDFDownloadLink document={<PdfDocument data={data} plate={plate} />} fileName="report.pdf">
										{({ loading }) =>
											loading ? (
												<Progress percent={60} color="yellow" size="tiny" />
											) : (
												<Button size="mini" positive content="Download" />
											)
										}
									</PDFDownloadLink>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button negative size="mini" onClick={() => setOpen(false)} content="Cancel" />
				</Modal.Actions>
			</Modal>
			<Grid stackable>
				<Grid.Row columns={2}>
					<Grid.Column>
						<Input
							loading={loading}
							icon="search"
							iconPosition="left"
							size="small"
							placeholder="Plate number.."
							value={plate}
							onChange={e => setPlate(e.target.value)}
							style={{ marginRight: 5 }}
						/>
						<Button
							positive
							size="small"
							content="Search"
							onClick={() => {
								setLoading(true);
								setData([]);
								getData();
							}}
						/>
					</Grid.Column>
					<Grid.Column floated="right" textAlign="right">
						<Button
							style={{ minWidth: 100 }}
							size="mini"
							primary
							onClick={() => exportToCSV()}
							disabled={data.length === 0}
							content="Export to excel"
						/>
						<Button
							style={{ minWidth: 100 }}
							size="mini"
							primary
							color="orange"
							onClick={() => setOpen(true)}
							disabled={data.length === 0}
							content="Export to *.pdf"
						/>
						<Button
							style={{ minWidth: 100 }}
							disabled={checked.length === 0}
							size="mini"
							positive
							onClick={() => {
								setVisible(true);
								const item = _.filter(data, ['pool', checked[0]]);
								formik.setFieldValue('den', item[0].den || '');
								formik.setFieldValue('chik', item[0].chik || '');
								formik.setFieldValue('zik', item[0].zik || '');
								formik.setFieldValue('result', item[0].result || '');
								formik.setFieldValue('negControl', item[0].negControl || '');
								formik.setFieldValue('denControl', item[0].denControl || '');
								formik.setFieldValue('chikControl', item[0].chikControl || '');
								formik.setFieldValue('zikControl', item[0].zikControl || '');
								formik.setFieldValue('denThreshold', item[0].denThreshold || '');
								formik.setFieldValue('chikThreshold', item[0].chikThreshold || '');
								formik.setFieldValue('zikThreshold', item[0].zikThreshold || '');
								formik.setFieldValue('dengueSerotype', item[0].dengueSerotype || '');
								formik.setFieldValue('positive_control_d1', item[0].positive_control_d1 || '');
								formik.setFieldValue('positive_control_d2', item[0].positive_control_d2 || '');
								formik.setFieldValue('positive_control_d3', item[0].positive_control_d3 || '');
								formik.setFieldValue('positive_control_d4', item[0].positive_control_d4 || '');
								formik.setFieldValue('mapa_date', item[0].mapa_date || '');
								formik.setFieldValue('mdsa_date', item[0].mdsa_date || '');
								formik.setFieldValue('denv_mdsa_ct_value', item[0].denv_mdsa_ct_value || '');
							}}
							content="Add Results"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						{data.length ? (
							<>
								<p className="strong small mb-0">Control Values</p>
								<p className="quiet small">Add control values for the following plate results.</p>
								<Table stackable textAlign="center" size="small" celled>
									<Table.Header>
										<Table.Row textAlign="right">
											<Table.HeaderCell colSpan="13">
												<Input
													name="filter"
													placeholder="Filter by pool or super..."
													iconPosition="left"
													size="small"
													value={term}
													icon={term ? <Icon link name="close" onClick={() => setTerm('')} /> : <Icon name="search" />}
													onChange={e => setTerm(e.target.value)}
												/>
											</Table.HeaderCell>
										</Table.Row>
										<Table.Row>
											<Table.HeaderCell>
												<Switch
													checked={checked.length === data.length}
													onChange={e => {
														if (e.target.checked) {
															setChecked(
																_.map(
																	matchSorter(data, term, {
																		keys: ['pool', 'superpool']
																	}),
																	item => {
																		return item.pool;
																	}
																)
															);
														} else {
															setChecked([]);
														}
													}}
												/>
											</Table.HeaderCell>
											<Table.HeaderCell>Pool</Table.HeaderCell>
											<Table.HeaderCell>Well</Table.HeaderCell>
											<Table.HeaderCell>den CT Value</Table.HeaderCell>
											<Table.HeaderCell>chik CT Value</Table.HeaderCell>
											<Table.HeaderCell>zikV CT Value</Table.HeaderCell>
											<Table.HeaderCell>Neg Ctrl</Table.HeaderCell>
											<Table.HeaderCell>den Ctrl</Table.HeaderCell>
											<Table.HeaderCell>chik Ctrl</Table.HeaderCell>
											<Table.HeaderCell>zik Ctrl</Table.HeaderCell>
											<Table.HeaderCell>Results</Table.HeaderCell>
											<Table.HeaderCell>Serotype</Table.HeaderCell>
											<Table.HeaderCell />
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{matchSorter(data, term, {
											keys: ['pool', 'superpool']
										}).map(item => {
											return (
												<Table.Row key={item.pool}>
													<Table.Cell>
														<Switch
															checked={_.includes(checked, item.pool)}
															onChange={() => {
																setChecked(_.xor(checked, [item.pool]));
															}}
														/>
													</Table.Cell>
													<Table.Cell>
														<Header as="h5">
															<Header.Content>{item.pool}</Header.Content>
														</Header>
													</Table.Cell>
													<Table.Cell>{item.well}</Table.Cell>
													<Table.Cell>{item.den}</Table.Cell>
													<Table.Cell>{item.chik}</Table.Cell>
													<Table.Cell>{item.zik}</Table.Cell>
													<Table.Cell>{item.negControl}</Table.Cell>
													<Table.Cell>{item.denControl}</Table.Cell>
													<Table.Cell>{item.chikControl}</Table.Cell>
													<Table.Cell>{item.zikControl}</Table.Cell>
													<Table.Cell
														positive={_.includes(item.result, 'Positive')}
														negative={_.includes(item.result, 'Negative')}
													>
														{item.result}
													</Table.Cell>
													<Table.Cell>{item.dengueSerotype}</Table.Cell>
													<Table.Cell>
														<Button
															size="mini"
															icon="pencil"
															compact
															circular
															positive
															onClick={() => {
																setVisible(true);
																setChecked([item.pool]);
																formik.setFieldValue('den', item.den || '');
																formik.setFieldValue('chik', item.chik || '');
																formik.setFieldValue('zik', item.zik || '');
																formik.setFieldValue('result', item.result || '');
																formik.setFieldValue('negControl', item.negControl || '');
																formik.setFieldValue('denControl', item.denControl || '');
																formik.setFieldValue('chikControl', item.chikControl || '');
																formik.setFieldValue('zikControl', item.zikControl || '');
																formik.setFieldValue('denThreshold', item.denThreshold || '');
																formik.setFieldValue('chikThreshold', item.chikThreshold || '');
																formik.setFieldValue('zikThreshold', item.zikThreshold || '');
																formik.setFieldValue('dengueSerotype', item.dengueSerotype || '');
																formik.setFieldValue('positive_control_d1', item.positive_control_d1 || '');
																formik.setFieldValue('positive_control_d2', item.positive_control_d2 || '');
																formik.setFieldValue('positive_control_d3', item.positive_control_d3 || '');
																formik.setFieldValue('positive_control_d4', item.positive_control_d4 || '');
																formik.setFieldValue('mapa_date', item.mapa_date || '');
																formik.setFieldValue('mdsa_date', item.mdsa_date || '');
																formik.setFieldValue('denv_mdsa_ct_value', item.denv_mdsa_ct_value || '');
															}}
														/>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
									<Table.Footer fullWidth>
										<Table.Row>
											<Table.HeaderCell>
												<Switch
													checked={checked.length === data.length}
													onChange={e => {
														if (e.target.checked) {
															setChecked(
																_.map(
																	matchSorter(data, term, {
																		keys: ['pool', 'superpool']
																	}),
																	item => {
																		return item.pool;
																	}
																)
															);
														} else {
															setChecked([]);
														}
													}}
												/>
											</Table.HeaderCell>
											<Table.HeaderCell colSpan="12" textAlign="left">
												<Header size="tiny">
													<Header.Content>
														Showing{' '}
														{
															matchSorter(data, term, {
																keys: ['pool', 'superpool']
															}).length
														}{' '}
														of {data.length}
													</Header.Content>
												</Header>
											</Table.HeaderCell>
										</Table.Row>
									</Table.Footer>
								</Table>
							</>
						) : (
							<div className="pre">
								<div className="pre-header x-small p-10 strong">Results</div>
								<div>
									{loading ? (
										<div className="pre-loading x-small p-10 strong">Loading</div>
									) : (
										<div className="x-small p-10 strong">
											{getAdmin(role)
												? 'Enter plate number on the input field to load results'
												: 'You have no privileges to access this section'}
										</div>
									)}
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="placeholder full-width" />
									<div className="divider" />
									<div className="placeholder" />
									<div className="placeholder full-width" />
								</div>
							</div>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	);
}

export default Report;
