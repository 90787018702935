import React from 'react';
import { Grid, Header, Image } from 'semantic-ui-react';
import { useTitle } from 'react-use';
import Source from '../../../../assets/task.svg';

function OrdersBanner(props) {
	useTitle(`Laboratory | ${props.title}`);

	return (
		<Grid stackable verticalAlign="middle" padded="vertically">
			<Grid.Row columns={1} color="blue" style={{ minHeight: 400 }}>
				<Grid.Column>
					<Grid
						container
						stackable
						style={{ paddingTop: 80, paddingBottom: 80 }}
					>
						<Grid.Row columns={2} verticalAlign="middle">
							<Grid.Column>
								<Image centered src={Source} height={200} />
							</Grid.Column>
							<Grid.Column>
								<Header as="h1" inverted>
									{props.title}
								</Header>
								<p className="quiet">{props.subtitle}</p>
								{props.children}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}

export default OrdersBanner;
