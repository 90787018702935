import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Dimmer, Loader, Table } from 'semantic-ui-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useUnmount } from 'react-use';
import { addToast } from '../../../../../../store/actions/ToastActions';
import { fetchPools } from '../../../../../../store/actions/CustomerActions';

function OrderPools() {
	const dispatch = useDispatch();
	const email = useSelector(state => state.auth.email);
	const orders = useSelector(state => state.customer.orders);
	const uuid = useSelector(state => state.customer.uuid);
	const pools = useSelector(state => state.customer.pools);
	const pools_loading = useSelector(state => state.customer.pools_loading);
	const [order, setOrder] = useState(null);
	const [active, setActive] = useState(null);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const formik = useFormik({
		initialValues: {
			maintenance: '',
			collection: '',
			sample: '',
			pool: '',
			box: '',
			organization: '',
			email: ''
		},
		validationSchema: Yup.object().shape({
			pool: Yup.string()
				.min(12)
				.max(12)
				.required('This field is required')
		}),
		onSubmit: async (values, { setSubmitting, setFieldValue }) => {
			await axios
				.put('/api/order-pool', values)
				.then(res => {
					dispatch(
						addToast({
							type: 'success',
							message: res.data.message
						})
					);
					setFieldValue('pool', '');
					setSubmitting(false);
					setData(_.concat(data, values));
				})
				.catch(error => {
					setSubmitting(false);
					dispatch(
						addToast({
							type: 'error',
							message: error.response.data.message
						})
					);
				});
		}
	});

	useEffect(() => {
		const data = _.filter(orders, e => {
			return e.uuid === uuid;
		});
		setOrder(data[0]);
	}, [uuid, orders]);

	const deletePool = pool => {
		setLoading(true);

		axios
			.delete(`/api/delete-order-pool/${pool}`)
			.then(res => {
				const filter = _.filter(data, e => {
					return e.pool !== pool;
				});

				setData(filter);

				dispatch(
					addToast({
						type: 'success',
						message: res.data.message
					})
				);
				setActive(null);
				setLoading(false);
			})
			.catch(err => {
				dispatch(
					addToast({
						type: 'error',
						message: err.response.data.message
					})
				);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (order) {
			formik.setFieldValue('maintenance', order.uuid);
			formik.setFieldValue('box', order.order_number);
			formik.setFieldValue('sample', order.customer);
			formik.setFieldValue('collection', moment(order.shipment).format('M/D/YYYY'));
			formik.setFieldValue('organization', order.customer);
			formik.setFieldValue('email', email);
			dispatch(fetchPools(order.uuid));
		}
	}, [order]);

	useEffect(() => {
		setData(pools);
	}, [pools]);

	useUnmount(() => {
		formik.resetForm();
	});

	return (
		<div>
			<Form onSubmit={formik.handleSubmit} noValidate>
				<Form.Field
					required
					name="pool"
					value={formik.values.pool}
					control={Input}
					label="Pool"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder="12 digit number..."
					error={formik.errors.pool && formik.touched.pool && formik.errors.pool}
				/>
				<Form.Field
					positive
					control={Button}
					content="Add Pool"
					loading={formik.isSubmitting}
					disabled={formik.isSubmitting}
				/>
			</Form>
			<div className="mt-10">
				{pools_loading ? (
					<div>
						<Dimmer active inverted>
							<Loader inverted>Loading Pools</Loader>
						</Dimmer>
					</div>
				) : (
					<>
						{data.length > 0 ? (
							<Table definition celled striped ize="small" compact="very">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell />
										<Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(data, e => {
										return (
											<Table.Row key={e.pool}>
												<Table.Cell>{e.pool}</Table.Cell>
												<Table.Cell textAlign="center">
													<Button
														positive
														circular
														size="mini"
														icon="trash alternate"
														loading={loading && active && active === e.pool}
														onClick={b => {
															setActive(e.pool);
															b.preventDefault();
															deletePool(e.pool);
														}}
													/>
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						) : (
							<p className="text-center small quiet">No Pools Available</p>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default OrderPools;
