import {
	PLATE_MODAL_SHOW,
	PLATE_MODAL_HIDE,
	FINISH_PLATE,
	PLATE_CHANGE_VALUE,
	CHANGE_PLATE_NUMBER,
	PLATE_LOAD_ERROR,
	PLATE_LOAD_LOADING,
	PLATE_LOAD_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	plate: '',
	loading: false,
	error: '',
	visible: false,
	index: null,
	value: '',
	wells: [
		{
			index: 0,
			well: 'A1',
			pool: '',
			disable: false
		},
		{
			index: 1,
			well: 'A2',
			pool: '',
			disable: false
		},
		{
			index: 2,
			well: 'A3',
			pool: '',
			disable: false
		},
		{
			index: 3,
			well: 'A4',
			pool: '',
			disable: false
		},
		{
			index: 4,
			well: 'A5',
			pool: '',
			disable: false
		},
		{
			index: 5,
			well: 'A6',
			pool: '',
			disable: false
		},
		{
			index: 6,
			well: 'A7',
			pool: '',
			disable: false
		},
		{
			index: 7,
			well: 'A8',
			pool: '',
			disable: false
		},
		{
			index: 8,
			well: 'A9',
			pool: '',
			disable: false
		},
		{
			index: 9,
			well: 'A10',
			pool: '',
			disable: false
		},
		{
			index: 10,
			well: 'A11',
			pool: '',
			disable: false
		},
		{
			index: 11,
			well: 'A12',
			pool: '',
			disable: false
		},
		{
			index: 12,
			well: 'B1',
			pool: '',
			disable: false
		},
		{
			index: 13,
			well: 'B2',
			pool: '',
			disable: false
		},
		{
			index: 14,
			well: 'B3',
			pool: '',
			disable: false
		},
		{
			index: 15,
			well: 'B4',
			pool: '',
			disable: false
		},
		{
			index: 16,
			well: 'B5',
			pool: '',
			disable: false
		},
		{
			index: 17,
			well: 'B6',
			pool: '',
			disable: false
		},
		{
			index: 18,
			well: 'B7',
			pool: '',
			disable: false
		},
		{
			index: 19,
			well: 'B8',
			status: false,
			pool: '',
			disable: false
		},
		{
			index: 20,
			well: 'B9',
			pool: '',
			disable: false
		},
		{
			index: 21,
			well: 'B10',
			pool: '',
			disable: false
		},
		{
			index: 22,
			well: 'B11',
			pool: '',
			disable: false
		},
		{
			index: 23,
			well: 'B12',
			pool: '',
			disable: false
		},
		{
			index: 24,
			well: 'C1',
			pool: '',
			disable: false
		},
		{
			index: 25,
			well: 'C2',
			pool: '',
			disable: false
		},
		{
			index: 26,
			well: 'C3',
			pool: '',
			disable: false
		},
		{
			index: 27,
			well: 'C4',
			pool: '',
			disable: false
		},
		{
			index: 28,
			well: 'C5',
			pool: '',
			disable: false
		},
		{
			index: 29,
			well: 'C6',
			pool: '',
			disable: false
		},
		{
			index: 30,
			well: 'C7',
			pool: '',
			disable: false
		},
		{
			index: 31,
			well: 'C8',
			pool: '',
			disable: false
		},
		{
			index: 32,
			well: 'C9',
			pool: '',
			disable: false
		},
		{
			index: 33,
			well: 'C10',
			pool: '',
			disable: false
		},
		{
			index: 34,
			well: 'C11',
			pool: '',
			disable: false
		},
		{
			index: 35,
			well: 'C12',
			pool: '',
			disable: false
		},
		{
			index: 36,
			well: 'D1',
			pool: '',
			disable: false
		},
		{
			index: 37,
			well: 'D2',
			pool: '',
			disable: false
		},
		{
			index: 38,
			well: 'D3',
			pool: '',
			disable: false
		},
		{
			index: 39,
			well: 'D4',
			pool: '',
			disable: false
		},
		{
			index: 40,
			well: 'D5',
			pool: '',
			disable: false
		},
		{
			index: 41,
			well: 'D6',
			pool: '',
			disable: false
		},
		{
			index: 42,
			well: 'D7',
			pool: '',
			disable: false
		},
		{
			index: 43,
			well: 'D8',
			pool: '',
			disable: false
		},
		{
			index: 44,
			well: 'D9',
			pool: '',
			disable: false
		},
		{
			index: 45,
			well: 'D10',
			pool: '',
			disable: false
		},
		{
			index: 46,
			well: 'D11',
			pool: '',
			disable: false
		},
		{
			index: 47,
			well: 'D12',
			pool: '',
			disable: false
		},
		{
			index: 48,
			well: 'E1',
			pool: '',
			disable: false
		},
		{
			index: 49,
			well: 'E2',
			pool: '',
			disable: false
		},
		{
			index: 50,
			well: 'E3',
			pool: '',
			disable: false
		},
		{
			index: 51,
			well: 'E4',
			pool: '',
			disable: false
		},
		{
			index: 52,
			well: 'E5',
			pool: '',
			disable: false
		},
		{
			index: 53,
			well: 'E6',
			pool: '',
			disable: false
		},
		{
			index: 54,
			well: 'E7',
			pool: '',
			disable: false
		},
		{
			index: 55,
			well: 'E8',
			pool: '',
			disable: false
		},
		{
			index: 56,
			well: 'E9',
			pool: '',
			disable: false
		},
		{
			index: 57,
			well: 'E10',
			pool: '',
			disable: false
		},
		{
			index: 58,
			well: 'E11',
			pool: '',
			disable: false
		},
		{
			index: 59,
			well: 'E12',
			pool: 'Negative',
			disable: true
		},
		{
			index: 60,
			well: 'F1',
			pool: '',
			disable: false
		},
		{
			index: 61,
			well: 'F2',
			pool: '',
			disable: false
		},
		{
			index: 62,
			well: 'F3',
			pool: '',
			disable: false
		},
		{
			index: 63,
			well: 'F4',
			pool: '',
			disable: false
		},
		{
			index: 64,
			well: 'F5',
			pool: '',
			disable: false
		},
		{
			index: 65,
			well: 'F6',
			pool: '',
			disable: false
		},
		{
			index: 66,
			well: 'F7',
			pool: '',
			disable: false
		},
		{
			index: 67,
			well: 'F8',
			pool: '',
			disable: false
		},
		{
			index: 68,
			well: 'F9',
			pool: '',
			disable: false
		},
		{
			index: 69,
			well: 'F10',
			pool: '',
			disable: false
		},
		{
			index: 70,
			well: 'F11',
			pool: '',
			disable: false
		},
		{
			index: 71,
			well: 'F12',
			pool: 'DenV PCR',
			disable: true
		},
		{
			index: 72,
			well: 'G1',
			pool: '',
			disable: false
		},
		{
			index: 73,
			well: 'G2',
			pool: '',
			disable: false
		},
		{
			index: 74,
			well: 'G3',
			pool: '',
			disable: false
		},
		{
			index: 75,
			well: 'G4',
			pool: '',
			disable: false
		},
		{
			index: 76,
			well: 'G5',
			pool: '',
			disable: false
		},
		{
			index: 77,
			well: 'G6',
			pool: '',
			disable: false
		},
		{
			index: 78,
			well: 'G7',
			pool: '',
			disable: false
		},
		{
			index: 79,
			well: 'G8',
			pool: '',
			disable: false
		},
		{
			index: 80,
			well: 'G9',
			pool: '',
			disable: false
		},
		{
			index: 81,
			well: 'G10',
			pool: '',
			disable: false
		},
		{
			index: 82,
			well: 'G11',
			pool: '',
			disable: false
		},
		{
			index: 83,
			well: 'G12',
			pool: 'ChikV PCR',
			disable: true
		},
		{
			index: 84,
			well: 'H1',
			pool: '',
			disable: false
		},
		{
			index: 85,
			well: 'H2',
			pool: '',
			disable: false
		},
		{
			index: 86,
			well: 'H3',
			pool: '',
			disable: false
		},
		{
			index: 87,
			well: 'H4',
			pool: '',
			disable: false
		},
		{
			index: 88,
			well: 'H5',
			pool: '',
			disable: false
		},
		{
			index: 89,
			well: 'H6',
			pool: '',
			disable: false
		},
		{
			index: 90,
			well: 'H7',
			pool: '',
			disable: false
		},
		{
			index: 91,
			well: 'H8',
			pool: '',
			disable: false
		},
		{
			index: 92,
			well: 'H9',
			pool: '',
			disable: false
		},
		{
			index: 93,
			well: 'H10',
			pool: '',
			disable: false
		},
		{
			index: 94,
			well: 'H11',
			pool: '',
			disable: false
		},
		{
			index: 95,
			well: 'H12',
			pool: 'ZikV PCR',
			disable: true
		}
	],
	plateLoading: false,
	plateError: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PLATE_MODAL_SHOW:
			return {
				...state,
				visible: true,
				index: action.index,
				value: action.value
			};
		case PLATE_MODAL_HIDE:
			return {
				...state,
				visible: false,
				index: null,
				value: null
			};
		case PLATE_CHANGE_VALUE:
			return {
				...state,
				value: action.value,
				wells: action.data
			};
		case CHANGE_PLATE_NUMBER:
			return {
				...state,
				plate: action.value,
				plateLoading: true
			};
		case PLATE_LOAD_LOADING:
			return {
				...state,
				plateLoading: true
			};
		case PLATE_LOAD_SUCCESS:
			return {
				...state,
				plateLoading: false,
				wells: action.payload
			};
		case PLATE_LOAD_ERROR:
			return {
				plateLoading: false,
				plateError: action.error
			};
		case FINISH_PLATE:
			return INITIAL_STATE;

		default:
			return state;
	}
};
