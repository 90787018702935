import {
	LOAD_INBOX_ERROR,
	LOAD_INBOX_LOADING,
	LOAD_INBOX_SUCCESS,
	SHOW_INBOX_MODAL,
	HIDE_INBOX_MODAL,
	HANDLE_CHANGE_INBOX,
	GET_LAB_USERS,
	SET_EX_TECH,
	SET_ID_TECH,
	INBOX_SET_ACTIVE,
	INBOX_SET_VALUE,
	LOAD_RECORD_POOLS_ERROR,
	LOAD_RECORD_POOLS_LOADING,
	LOAD_RECORD_POOLS_SUCCESS,
	CHECK_VALUE_CLOSE,
	CHECK_VALUE_OPEN,
	CONFIRM_NOT_FOUND,
	CONFIRM_DUPLICATED
} from '../actions/types';

const INITIAL_STATE = {
	confirm: false,
	users: [],
	idTech: '',
	exTech: '',
	data: [],
	loading: true,
	error: '',
	date: new Date().toLocaleString(),
	visible: false,
	record: {},
	value: '',
	positive: 'Inbox',
	loadingPools: false,
	pools: [],
	poolsError: '',
	notFound: false,
	duplicated: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOAD_INBOX_LOADING:
			return {
				...state,
				loading: true,
				error: ''
			};
		case LOAD_INBOX_SUCCESS:
			return {
				...state,
				data: action.data,
				date: new Date().toLocaleString(),
				loading: false
			};
		case LOAD_INBOX_ERROR:
			return {
				...state,
				loading: false,
				date: new Date().toLocaleString(),
				error: action.error
			};
		case SHOW_INBOX_MODAL:
			return {
				...state,
				visible: true,
				record: action.payload
			};
		case HIDE_INBOX_MODAL:
			return {
				...state,
				visible: false,
				record: {}
			};
		case HANDLE_CHANGE_INBOX:
			return {
				...state,
				form: {
					...state.form,
					[action.payload.prop]: action.payload.value
				}
			};
		case GET_LAB_USERS:
			return {
				...state,
				users: action.payload
			};
		case SET_EX_TECH:
			return {
				...state,
				exTech: action.payload
			};
		case SET_ID_TECH:
			return {
				...state,
				idTech: action.payload
			};
		case INBOX_SET_ACTIVE:
			return {
				...state,
				positive: action.payload
			};
		case INBOX_SET_VALUE:
			return {
				...state,
				value: action.payload
			};
		case LOAD_RECORD_POOLS_LOADING:
			return {
				...state,
				loadingPools: true,
				error: ''
			};
		case LOAD_RECORD_POOLS_SUCCESS:
			return {
				...state,
				loadingPools: false,
				pools: action.payload
			};
		case LOAD_RECORD_POOLS_ERROR:
			return {
				...state,
				loadingPools: false,
				poolsError: action.payload
			};
		case CHECK_VALUE_OPEN:
			return {
				...state,
				confirm: true
			};
		case CHECK_VALUE_CLOSE:
			return {
				...state,
				confirm: false
			};
		case CONFIRM_NOT_FOUND:
			return {
				...state,
				notFound: action.payload
			};
		case CONFIRM_DUPLICATED:
			return {
				...state,
				duplicated: action.payload
			};
		default:
			return state;
	}
};
