import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import FadeIn from 'react-fade-in';
import _ from 'lodash';
import {
	clearPool,
	fetchPositive,
	hidePositive,
	setPool
} from '../../../../store/actions/PositiveActions';
import { changePlateNumber } from '../../../../store/actions/PlateActions';
import { setResults } from '../../../../store/actions/ArbovirusActions';

let map = null;

class PositiveSamples extends Component {
	componentDidMount() {
		map = new mapboxgl.Map({
			container: this.container,
			style: 'mapbox://styles/prvcu/ck4d5mjyo0ul01cqme4fx5jii',
			center: [this.props.longitude, this.props.latitude],
			zoom: this.props.zoom,
			attributionControl: false,
			accessToken:
				'pk.eyJ1IjoicHJ2Y3UiLCJhIjoiY2piODQ3ZWRwNDZucDJ3c2hvY3AwZ25jdCJ9.U3hrNWPSaExsQhRYjUGTQQ'
		});

		map.on('load', () => {
			map.addSource('points', {
				type: 'geojson',
				data: this.props.data
			});

			this.props.fetchPositive(map);
		});
	}

	createPopUp = marker => {
		const popups = document.getElementsByClassName('mapboxgl-popup');
		if (popups[0]) popups[0].remove();

		map.flyTo({
			center: marker.geometry.coordinates,
			zoom: 17
		});

		const div = document.createElement('div');
		const content = document.createElement('content');
		const h3 = document.createElement('h3');
		const h4 = document.createElement('h4');
		const a = document.createElement('a');
		const a2 = document.createElement('a');
		h3.innerText = `${marker.properties.name}`;
		h4.innerText = `${marker.properties.count} positive sample results for this trap`;
		a.innerHTML = `Pools`;
		a2.innerHTML = `Close`;
		a.className = 'map-button';
		a2.className = 'map-button';
		div.appendChild(h3);
		div.appendChild(content);
		content.appendChild(h4);
		content.appendChild(a);
		content.appendChild(a2);
		content.classList.add('popupContent');

		a.addEventListener('click', () => {
			const sidebar = document.getElementById('sidebar');
			sidebar.classList.remove('map-popup-collapsed');

			map.easeTo({
				padding: {
					left: 275
				},
				pitch: 60,
				duration: 1000
			});
		});

		a2.addEventListener('click', () => {
			const sidebar = document.getElementById('sidebar');
			sidebar.classList.add('map-popup-collapsed');
			map.easeTo({
				padding: {
					left: 0
				},
				pitch: 0,
				duration: 1000
			});
			const popup = document.getElementsByClassName('mapboxgl-popup');
			if (popup[0]) popup[0].remove();
			const active = document.getElementsByClassName('list-active-item');
			active[0].classList.remove('list-active-item');
		});

		new mapboxgl.Popup({ closeOnClick: false, offset: 15 })
			.setLngLat(marker.geometry.coordinates)
			.setDOMContent(div)
			.addTo(map);

		const activeItem = document.getElementsByClassName('list-active-item');
		if (activeItem[0]) {
			activeItem[0].classList.remove('list-active-item');
		}

		const listing = document.getElementById(
			`listing-${marker.properties.name}`
		);
		listing.classList.add('list-active-item');
	};

	exportToCSV = () => {
		const dataset = [];

		this.props.exportData.features.forEach(e => {
			dataset.push(e.properties);
		});

		const ws = XLSX.utils.json_to_sheet(dataset);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], {
			type:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		FileSaver.saveAs(data, 'report.xlsx');
	};

	render() {
		return (
			<div className="map-container flex fill-black">
				<div className="map-column w-2/3 h-500">
					<div
						ref={e => (this.container = e)}
						className="p-10"
						style={{ height: '100%' }}
					>
						<div id="sidebar" className="map-popup map-popup-collapsed">
							<div className="map-popup-content fill-black small quiet">
								{this.props.properties && (
									<>
										<div className="map-popup-header fill-black x-small p-10">
											<div className="map-popup-title">
												{this.props.properties.name}
											</div>
											<div className="map-popup-subtitle">
												{this.props.properties.count}
											</div>
										</div>
										<div className="map-popup-content-inside x-small quiet p-10">
											<>
												{_.map(this.props.properties.pools, e => {
													return (
														<div key={e.pool}>
															<div className="map-popup-date quiet">
																{new Date(e.date).toLocaleDateString()}
															</div>
															<div
																key={e.pool}
																className="fill-grey mb-10 map-popup-content-inside-balloon"
															>
																<div className="strong">
																	<span role="img" aria-label="Mosquito">
																		🦟
																	</span>{' '}
																	{e.result}
																</div>
																<div>Pool Number: {e.pool}</div>
																<div>Plate {e.plate}</div>
																<div>
																	<button
																		tabIndex={-1}
																		type="button"
																		className="map-popup-small-button"
																		onClick={() => {
																			this.props.changePlateNumber(e.plate);
																			this.props.setResults();
																			this.props.hidePositive();
																		}}
																	>
																		Results
																	</button>
																</div>
															</div>
														</div>
													);
												})}
											</>
										</div>
										<div className="map-popup-content-button">
											<button
												type="button"
												tabIndex={-1}
												onClick={() => {
													const sidebar = document.getElementById('sidebar');
													sidebar.classList.add('map-popup-collapsed');

													map.easeTo({
														padding: {
															left: 0
														},
														pitch: 0,
														duration: 1000
													});
												}}
											>
												Close
											</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="map-column map-sidebar w-1/3 mh-500">
					<div className="map-title quiet p-10">
						<div>Samples Locations</div>
						<p className="x-small fill-black quiet">
							Positive results to Dengue (DENV), Chikungunya (CHIKV) or Zika
							(ZIKV) viruses
						</p>
					</div>
					<div className="map-list" style={{ flex: 1 }}>
						{this.props.data && (
							<FadeIn>
								{this.props.data.features.map(e => {
									return (
										<div
											aria-hidden="true"
											role="button"
											key={e.properties.name}
											onClick={() => {
												this.props.setPool(e);
												this.createPopUp(e);
											}}
											className="sidebar-item"
											id={`listing-${e.properties.name}`}
										>
											<div className="truncate" style={{ color: '#56b881' }}>
												<strong>{e.properties.name}</strong>
											</div>
											<div className="truncate ">
												{e.properties.count} pool with positive result
											</div>
										</div>
									);
								})}
							</FadeIn>
						)}
					</div>
					<div
						className="map-subtitle italic text-right p-10"
						style={{ fontSize: 10 }}
					>
						Updated @ {new Date().toLocaleTimeString()}
					</div>
					<div className="map-buttons">
						<button type="button" onClick={this.exportToCSV}>
							Export
						</button>
						<button
							type="button"
							onClick={() => {
								this.props.hidePositive();
							}}
						>
							Close
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		longitude: state.positive.longitude,
		latitude: state.positive.latitude,
		zoom: state.positive.zoom,
		data: state.positive.data,
		exportData: state.positive.exportData,
		loading: state.positive.loading,
		pool: state.positive.pool,
		properties: state.positive.properties
	};
};

export default connect(
	mapStateToProps,
	{
		fetchPositive,
		setPool,
		clearPool,
		hidePositive,
		changePlateNumber,
		setResults
	}
)(PositiveSamples);
