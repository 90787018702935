import React from 'react';

const Badge = props => {
    return (
        <div role="button" tabIndex={0} className={`badge ${props.color}`} style={props.style} onClick={props.onClick}>
            {props.content || props.children}
        </div>
    );
};

export default Badge;
