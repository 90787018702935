import {
	HIDE_BALLOON,
	QUICK_FACTS_ERROR,
	QUICK_FACTS_LOADING,
	QUICK_FACTS_SUCCESS,
	SHOW_BALLOON
} from './types';

export const fetchQuickFacts = () => {
	return dispatch => {
		dispatch({
			type: QUICK_FACTS_LOADING
		});

		fetch(`/api/quick-facts`)
			.then(response => response.json())
			.then(data => {
				dispatch({
					type: QUICK_FACTS_SUCCESS,
					payload: data
				});
			})
			.catch(error => {
				dispatch({
					type: QUICK_FACTS_ERROR,
					payload: error.message || `Unexpected Error!!!`
				});
			});
	};
};

export const showBalloon = () => {
	return {
		type: SHOW_BALLOON
	};
};

export const hideBalloon = () => {
	return {
		type: HIDE_BALLOON
	};
};
