import {
	LOAD_RESULTS_ERROR,
	LOAD_RESULTS_LOADING,
	LOAD_RESULTS_SUCCESS,
	RESULTS_CHANGE_VALUE,
	RESULTS_SET_KEY,
	SUBMIT_RESULTS_ERROR,
	SUBMIT_RESULTS_LOADING,
	SUBMIT_RESULTS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
	data: [],
	error: '',
	loading: false,
	plate: '',
	dataIndex: '',
	den: '',
	chik: '',
	zik: '',
	result: '',
	dengueSerotype: '',
	resultLoading: false,
	negControl: '',
	denControl: '',
	chikControl: '',
	zikControl: '',
	denThreshold: '',
	chikThreshold: '',
	zikThreshold: '',
	positive_control_d1: '',
	positive_control_d2: '',
	positive_control_d3: '',
	positive_control_d4: '',
	mapa_date: '',
	mdsa_date: ''
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOAD_RESULTS_LOADING:
			return {
				...state,
				loading: true,
				error: ''
			};
		case LOAD_RESULTS_SUCCESS:
			return {
				...state,
				data: action.payload,
				negControl:
					action.payload.length > 0 ? action.payload[0].negControl : '',
				denControl:
					action.payload.length > 0 ? action.payload[0].denControl : '',
				chikControl:
					action.payload.length > 0 ? action.payload[0].chikControl : '',
				zikControl:
					action.payload.length > 0 ? action.payload[0].zikControl : '',
				denThreshold:
					action.payload.length > 0 ? action.payload[0].denThreshold : '',
				chikThreshold:
					action.payload.length > 0 ? action.payload[0].chikThreshold : '',
				zikThreshold:
					action.payload.length > 0 ? action.payload[0].zikThreshold : '',
				positive_control_d1:
					action.payload.length > 0
						? action.payload[0].positive_control_d1
						: '',
				positive_control_d2:
					action.payload.length > 0
						? action.payload[0].positive_control_d2
						: '',
				positive_control_d3:
					action.payload.length > 0
						? action.payload[0].positive_control_d3
						: '',
				positive_control_d4:
					action.payload.length > 0
						? action.payload[0].positive_control_d4
						: '',
				mapa_date: action.payload.length > 0 ? action.payload[0].mapa_date : '',
				mdsa_date: action.payload.length > 0 ? action.payload[0].mdsa_date : '',
				loading: false
			};
		case LOAD_RESULTS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case RESULTS_CHANGE_VALUE:
			return {
				...state,
				[action.payload.state]: action.payload.value
			};
		case RESULTS_SET_KEY:
			return {
				...state,
				dataIndex: action.payload,
				den: action.den,
				chik: action.chik,
				zik: action.zik,
				result: action.result,
				dengueSerotype: action.dengueSerotype,
				negControl: action.negControl,
				denControl: action.denControl,
				chikControl: action.chikControl,
				zikControl: action.zikControl,
				denThreshold: action.denThreshold,
				chikThreshold: action.chikThreshold,
				zikThreshold: action.zikThreshold,
				positive_control_d1: action.positive_control_d1,
				positive_control_d2: action.positive_control_d2,
				positive_control_d3: action.positive_control_d3,
				positive_control_d4: action.positive_control_d4,
				mapa_date: action.mapa_date,
				mdsa_date: action.mdsa_date
			};
		case SUBMIT_RESULTS_LOADING:
			return {
				...state,
				resultLoading: true,
				error: ''
			};
		case SUBMIT_RESULTS_SUCCESS:
			return {
				...state,
				error: '',
				resultLoading: false
			};
		case SUBMIT_RESULTS_ERROR:
			return {
				...state,
				resultLoading: false,
				error: action.error
			};
		default:
			return state;
	}
};
