import mapboxgl from 'mapbox-gl';
import bbox from '@turf/bbox';
import _ from 'lodash';
import {
	POSITIVE_RESULTS_CLEAR,
	POSITIVE_RESULTS_ERROR,
	POSITIVE_RESULTS_HIDE,
	POSITIVE_RESULTS_LOADING,
	POSITIVE_RESULTS_POOL,
	POSITIVE_RESULTS_SHOW,
	POSITIVE_RESULTS_SUCCESS
} from './types';

export const showPositive = () => {
	return {
		type: POSITIVE_RESULTS_SHOW
	};
};

export const hidePositive = () => {
	return {
		type: POSITIVE_RESULTS_HIDE
	};
};

export const fetchPositive = map => {
	return dispatch => {
		dispatch({
			type: POSITIVE_RESULTS_LOADING
		});

		fetch(`/api/positive`)
			.then(response => response.json())
			.then(data => {
				const features = _(data.features)
					.groupBy('properties.structureid')
					.map((items, name) => {
						const pools = [];
						items.forEach(e => {
							pools.push(e.properties);
						});

						return {
							type: 'Feature',
							properties: {
								name,
								count: items.length,
								pools
							},
							geometry: items[0].geometry
						};
					})
					.value();

				const json = {
					type: 'FeatureCollection',
					features
				};

				dispatch({
					type: POSITIVE_RESULTS_SUCCESS,
					payload: json,
					exportData: data
				});

				json.features.forEach(marker => {
					const el = document.createElement('div');
					el.innerHTML = marker.properties.count;
					el.id = `marker-${marker.properties.name}`;
					el.className = 'map-marker';

					new mapboxgl.Marker(el, { anchor: 'center' })
						.setLngLat(marker.geometry.coordinates)
						.addTo(map);

					el.addEventListener('click', () => {
						const popups = document.getElementsByClassName('mapboxgl-popup');
						if (popups[0]) popups[0].remove();

						dispatch({
							type: POSITIVE_RESULTS_POOL,
							payload: marker.properties.name,
							properties: marker.properties
						});

						map.flyTo({
							center: marker.geometry.coordinates,
							zoom: 17
						});

						const div = document.createElement('div');
						const content = document.createElement('content');
						const h3 = document.createElement('h3');
						const h4 = document.createElement('h4');
						const a = document.createElement('a');
						const a2 = document.createElement('a');
						h3.innerText = `${marker.properties.name}`;
						h4.innerText = `${marker.properties.count} positive sample results for this trap`;
						a.innerHTML = `Pools`;
						a2.innerHTML = `Close`;
						a.className = 'map-button';
						a2.className = 'map-button';
						div.appendChild(h3);
						div.appendChild(content);
						content.appendChild(h4);
						content.appendChild(a);
						content.appendChild(a2);
						content.classList.add('popupContent');

						a.addEventListener('click', () => {
							const sidebar = document.getElementById('sidebar');
							sidebar.classList.remove('map-popup-collapsed');

							map.easeTo({
								padding: {
									left: 275
								},
								pitch: 60,
								duration: 1000
							});
						});

						a2.addEventListener('click', () => {
							const sidebar = document.getElementById('sidebar');
							sidebar.classList.add('map-popup-collapsed');

							const active = document.getElementsByClassName(
								'list-active-item'
							);
							active[0].classList.remove('list-active-item');

							map.easeTo({
								padding: {
									left: 0
								},
								pitch: 0,
								duration: 1000
							});
							const popup = document.getElementsByClassName('mapboxgl-popup');
							if (popup[0]) popup[0].remove();
						});

						new mapboxgl.Popup({ closeOnClick: false, offset: 15 })
							.setLngLat(marker.geometry.coordinates)
							.setDOMContent(div)
							.addTo(map);

						const activeItem = document.getElementsByClassName(
							'list-active-item'
						);
						if (activeItem[0]) {
							activeItem[0].classList.remove('list-active-item');
						}

						const listing = document.getElementById(
							`listing-${marker.properties.name}`
						);
						listing.classList.add('list-active-item');
					});
				});

				const bounds = bbox(json);
				map.fitBounds(bounds, { padding: 100 });
			})
			.catch(error => {
				dispatch({
					type: POSITIVE_RESULTS_ERROR,
					payload: error.message
				});
			});
	};
};

export const setPool = marker => {
	return dispatch => {
		dispatch({
			type: POSITIVE_RESULTS_POOL,
			payload: marker.properties.pool,
			properties: marker.properties
		});
	};
};

export const clearPool = () => {
	return {
		type: POSITIVE_RESULTS_CLEAR
	};
};
