import {
	LINE_CHART_ERROR,
	LINE_CHART_LOADING,
	LINE_CHART_SUCCESS,
	SIDEBAR_FETCH_POOLS_ERROR,
	SIDEBAR_FETCH_POOLS_LOADING,
	SIDEBAR_FETCH_POOLS_SUCCESS,
	SIDEBAR_FETCH_SUPERPOOLS_ERROR,
	SIDEBAR_FETCH_SUPERPOOLS_LOADING,
	SIDEBAR_FETCH_SUPERPOOLS_SUCCESS,
	WEEK_CHART_LOADING,
	WEEK_CHART_SUCCESS,
	WEEK_CHART_ERROR
} from '../actions/types';

const INITIAL_STATE = {
	lineData: [],
	pools: [],
	superPools: [],
	loading: false,
	error: '',
	date: new Date().toLocaleTimeString(),
	weeklyData: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LINE_CHART_LOADING:
			return {
				...state,
				loading: true
			};
		case LINE_CHART_SUCCESS:
			return {
				...state,
				loading: false,
				lineData: action.payload,
				date: new Date().toLocaleTimeString()
			};
		case LINE_CHART_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				lineData: []
			};
		case WEEK_CHART_LOADING:
			return {
				...state,
				loading: true
			};
		case WEEK_CHART_SUCCESS:
			return {
				...state,
				loading: false,
				weeklyData: action.payload,
				date: new Date().toLocaleTimeString()
			};
		case WEEK_CHART_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				weeklyData: []
			};
		case SIDEBAR_FETCH_POOLS_LOADING:
			return {
				...state,
				loading: true
			};
		case SIDEBAR_FETCH_POOLS_SUCCESS:
			return {
				...state,
				loading: false,
				pools: action.payload,
				date: new Date().toLocaleTimeString()
			};
		case SIDEBAR_FETCH_POOLS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				pools: []
			};
		case SIDEBAR_FETCH_SUPERPOOLS_LOADING:
			return {
				...state,
				loading: true
			};
		case SIDEBAR_FETCH_SUPERPOOLS_SUCCESS:
			return {
				...state,
				loading: false,
				superPools: action.payload,
				date: new Date().toLocaleTimeString()
			};
		case SIDEBAR_FETCH_SUPERPOOLS_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				superPools: []
			};
		default:
			return state;
	}
};
