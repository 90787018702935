import { LoginType, MsalAuthProvider } from 'react-aad-msal';

const config = {
	auth: {
		authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
		clientId: process.env.REACT_APP_CLIENT_ID,
		postLogoutRedirectUri: window.location.origin,
		redirectUri: window.location.origin,
		validateAuthority: true
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true
	}
};

const authenticationParameters = {
	scopes: ['User.Read', 'Calendars.Read', 'GroupMember.Read.All', 'Mail.Send']
};

const options = {
	loginType: LoginType.Popup,
	tokenRefreshUri: `${window.location.origin}/auth.html`
};

export const authProvider = new MsalAuthProvider(
	config,
	authenticationParameters,
	options
);
