import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import ErrorSource from '../../assets/error.svg';
import WarningSource from '../../assets/warning.svg';
import SuccessSource from '../../assets/success.svg';
import { deleteToast } from '../../store/actions/ToastActions';

class Toast extends Component {
	getColor = type => {
		switch (type) {
			case 'success':
				return '#56b881';
			case 'error':
				return '#e55e5e';
			case 'warning':
				return '#f1f075';
			default:
				return '#00BFA6';
		}
	};

	getSource = type => {
		switch (type) {
			case 'success':
				return SuccessSource;
			case 'error':
				return ErrorSource;
			case 'warning':
				return WarningSource;
			default:
				return SuccessSource;
		}
	};

	getTitle = type => {
		switch (type) {
			case 'success':
				return 'Success!!';
			case 'error':
				return 'Error!!';
			case 'warning':
				return 'Warning!!';
			default:
				return SuccessSource;
		}
	};

	render() {
		return (
			<div className="toast-container">
				{_.map(this.props.list, (item, index) => {
					return (
						<div key={index} className="toast">
							<div className="toast-icon-container">
								<div className="toast-title">
									<Image src={this.getSource(item.type)} />
								</div>
							</div>
							<div className="toast-text-container">
								<div
									className="toast-title"
									style={{ color: this.getColor(item.type) }}
								>
									{this.getTitle(item.type)}
								</div>
								<div className="toast-message">{item.message}</div>
							</div>
							<div
								role="button"
								tabIndex={0}
								className="toast-button-container"
								onClick={() => {
									this.props.deleteToast(index);
								}}
							>
								Close
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		list: state.toast.list
	};
};

export default connect(
	mapStateToProps,
	{ deleteToast }
)(Toast);
