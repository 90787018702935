import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Container, Form, Grid, Header, Icon, Input, Modal, Progress, Table } from 'semantic-ui-react';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import { useFormik } from 'formik';
import { PDFDownloadLink } from '@react-pdf/renderer';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAdmin } from '../../../auth-utils';
import Switch from '../../../shared-components/Switch';
import WolbPDFDocument from './WolbPDFDocument';

function WolbResults() {
	// GLOBAL STATE
	const role = useSelector(state => state.auth.role);
	// LOCAL STATE
	const [plate, setPlate] = useState('');
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [checked, setChecked] = useState([]);
	const [data, setData] = useState([]);
	const [term, setTerm] = useState('');

	const exportToCSV = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const dataset = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		FileSaver.saveAs(dataset, 'report.xlsx');
	};

	const getData = () => {
		axios
			.post('/api/wolb-results', {
				plate
			})
			.then(r => {
				setData(r.data);
			})
			.catch(e => console.log(e))
			.finally(() => setLoading(false));
	};

	const formik = useFormik({
		initialValues: {
			wolb_wsptm_ct_value: '',
			wolb_rps17_ct_value: '',
			wolb_alb_ct_value: '',
			wolb_wsptm_ctrl: '',
			wolb_rps17_ct_ctrl: '',
			wolb_alb_ct_ctrl: '',
			wolb_results: '',
			wolb_strain: ''
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			axios
				.post('/api/wolb-add-results', {
					pool: checked,
					plate,
					wolb_wsptm_ct_value: values.wolb_wsptm_ct_value,
					wolb_rps17_ct_value: values.wolb_rps17_ct_value,
					wolb_alb_ct_value: values.wolb_alb_ct_value,
					wolb_wsptm_ctrl: values.wolb_wsptm_ctrl,
					wolb_rps17_ct_ctrl: values.wolb_rps17_ct_ctrl,
					wolb_alb_ct_ctrl: values.wolb_alb_ct_ctrl,
					wolb_results: values.wolb_results,
					wolb_strain: values.wolb_strain
				})
				.then(() => {
					_.forEach(checked, pool => {
						const i = _.findIndex(data, { pool });
						if (i !== -1) {
							data[i].wolb_wsptm_ct_value = values.wolb_wsptm_ct_value || 0;
							data[i].wolb_rps17_ct_value = values.wolb_rps17_ct_value || 0;
							data[i].wolb_alb_ct_value = values.wolb_alb_ct_value || 0;
							data[i].wolb_wsptm_ctrl = values.wolb_wsptm_ctrl || 0;
							data[i].wolb_rps17_ct_ctrl = values.wolb_rps17_ct_ctrl || 0;
							data[i].wolb_alb_ct_ctrl = values.wolb_alb_ct_ctrl || 0;
							data[i].wolb_results = values.wolb_results;
							data[i].wolb_strain = values.wolb_strain;
							data[i].wolb_status = 'Success';
						}
					});
				})
				.catch(e => console.log(e))
				.finally(() => {
					setChecked([]);
					setSubmitting(false);
					setVisible(false);
					resetForm();
				});
		}
	});

	return (
		<>
			<Modal size="small" open={visible}>
				<Modal.Content>
					<p className="strong">Add Results</p>
					<p className="small quiet">You have selected {checked.length} to add results in bulk.</p>
					<Form size="small">
						<Form.Group widths="equal">
							<Form.Input
								id="wolb_wsptm_ct_value"
								name="wolb_wsptm_ct_value"
								type="number"
								label="wolb_wsptm_ct_value"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_wsptm_ct_value}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="wolb_rps17_ct_value"
								name="wolb_rps17_ct_value"
								type="number"
								label="wolb_rps17_ct_value"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_rps17_ct_value}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="wolb_alb_ct_value"
								name="wolb_alb_ct_value"
								type="number"
								label="wolb_alb_ct_value"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_alb_ct_value}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Input
								id="wolb_wsptm_ctrl"
								name="wolb_wsptm_ctrl"
								type="number"
								label="wolb_wsptm_ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_wsptm_ctrl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="wolb_rps17_ct_ctrl"
								name="wolb_rps17_ct_ctrl"
								type="number"
								label="wolb_rps17_ct_ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_rps17_ct_ctrl}
								onChange={formik.handleChange}
							/>
							<Form.Input
								id="wolb_alb_ct_ctrl"
								name="wolb_alb_ct_ctrl"
								type="number"
								label="wolb_alb_ct_ctrl"
								size="small"
								placeholder="0.001"
								value={formik.values.wolb_alb_ct_ctrl}
								onChange={formik.handleChange}
							/>
						</Form.Group>
						<Form.Dropdown
							id="wolb_results"
							name="wolb_results"
							label="wolb_results"
							selection
							fluid
							size="small"
							header="Select Results"
							placeholder="Positive..."
							value={formik.values.wolb_results}
							onChange={(e, { value }) => formik.setFieldValue('wolb_results', value)}
							options={[
								{
									key: 'Negative',
									value: 'Negative',
									text: 'Negative'
								},
								{
									key: 'Positive',
									value: 'Positive',
									text: 'Positive'
								}
							]}
						/>
						<Form.Dropdown
							id="wolb_strain"
							name="wolb_strain"
							label="wolb_strain"
							selection
							fluid
							clearable
							upward
							size="small"
							header="Select Strain"
							placeholder="wsp..."
							value={formik.values.wolb_strain}
							onChange={(e, { value }) => formik.setFieldValue('wolb_strain', value)}
							options={[
								{
									key: 'wsp',
									value: 'wsp',
									text: 'wsp'
								},
								{
									key: 'rps17',
									value: 'rps17',
									text: 'rps17'
								},
								{
									key: 'Alb',
									value: 'Alb',
									text: 'Alb'
								}
							]}
						/>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button
						size="mini"
						content="Cancel"
						onClick={() => {
							setVisible(false);
							setChecked([]);
							formik.resetForm();
						}}
					/>
					<Button
						size="mini"
						primary
						content="Submit"
						loading={formik.isSubmitting}
						onClick={() => formik.submitForm()}
					/>
				</Modal.Actions>
			</Modal>
			<Modal size="mini" open={open}>
				<Modal.Content>
					<Modal.Description>
						<Grid textAlign="center">
							<Grid.Row>
								<Grid.Column>
									<p className="small strong">
										Generating report for plate {plate} on {new Date().toLocaleString()}
									</p>
									<p className="x-small quiet">Please wait a few seconds until the report it is generated.</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<PDFDownloadLink document={<WolbPDFDocument data={data} plate={plate} />} fileName="report.pdf">
										{({ loading }) =>
											loading ? (
												<Progress percent={60} color="yellow" size="tiny" />
											) : (
												<Button size="mini" positive content="Download" />
											)
										}
									</PDFDownloadLink>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button negative size="mini" onClick={() => setOpen(false)} content="Cancel" />
				</Modal.Actions>
			</Modal>
			<Container>
				<Grid stackable>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Input
								loading={loading}
								icon="search"
								iconPosition="left"
								size="small"
								placeholder="Plate number.."
								value={plate}
								onChange={e => setPlate(e.target.value)}
								style={{ marginRight: 5 }}
							/>
							<Button
								positive
								size="small"
								content="Search"
								onClick={() => {
									setLoading(true);
									setData([]);
									getData();
								}}
							/>
						</Grid.Column>
						<Grid.Column floated="right" textAlign="right">
							<Button
								style={{ minWidth: 100 }}
								size="mini"
								primary
								onClick={() => exportToCSV()}
								disabled={data.length === 0}
								content="Export to excel"
							/>
							<Button
								style={{ minWidth: 100 }}
								size="mini"
								primary
								color="orange"
								onClick={() => setOpen(true)}
								disabled={data.length === 0}
								content="Export to *.pdf"
							/>
							<Button
								style={{ minWidth: 100 }}
								disabled={checked.length === 0}
								size="mini"
								positive
								onClick={() => {
									setVisible(true);
									formik.setFieldValue('wolb_wsptm_ct_value', data[0].wolb_wsptm_ct_value || '');
									formik.setFieldValue('wolb_rps17_ct_value', data[0].wolb_rps17_ct_value || '');
									formik.setFieldValue('wolb_alb_ct_value', data[0].wolb_alb_ct_value || '');
									formik.setFieldValue('wolb_wsptm_ctrl', data[0].wolb_wsptm_ctrl || '');
									formik.setFieldValue('wolb_rps17_ct_ctrl', data[0].wolb_rps17_ct_ctrl || '');
									formik.setFieldValue('wolb_alb_ct_ctrl', data[0].wolb_alb_ct_ctrl || '');
									formik.setFieldValue('wolb_results', data[0].wolb_results || '');
									formik.setFieldValue('wolb_strain', data[0].wolb_strain || '');
								}}
								content="Add Results"
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							{data.length ? (
								<>
									<p className="strong small mb-0">Control Values</p>
									<p className="quiet small">Add control values for the following plate results.</p>
									<Table stackable textAlign="center" size="small" celled>
										<Table.Header>
											<Table.Row textAlign="right">
												<Table.HeaderCell colSpan="13">
													<Input
														name="filter"
														placeholder="Filter by pool or super..."
														iconPosition="left"
														size="small"
														value={term}
														icon={
															term ? <Icon link name="close" onClick={() => setTerm('')} /> : <Icon name="search" />
														}
														onChange={e => setTerm(e.target.value)}
													/>
												</Table.HeaderCell>
											</Table.Row>
											<Table.Row>
												<Table.HeaderCell>
													<Switch
														checked={checked.length === data.length}
														onChange={e => {
															if (e.target.checked) {
																setChecked(
																	_.map(
																		matchSorter(data, term, {
																			keys: ['pool']
																		}),
																		item => {
																			return item.pool;
																		}
																	)
																);
															} else {
																setChecked([]);
															}
														}}
													/>
												</Table.HeaderCell>
												<Table.HeaderCell>Pool</Table.HeaderCell>
												<Table.HeaderCell>Well</Table.HeaderCell>
												<Table.HeaderCell>wspTM_CTValue</Table.HeaderCell>
												<Table.HeaderCell>rps17_CTValue</Table.HeaderCell>
												<Table.HeaderCell>Alb_CTValue</Table.HeaderCell>
												<Table.HeaderCell>wspTM_Ctrl</Table.HeaderCell>
												<Table.HeaderCell>rps17_Ctrl</Table.HeaderCell>
												<Table.HeaderCell>Alb_Ctrl</Table.HeaderCell>
												<Table.HeaderCell>Results</Table.HeaderCell>
												<Table.HeaderCell>Strain</Table.HeaderCell>
												<Table.HeaderCell>Status</Table.HeaderCell>
												<Table.HeaderCell />
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{matchSorter(data, term, {
												keys: ['pool']
											}).map(item => {
												return (
													<Table.Row key={item.pool}>
														<Table.Cell>
															<Switch
																checked={_.includes(checked, item.pool)}
																onChange={() => {
																	setChecked(_.xor(checked, [item.pool]));
																}}
															/>
														</Table.Cell>
														<Table.Cell>
															<Header as="h5">
																<Header.Content>{item.pool}</Header.Content>
															</Header>
														</Table.Cell>
														<Table.Cell>{item.wolb_well}</Table.Cell>
														<Table.Cell>{item.wolb_wsptm_ct_value}</Table.Cell>
														<Table.Cell>{item.wolb_rps17_ct_value}</Table.Cell>
														<Table.Cell>{item.wolb_alb_ct_value}</Table.Cell>
														<Table.Cell>{item.wolb_wsptm_ctrl}</Table.Cell>
														<Table.Cell>{item.wolb_rps17_ct_ctrl}</Table.Cell>
														<Table.Cell>{item.wolb_alb_ct_ctrl}</Table.Cell>
														<Table.Cell>{item.wolb_results}</Table.Cell>
														<Table.Cell>{item.wolb_strain}</Table.Cell>
														<Table.Cell>{item.wolb_status === 'Success' ? 'Success' : 'Pending'}</Table.Cell>
														<Table.Cell>
															<Button
																size="mini"
																icon="pencil"
																compact
																circular
																positive
																onClick={() => {
																	setVisible(true);
																	setChecked([item.pool]);
																	formik.setFieldValue('wolb_wsptm_ct_value', item.wolb_wsptm_ct_value || '');
																	formik.setFieldValue('wolb_rps17_ct_value', item.wolb_rps17_ct_value || '');
																	formik.setFieldValue('wolb_alb_ct_value', item.wolb_alb_ct_value || '');
																	formik.setFieldValue('wolb_wsptm_ctrl', item.wolb_wsptm_ctrl || '');
																	formik.setFieldValue('wolb_rps17_ct_ctrl', item.wolb_rps17_ct_ctrl || '');
																	formik.setFieldValue('wolb_alb_ct_ctrl', item.wolb_alb_ct_ctrl || '');
																	formik.setFieldValue('wolb_results', item.wolb_results || '');
																	formik.setFieldValue('wolb_strain', item.wolb_strain || '');
																}}
															/>
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
										<Table.Footer fullWidth>
											<Table.Row>
												<Table.HeaderCell>
													<Switch
														checked={checked.length === data.length}
														onChange={e => {
															if (e.target.checked) {
																setChecked(
																	_.map(
																		matchSorter(data, term, {
																			keys: ['pool', 'superpool']
																		}),
																		item => {
																			return item.pool;
																		}
																	)
																);
															} else {
																setChecked([]);
															}
														}}
													/>
												</Table.HeaderCell>
												<Table.HeaderCell colSpan="12" textAlign="left">
													<Header size="tiny">
														<Header.Content>
															Showing{' '}
															{
																matchSorter(data, term, {
																	keys: ['pool', 'superpool']
																}).length
															}{' '}
															of {data.length}
														</Header.Content>
													</Header>
												</Table.HeaderCell>
											</Table.Row>
										</Table.Footer>
									</Table>
								</>
							) : (
								<div className="pre">
									<div className="pre-header x-small p-10 strong">Results</div>
									<div>
										{loading ? (
											<div className="pre-loading x-small p-10 strong">Loading</div>
										) : (
											<div className="x-small p-10 strong">
												{getAdmin(role)
													? 'Enter plate number on the input field to load results'
													: 'You have no privileges to access this section'}
											</div>
										)}
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="placeholder full-width" />
										<div className="divider" />
										<div className="placeholder" />
										<div className="placeholder full-width" />
									</div>
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</>
	);
}

export default WolbResults;
