import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Input, TextArea } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DateInput } from 'semantic-ui-calendar-react';
import { addToast } from '../../../../../../store/actions/ToastActions';

function Edit({ order }) {
	const dispatch = useDispatch();
	const email = useSelector(state => state.auth.email);

	const schema = Yup.object().shape({
		customer: Yup.number()
			.test(
				'len',
				'Must be exactly 5 digits',
				val => val.toString().length === 5
			)
			.integer()
			.required('This field is required'),
		order_number: Yup.string()
			.min(12)
			.max(12)
			.required('This field is required'),
		shipment: Yup.string().required('This field is required'),
		received: Yup.string().required('This field is required'),
		vials: Yup.number()
			.integer('The number must be an integer')
			.required('This field is required'),
		box: Yup.string().required('This field is required')
	});

	return (
		<Grid.Row columns={1}>
			<Grid.Column>
				<Formik
					initialValues={order}
					validationSchema={schema}
					onSubmit={async (values, { setSubmitting }) => {
						values.editor = email;

						await axios
							.put('/api/update-order', values)
							.then(res => {
								setSubmitting(false);
								dispatch(
									addToast({
										type: 'success',
										message: res.data.message
									})
								);
							})
							.catch(error => {
								dispatch(
									addToast({
										type: 'error',
										message: error.response.data.message
									})
								);
								setSubmitting(false);
							});
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						setFieldTouched
					}) => (
						<Form onSubmit={handleSubmit} noValidate size="small">
							<Form.Group widths="equal">
								<Form.Field
									type="number"
									error={errors.customer && touched.customer && errors.customer}
									name="customer"
									required
									value={values.customer}
									control={Input}
									label="Customer Number"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="5 digits number. Check clients list..."
								/>
								<Form.Field
									error={
										errors.order_number &&
										touched.order_number &&
										errors.order_number
									}
									name="order_number"
									required
									value={values.order_number}
									control={Input}
									label="Order Number"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="12 digits code..."
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field
									error={errors.shipment && touched.shipment && errors.shipment}
									name="shipment"
									required
									control={DateInput}
									label="Shipment Date"
									value={values.shipment}
									onChange={(event, { value }) => {
										setFieldValue('shipment', value);
									}}
									onBlur={() => setFieldTouched('shipment')}
									placeholder="mm/dd/yyyy"
									dateFormat="L"
									hideMobileKeyboard
									animation="fade"
									closable
									marked={[new Date(values.shipment)]}
									markColor="orange"
									iconPosition="left"
									autoComplete="off"
								/>
								<Form.Field
									error={errors.received && touched.received && errors.received}
									name="received"
									required
									control={DateInput}
									label="Received Date"
									value={values.received}
									onChange={(event, { value }) => {
										setFieldValue('received', value);
									}}
									onBlur={() => setFieldTouched('received')}
									placeholder="mm/dd/yyyy"
									dateFormat="L"
									hideMobileKeyboard
									animation="fade"
									closable
									marked={[new Date(values.received)]}
									markColor="orange"
									iconPosition="left"
									autoComplete="off"
								/>
								<Form.Field
									name="reported"
									control={DateInput}
									label="Reported Date"
									value={values.reported}
									onChange={(event, { value }) => {
										setFieldValue('reported', value);
									}}
									onBlur={() => setFieldTouched('reported')}
									placeholder="mm/dd/yyyy"
									dateFormat="L"
									hideMobileKeyboard
									animation="fade"
									closable
									marked={[new Date(values.reported)]}
									markColor="orange"
									iconPosition="left"
									autoComplete="off"
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field
									name="comments"
									value={values.comments}
									control={TextArea}
									label="Comments"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Anything to say about this order..."
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field
									name="description"
									value={values.description}
									control={Input}
									label="Item Description"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Please describe the item..."
								/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field
									type="number"
									error={errors.vials && touched.vials && errors.vials}
									name="vials"
									required
									value={values.vials}
									control={Input}
									label="Vials Quantity"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Please number only..."
								/>
								<Form.Field
									error={errors.box && touched.box && errors.box}
									name="box"
									required
									value={values.box}
									control={Input}
									label="Box Quantity"
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Please add box number..."
								/>
							</Form.Group>
							<Form.Field
								positive
								control={Button}
								content="Submit"
								loading={isSubmitting}
								disabled={isSubmitting}
							/>
						</Form>
					)}
				</Formik>
			</Grid.Column>
		</Grid.Row>
	);
}

export default Edit;
