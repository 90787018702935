import React, { useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useFormik } from 'formik';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import axios from 'axios';

const Export = () => {
	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			startDate: moment()
				.subtract(1, 'days')
				.format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD')
		},
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true);
			axios
				.post('/api/export-pools', {
					startDate: values.startDate,
					endDate: values.endDate
				})
				.then(response => {
					const ws = XLSX.utils.json_to_sheet(response.data);
					const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
					const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
					const blob = new Blob([buffer], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
					});

					FileSaver.saveAs(blob, `pools_${new Date().toLocaleDateString()}.xlsx`);
				})
				.catch(e => console.log(e))
				.finally(() => {
					setSubmitting(false);
				});
		}
	});

	return (
		<>
			<Modal
				closeIcon
				onClose={() => {
					setOpen(false);
					formik.setSubmitting(false);
					formik.resetForm();
				}}
				onOpen={() => setOpen(true)}
				open={open}
				trigger={<Button color="black" size="mini" content="Export Pools Data" onClick={() => setOpen(true)} />}
				size="mini"
			>
				<Modal.Header as="h4">Export</Modal.Header>
				<Modal.Content>
					<p>Use the following form to select dates and export the pools dataset.</p>
					<Form size="small">
						<Form.Group widths="equal">
							<Form.Field
								id="startDate"
								name="startDate"
								label="Start Date"
								size="small"
								placeholder="YYYY-MM-DD"
								value={formik.values.startDate}
								maxDate={moment()
									.subtract(1, 'days')
									.format('YYYY-MM-DD')}
								onChange={(event, { value }) => {
									formik.setFieldValue('startDate', value);
								}}
								closable
								animation="none"
								dateFormat="YYYY-MM-DD"
								control={DateInput}
							/>
							<Form.Field
								id="endDate"
								name="endDate"
								label="End Date"
								size="small"
								placeholder="YYYY-MM-DD"
								value={formik.values.endDate}
								maxDate={moment().format('YYYY-MM-DD')}
								onChange={(event, { value }) => {
									formik.setFieldValue('endDate', value);
								}}
								closable
								animation="none"
								dateFormat="YYYY-MM-DD"
								control={DateInput}
							/>
						</Form.Group>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button
						size="mini"
						content="Cancel"
						onClick={() => {
							setOpen(false);
							formik.setSubmitting(false);
							formik.resetForm();
						}}
					/>
					<Button
						type="button"
						size="mini"
						primary
						content="Download"
						loading={formik.isSubmitting}
						onClick={() => formik.handleSubmit()}
					/>
				</Modal.Actions>
			</Modal>
		</>
	);
};

export default Export;
