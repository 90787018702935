import {
	ORDERS_SEARCH_VALUE,
	ORDERS_DATA_LOADING,
	ORDERS_DATA_SUCCESS,
	ORDERS_DATA_ERROR,
	ORDERS_MODAL_VISIBILITY,
	ORDERS_SET_RECORD,
	ORDERS_CONFIRMATION_VISIBILITY
} from './types';

export const setValue = value => {
	return {
		type: ORDERS_SEARCH_VALUE,
		payload: value
	};
};

export const getData = url => {
	return dispatch => {
		dispatch({
			type: ORDERS_DATA_LOADING
		});

		fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch({
					type: ORDERS_DATA_SUCCESS,
					payload: json
				});
			})
			.catch(error => {
				dispatch({
					type: ORDERS_DATA_ERROR,
					payload: error.message
				});
			});
	};
};

export const setModal = () => {
	return {
		type: ORDERS_MODAL_VISIBILITY
	};
};

export const setRecord = e => {
	return {
		type: ORDERS_SET_RECORD,
		payload: e
	};
};

export const setConfirmation = () => {
	return {
		type: ORDERS_CONFIRMATION_VISIBILITY
	};
};
