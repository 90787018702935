import { LOGIN, LOGOUT } from './types';

export const getUser = ({ prop, value }) => {
	return dispatch => {
		dispatch({
			type: LOGIN,
			payload: { prop, value }
		});
	};
};

export const resetUser = () => {
	return {
		type: LOGOUT
	};
};
