import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bar, Line } from 'react-chartjs-2';
import _ from 'lodash';
import { Button, Grid, Icon } from 'semantic-ui-react';
import {
	fetchLineData,
	fetchTopFive,
	fetchweeklyData
} from '../../../../store/actions/ChartActions';
import { hideSidebar } from '../../../../store/actions/SidebarActions';
import { exportCanvas } from '../../../../utils/Functions';

class Charts extends Component {
	componentDidMount() {
		this.props.fetchLineData();
		this.props.fetchweeklyData();
		this.props.fetchTopFive();
	}

	render() {
		return (
			<Grid inverted padded>
				<Grid.Row>
					<Grid.Column textAlign="right">
						<Button
							color="blue"
							size="mini"
							onClick={this.props.hideSidebar}
							content="Close"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<span className="quiet small strong fill-black">
							Monthly Pools Count{' '}
							<Icon
								fitted
								style={{ float: 'right' }}
								circular
								inverted
								color="green"
								link
								name="save outline"
								onClick={() => exportCanvas('chart-1')}
							/>
						</span>
						<div style={{ position: 'relative', height: '25vh' }}>
							<Line
								id="chart-1"
								data={{
									labels: _.map(this.props.lineData, e => {
										return e.x;
									}),
									datasets: [
										{
											label: 'Pools Quantity',
											fill: false,
											lineTension: 0.4,
											borderColor: '#f9886c',
											pointRadius: 4,
											pointBorderColor: '#ffffff',
											pointBackgroundColor: '#f9886c',
											pointBorderWidth: 2,
											pointHoverRadius: 6,
											pointHoverBackgroundColor: '#f9886c',
											pointHoverBorderColor: '#ffffff',
											pointHoverBorderWidth: 3,
											data: _.map(this.props.lineData, e => {
												return e.y;
											})
										}
									]
								}}
								options={{
									maintainAspectRatio: false,
									scales: {
										yAxes: [
											{
												scaleLabel: {
													display: true,
													labelString: 'Pools Quantity'
												}
											}
										],
										xAxes: [
											{
												scaleLabel: {
													display: true,
													labelString: 'Month/Year'
												}
											}
										]
									},
									legend: {
										display: false
									}
								}}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<span className="quiet small strong fill-black">
							Weekly Pools in {new Date().getFullYear()}
							<Icon
								fitted
								style={{ float: 'right' }}
								circular
								inverted
								color="green"
								link
								name="save outline"
								onClick={() => exportCanvas('chart-2')}
							/>
						</span>
						<div style={{ position: 'relative', height: '25vh' }}>
							<Bar
								id="chart-2"
								data={{
									labels: _.map(this.props.weeklyData, e => {
										return e.week;
									}),
									datasets: [
										{
											label: 'Pools Quantity',
											backgroundColor: '#f9886c',
											borderWidth: 0,
											hoverBackgroundColor: '#e55e5e',
											data: _.map(this.props.weeklyData, e => {
												return e.count;
											})
										}
									]
								}}
								options={{
									maintainAspectRatio: false,
									scales: {
										yAxes: [
											{
												scaleLabel: {
													display: true,
													labelString: 'Pools Quantity'
												}
											}
										],
										xAxes: [
											{
												scaleLabel: {
													display: true,
													labelString: 'Week'
												}
											}
										]
									},
									legend: {
										display: false
									}
								}}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.charts.loading,
		lineData: state.charts.lineData,
		feed: state.charts.pools,
		date: state.charts.date,
		weeklyData: state.charts.weeklyData,
		name: state.auth.name
	};
};

export default connect(
	mapStateToProps,
	{
		fetchweeklyData,
		fetchTopFive,
		fetchLineData,
		hideSidebar
	}
)(Charts);
