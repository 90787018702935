import React, { useState, useEffect } from 'react';
import { Polar } from 'react-chartjs-2';
import _ from 'lodash';

const WeeklyCountChart = () => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const loadData = async () => {
		await fetch('/api/weekly-count')
			.then(response => response.json())
			.then(res => {
				setData({
					labels: _.map(res, e => {
						return e.key;
					}),
					datasets: [
						{
							label: 'Count',
							data: _.map(res, e => {
								return e.value;
							}),
							backgroundColor: [
								'rgba(138,138,203,0.75)',
								'rgba(251,176,59,0.75)',
								'rgba(237,100,152,0.75)',
								'rgba(86,184,129,0.75)',
								'rgba(241,240,117,0.75)',
								'rgba(56,135,190,0.75)'
							],
							hoverBackgroundColor: [
								'rgba(138,138,203,0.65)',
								'rgba(251,176,59,0.65)',
								'rgba(237,100,152,0.65)',
								'rgba(86,184,129,0.65)',
								'rgba(241,240,117,0.65)',
								'rgba(56,135,190,0.65)'
							],
							borderWidth: 0,
							borderColor: 'rgba(255,255,255,0.25)'
						}
					]
				});
				setLoading(false);
			})
			.catch(err => {
				setError(err.message);
				setLoading(false);
			});
	};

	useEffect(() => {
		loadData().then(() => setLoading(false));
	}, []);

	return (
		<div style={{ height: 300 }} className="text-center flex-middle">
			{loading && (
				<>
					<div className="message-loading mb-10" />
					<div className="small text-center">Loading</div>
				</>
			)}
			{error && !loading && (
				<div className="small strong text-center">error</div>
			)}
			{!loading && !error && (
				<Polar
					data={data}
					legend={{
						display: true,
						position: 'left',
						labels: {
							fontStyle: 'bold',
							usePointStyle: true,
							padding: 14
						}
					}}
					options={{
						animation: {
							animateScale: true,
							animateRotate: true
						},
						scale: {
							ticks: {
								backdropColor: '#2d2d2d'
							},
							pointLabels: {
								fontColor: '#fff',
								fontStyle: 'bold'
							}
						}
					}}
				/>
			)}
		</div>
	);
};

export default WeeklyCountChart;
